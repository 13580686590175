/* eslint-disable */
import { adminApi } from '../../helpers/ApiHelper';
import JoinAdminTeam from '../../model/admin/JoinAdminTeam';
import ErrorMessage from '../../model/services/ErrorMessage';
import InvitationToken from '../../model/user/InvitationToken';
import User from '../../model/user/User';
import VerifyRegistration from '../../model/user/VerifyRegistration';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getInvitationToken = async (token: string, userId: string) => {
    let params = new URLSearchParams();
    params.append('token', token);
    params.append('userId', userId);

    return adminApi.get<InvitationToken, string>(`Accounts/InvitationToken`, params).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const registerAdminMember = async (userData: VerifyRegistration) => {
    let verifyRegistrationDto = {
        token: userData.token,
        userId: userData.userId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        password: userData.password,
    };
    return await adminApi
        .post<User, string>('Accounts/Register', JSON.stringify(verifyRegistrationDto))
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const joinAdminTeam = async (userData: JoinAdminTeam) => {
    let addTeamMemberDto = {
        token: userData.token,
        userId: userData.userId,
    };
    return await adminApi.post<string, string>(`Accounts/Join`, JSON.stringify(addTeamMemberDto)).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

export default {
    getInvitationToken,
    joinAdminTeam,
    registerAdminMember,
};
