import * as React from 'react';
import { useState } from 'react';
import tagColour from '../../enums/tagColour';
import appSubmissionStatus from '../../enums/appSubmissionStatus';
import adminAppSubmissionStatus from '../../enums/Admin/adminAppSubmissionStatus';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

interface AppSubmissionStatusDropdownProps {
    submissionStatus?: string;
    appId?: string;
    updateAppStatus: Function;
}

const AppSubmissionStatusDropdown = ({
    submissionStatus,
    appId,
    updateAppStatus,
}: AppSubmissionStatusDropdownProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const intl = useIntl();

    const getDropdownColour = () => {
        switch (submissionStatus) {
            case appSubmissionStatus.submitted:
                return tagColour.Orange;
            case appSubmissionStatus.approved:
                return tagColour.Blue;
            case appSubmissionStatus.live:
                return tagColour.Green;
            case appSubmissionStatus.inDevelopment:
            default:
                return tagColour.Grey;
        }
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className="rd-tag" color={getDropdownColour()} caret>
                {intl.formatMessage({ id: `AppManagement.AppSubmissionStatus.${submissionStatus}` })}
            </DropdownToggle>
            <DropdownMenu>
                {(Object.keys(adminAppSubmissionStatus) as Array<keyof typeof adminAppSubmissionStatus>).map(
                    (status, key) => (
                        <DropdownItem key={key} onClick={() => updateAppStatus(appId, status)} value={status}>
                            <FormattedMessage
                                id={`AppManagement.AppSubmissionStatus.${status[0].toUpperCase() + status.slice(1)}`}
                            />
                        </DropdownItem>
                    )
                )}
            </DropdownMenu>
        </Dropdown>
    );
};

export default AppSubmissionStatusDropdown;
