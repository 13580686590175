import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import AssignRoles from './AssignRoles';

function AssignRolesModal({ header, roleName, onAccept, isOpen, onClose, teamsData, isLoading, assignRoles }) {
    const handleSubmit = () => {
        onAccept();
    };

    const cancelButton = () => {
        return (
            <Button color="secondary" onClick={onClose}>
                Close
            </Button>
        );
    };
    const updateRoles = (updated) => {
        assignRoles(updated);
    };

    return (
        <Modal className="assignRolesModal" centered isOpen={isOpen} toggle={onClose} scrollable={true}>
            <ModalHeader className="border-bottom-0" toggle={onClose}>
                {header && <span>{header}</span>}
            </ModalHeader>
            <ModalBody>
                <AssignRoles role={roleName} rData={teamsData} isLoading={isLoading} updateRoles={updateRoles} />
            </ModalBody>
            <ModalFooter>
                {cancelButton()}
                <Button color="primary" type="button" onClick={handleSubmit}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
}

AssignRolesModal.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    description: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    assignRoles: PropTypes.func,
};
export default AssignRolesModal;
