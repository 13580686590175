import config from '../../config/app';
import { matchPath, useLocation } from 'react-router-dom';
import MenuItem from '../../model/layout/SidebarMenuItem';
import { useIntl } from 'react-intl';

const ProfileMenuData = () => {
    const { pathname } = useLocation();
    const intl = useIntl();

    const isMenuItemActive = (path: string) => matchPath(path, pathname) !== null;

    const menu: MenuItem[] = [
        {
            title: intl.formatMessage({ id: 'Profile.Sidebar.Profile' }),
            path: config.profileLandingPath,
            isActive: true,
            subItems: [
                {
                    title: intl.formatMessage({ id: 'Profile.Sidebar.GeneralSettings' }),
                    path: config.profileGeneralSettingsPath,
                    isActive: isMenuItemActive(config.profileGeneralSettingsPath),
                },
                {
                    title: intl.formatMessage({ id: 'Profile.Sidebar.Organisation' }),
                    path: config.profileOrganisationSettingsPath,
                    isActive: isMenuItemActive(config.profileOrganisationSettingsPath),
                    subItems: [
                        {
                            title: intl.formatMessage({ id: 'Profile.Sidebar.TeamMembers' }),
                            path: config.profileTeamMembersPath,
                            isActive: isMenuItemActive(config.profileTeamMembersPath),
                        },
                        {
                            title: intl.formatMessage({ id: 'Profile.Sidebar.Roles' }),
                            path: config.profileRolesPath,
                            isActive: isMenuItemActive(config.profileRolesPath),
                        },
                    ],
                },
            ],
        },
    ];

    return menu;
};

export default ProfileMenuData;
