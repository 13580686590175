import * as React from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

const AvatarStyled = styled(Avatar)<AvatarProps>(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    fontSize: '13px',
    fontWeight: 'bold',
}));

export interface ProfileCircleProps {
    fullName: string;
}

const ProfileCircle = ({ fullName }: ProfileCircleProps) => {
    const initials = fullName
        .toUpperCase()
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '');

    return <AvatarStyled className="profile">{initials}</AvatarStyled>;
};

export default ProfileCircle;
