import $ from 'jquery';
import queryString from 'query-string';

export default function errorPage() {
    if (!$('.error-page').length) {
        return;
    }
    const parsed = queryString.parse(window.location.search);
    const statusCode = parsed['statusCode'];
    if (!$(`.error-page--${statusCode}`).removeClass('d-none').length) {
        $('.error-page--default').removeClass('d-none');
        if (statusCode) {
            $('.error-page--default .error-page__status-code').removeClass('d-none');
            $('.error-page--default .error-page__status-code span').text(statusCode);
        }
    }
    // Error page doesn't have menu-items in its sidebar
    $('.dp-sidebar').empty();
}
