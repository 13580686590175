import * as React from 'react';
import { useState } from 'react';
import { ReactComponent as CopyIcon } from '../../content/icons/Icon-Copy.svg';
import { ReactComponent as CopyIconWhite } from '../../content/icons/Icon-Copy-White.svg';
import Tooltip from '@mui/material/Tooltip';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

const CopyToClipboard = ({ id, isWhite, buttonProps, getValue }: CopyToClipboardProps) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const copyToClipboard = (e: React.MouseEvent) => {
        navigator.clipboard.writeText(getValue());
        setIsTooltipOpen(true);
        e.stopPropagation();
    };

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    const closeTooltip = () => {
        if (isTooltipOpen) setIsTooltipOpen(false);
    };

    return (
        <Tooltip placement="top" arrow title="Copied" open={isTooltipOpen} onClose={handleTooltipClose}>
            <IconButton
                {...buttonProps}
                id={`copy-to-clipboard-${id}`}
                onClick={copyToClipboard}
                onMouseLeave={closeTooltip}
            >
                {isWhite ? <CopyIconWhite /> : <CopyIcon />}
            </IconButton>
        </Tooltip>
    );
};

export interface CopyToClipboardProps {
    id: string;
    getValue: () => string;
    isWhite?: boolean;
    buttonProps?: IconButtonProps;
}

export default CopyToClipboard;
