import * as React from 'react';
import { useLocation } from 'react-router-dom';

function useQueryParam(paramName: string) {
    const locationSearch = useLocation().search;
    const searchParams = React.useMemo(() => new URLSearchParams(locationSearch), [locationSearch]);
    const [paramValue, setParamValue] = React.useState(searchParams.get(paramName));

    React.useEffect(() => {
        setParamValue(searchParams.get(paramName));
    }, [paramName, searchParams]);

    return paramValue;
}

export default useQueryParam;
