import * as React from 'react';
import Tag from '../common/Tag';
import tagColour from '../../enums/tagColour';
import appSubmissionStatus from '../../enums/appSubmissionStatus';
import { useIntl } from 'react-intl';

interface AppSubmissionTagProps {
    submissionStatus: string;
}

const AppSubmissionStatusTag = ({ submissionStatus }: AppSubmissionTagProps) => {
    const intl = useIntl();

    switch (submissionStatus) {
        case appSubmissionStatus.submitted:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.Submitted' })}
                    color={tagColour.Orange}
                />
            );
        case appSubmissionStatus.approved:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.Approved' })}
                    color={tagColour.Blue}
                />
            );
        case appSubmissionStatus.live:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.Live' })}
                    color={tagColour.Green}
                />
            );
        case appSubmissionStatus.inDevelopment:
        default:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppSubmissionStatus.InDevelopment' })}
                    color={tagColour.Grey}
                />
            );
    }
};

export default AppSubmissionStatusTag;
