/* eslint-disable */
import { api } from '../helpers/ApiHelper';
import TeamMember from '../model/user/TeamMember';
import UserInvite from '../model/user/UserInvite';
import ErrorMessage from '../model/services/ErrorMessage';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getTeamMembers = async (organisationId: string, emails?: string[]) => {
    emails = emails || [];
    let params = new URLSearchParams();
    emails.forEach((email) => {
        params.append('emails', email);
    });
    return api.get<TeamMember[], string>(`organisation/${organisationId}/TeamMembers`, params).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const inviteTeamMember = (organisationId: string, teamMember: UserInvite) => {
    return api.post<TeamMember, string>(`organisation/${organisationId}/TeamMember`, teamMember).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const inviteTeamMembers = (organisationId: string, teamMembers: UserInvite[]) => {
    return api
        .post<TeamMember[], string>(`organisation/${organisationId}/TeamMembers`, teamMembers)
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

const updateOrganisationName = async (newOrganisationName: string, organisationId: string) => {
    return api.post<string, string>(`organisation/${organisationId}/name/${newOrganisationName}`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

export default {
    getTeamMembers,
    inviteTeamMember,
    updateOrganisationName,
    inviteTeamMembers,
};
