import React from 'react';
import useTranslations from './hooks/useTranslations';
import { IntlProvider } from 'react-intl';
import App from './App';

export default function LocalisedApp() {
    const { language, messages } = useTranslations();
    return (
        <IntlProvider locale={language} messages={messages}>
            <App />
        </IntlProvider>
    );
}
