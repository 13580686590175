import Clipboard from 'clipboard';
import $ from 'jquery';

export default function copyCode() {
    const copyBtnClassName = '.code__toolbox__copy';
    $(copyBtnClassName).tooltip({
        title: 'Copy', // show this tooltip when user hovers to the copy button
    });

    // Initialize clipboard
    const clipboard = new Clipboard(copyBtnClassName, {
        text: (trigger) => {
            // this text will be saved into clipboard
            return $(trigger).closest('.code').find('code').text();
        },
    });
    clipboard.on('success', (evt) => {
        const btn = $(evt.trigger);
        btn.attr('data-original-title', 'Copied').tooltip('show');
        setTimeout(() => {
            // The content of the tooltip is stored in `data-original-title`
            // Rolling back for the next use
            btn.attr('data-original-title', 'Copy');
        }, 100);
    });
}
