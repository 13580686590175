/* eslint-disable */
import { api } from '../helpers/ApiHelper';
import AccountQuestionnaire from '../model/questionnaire/AccountQuestionnaire';
import QuestionnaireRequest from '../model/questionnaire/QuestionnaireRequest';
import ErrorMessage from '../model/services/ErrorMessage';
import InvitationToken from '../model/user/InvitationToken';
import User from '../model/user/User';
import VerifyRegistration from '../model/user/VerifyRegistration';
import Organisation from '../model/user/Organisation';
import ResendEmailConfirmationResponse from '../model/user/ResendEmailConfirmationResponse';

const undefinedResponse = (): ErrorMessage => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const register = async (firstName: string, lastName: string, email: string, organisation: string, password: string) => {
    return await api
        .post<User, string>(
            'Accounts/Register',
            JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                email: email,
                organisation: organisation,
                password: password,
            })
        )
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data, status: 'Fail' };
                    return errorMessage;
                }
            } else {
                let errorMessage: ErrorMessage = { errorMessage: 'Undefined response', status: 'Fail' };
                return errorMessage;
            }
        });
};

const verify = async (token: string, userId: string) => {
    return await api
        .post<User, string>(
            'Accounts/Verify',
            JSON.stringify({
                token: token,
                userId: userId,
            })
        )
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const getInvitationToken = async (token: string, userId: string, organisationId: string) => {
    let params = new URLSearchParams();
    params.append('token', token);
    params.append('userId', userId);
    params.append('organisationId', organisationId);

    return await api.get<InvitationToken, string>(`Accounts/InvitationToken`, params).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const registerTeamMember = async (userData: VerifyRegistration) => {
    let verifyRegistrationDto = {
        token: userData.token,
        userId: userData.userId,
        organisationId: userData.organisationId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        password: userData.password,
    };
    return await api
        .post<User, string>('Accounts/RegisterTeamMember', JSON.stringify(verifyRegistrationDto))
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const joinAdditionalTeam = async (userData: VerifyRegistration) => {
    let addTeamMemberDto = {
        token: userData.token,
        userId: userData.userId,
        organisationId: userData.organisationId,
    };
    return await api
        .post<number, string>('Accounts/JoinAdditionalTeam', JSON.stringify(addTeamMemberDto))
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const resendVerificationEmail = async (email: string, password: string) => {
    return await api
        .post<ResendEmailConfirmationResponse, string>(
            'Accounts/ResendVerification',
            JSON.stringify({
                email: email,
                password: password,
            })
        )
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const getQuestionnaire = async () => {
    return await api.get<AccountQuestionnaire, string>('Accounts/Questionnaire').then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const saveQuestionnaire = async (answers: QuestionnaireRequest) => {
    return await api
        .post<QuestionnaireRequest, string>('Accounts/Questionnaire', JSON.stringify(answers))
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const updateContactDetails = async (firstName: string, lastName: string) => {
    return await api
        .post<undefined, string>(
            'Accounts/UpdateContactDetails',
            JSON.stringify({
                firstName: firstName,
                lastName: lastName,
            })
        )
        .then((response) => {
            if (response.ok) {
                return true;
            } else if (!response.ok && response.data !== undefined) {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            } else {
                return undefinedResponse();
            }
        });
};

const updatePassword = async (currentPassword: string, newPassword: string, emailAddress: string) => {
    return await api
        .post<undefined, string>(
            'Accounts/UpdatePassword',
            JSON.stringify({
                currentPassword: currentPassword,
                newPassword: newPassword,
                emailAddress: emailAddress,
            })
        )
        .then((response) => {
            if (response.ok) {
                return true;
            } else if (!response.ok && response.data !== undefined) {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            } else {
                return undefinedResponse();
            }
        });
};

const getUserOnboardingStage = async (emailAddress: string) => {
    return await api
        .get<string, string>(`Accounts/OnboardingStage`, {
            emailAddress: emailAddress,
        })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const setUserOnboardingStage = async (emailAddress: string, userOnboardingStage: string) => {
    return await api
        .put<string, string>(`Accounts/OnboardingStage`, {
            emailAddress: emailAddress,
            userOnboardingStage: userOnboardingStage,
        })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const getUserOrganisations = async () => {
    return await api.get<Organisation[], string>(`Accounts/Organisations`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const setDefaultOrganisation = async (emailAddress: string, defaultOrganisationId: string) => {
    return await api
        .put<undefined, string>(`Accounts/Organisations/DefaultOrganisation`, {
            emailAddress: emailAddress,
            organisationId: defaultOrganisationId,
        })
        .then((response) => {
            if (response.ok) {
                return true;
            } else if (response.data !== undefined && !response.ok) {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            } else {
                return undefinedResponse();
            }
        });
};

const updateEmail = async (email: string) => {
    return await api
        .post<undefined, string>(
            'Accounts/UpdateEmail',
            JSON.stringify({
                newEmail: email,
            })
        )
        .then((response) => {
            if (response.ok) {
                return true;
            } else if (response.data !== undefined && !response.ok) {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            } else {
                return undefinedResponse();
            }
        });
};

export default {
    getQuestionnaire,
    saveQuestionnaire,
    register,
    verify,
    resendVerificationEmail,
    registerTeamMember,
    joinAdditionalTeam,
    updateContactDetails,
    updatePassword,
    getInvitationToken,
    getUserOnboardingStage,
    setUserOnboardingStage,
    getUserOrganisations,
    setDefaultOrganisation,
    updateEmail,
};
