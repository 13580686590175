import TestDiaryListPage from './TestDiaryListPage/TestDiaryListPage';
import AuditTrailPage from './AuditTrailPage/AuditTrailPage';
import UserManagementPage from './UserManagementPage/UserManagementPage';
import DocumentManagementPage from './DocumentManagementPage/DocumentManagementPage';
import OrganisationListPage from './OrganisationListPage/OrganisationListPage';

const Admin = {
    TestDiaryListPage,
    AuditTrailPage,
    UserManagementPage,
    DocumentManagementPage,
    OrganisationListPage,
};

export default Admin;
