import * as React from 'react';

const SelectList = (props: SelectListProps) => {
    const getClassNames = () => {
        let className = 'form-inline';
        if (props.className) {
            className += ` ${props.className}`;
        }

        return className;
    };

    return (
        <div className={getClassNames()}>
            <label className="mr-2 text-uppercase font-weight-bold"> {props.label}</label>
            <select className="form-select form-control" onChange={props.onChange}>
                {props.options.map((optionValue) => (
                    <option value={optionValue.key} key={optionValue.key}>
                        {optionValue.value}
                    </option>
                ))}
            </select>
        </div>
    );
};

interface SelectListProps {
    options: Option[];
    onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
    label?: string;
    className?: string;
}

interface Option {
    key: number | string;
    value: number | string;
}

export default SelectList;
