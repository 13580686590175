import * as React from 'react';
import { SxProps, Box } from '@mui/material';
import HubAlert, { HubAlertProps } from './HubAlert';

const HubAlertManager = (props: HubAlertManagerProps) => {
    const style: SxProps = {
        position: 'fixed',
        zIndex: 2,
        display: 'table',
        bottom: '2rem',
        left: '2rem',
    };

    const generateHubAlerts = () => {
        let alerts: JSX.Element[] = [];
        props.alerts.forEach((element, index) => {
            alerts.push(<HubAlert key={index} {...element} />);
        });
        return alerts;
    };

    return <Box sx={style}>{generateHubAlerts()}</Box>;
};

export interface HubAlertManagerProps {
    alerts: HubAlertProps[];
}

export default HubAlertManager;
