import React, { Fragment, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import Toggle from '../../../components/common/Toggle';
import DocumentService from '../../../services/admin/DocumentService';
import { useAuthentication } from '../../../contexts/AuthenticationContext';

function DocumentManagementPage(props) {
    const { authInfo } = useAuthentication();
    const [inHouseDocumentation, setInHouseDocumentation] = useState(authInfo?.inHouseDocumentation);

    const enableInHouseDocumentation = (isEnabled) => {
        setInHouseDocumentation(isEnabled);
        DocumentService.updateDocumentManagementSettings(authInfo.id);
    };

    return (
        <Fragment>
            <div className="container-fluid user-management-page">
                <main>
                    <h1 className="mb-4">Document management</h1>
                    <p>Switch between in-house documentation and Readme documentation with the help of this toggle</p>
                </main>
                <FormGroup row>
                    <Label sm={2}>
                        <h5>In House Documentation</h5>
                    </Label>
                    <Col sm={6}>
                        <Toggle
                            className="ml-0"
                            checked={inHouseDocumentation}
                            handleChange={enableInHouseDocumentation}
                            checkedLabel="Yes"
                            uncheckedLabel="No"
                        />
                    </Col>
                </FormGroup>
            </div>
        </Fragment>
    );
}

export default DocumentManagementPage;
