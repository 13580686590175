import * as React from 'react';
import { Alert } from 'reactstrap';
import infoBarType from '../../enums/infoBarType';
import { ReactComponent as ConfirmIcon } from '../../content/icons/Icon-Confirmed.svg';
import { ReactComponent as AlertIcon } from '../../content/icons/Icon-Alert.svg';
import { FormattedMessage } from 'react-intl';
// you should NEVER import this component directly, use the `InfoBarManager` instead!

class InfoBar extends React.Component<InfoBarProps, { isEnabled: boolean }> {
    static defaultProps = {
        type: infoBarType.error,
        expireIn: 4000,
        onExpire: (infoBar: InfoBar) => {},
        onDismiss: (infoBar: InfoBar) => {},
        onMount: (infoBar: InfoBar) => {},
    };

    constructor(props: InfoBarProps) {
        super(props);

        this.state = {
            isEnabled: true,
        };

        this.getAlertColour = this.getAlertColour.bind(this);
        this.setExpiry = this.setExpiry.bind(this);
        this.disable = this.disable.bind(this);
    }

    componentDidMount() {
        this.setExpiry();
        this.props.onMount!(this);
    }

    getAlertColour() {
        return this.props.type === infoBarType.success ? 'success' : 'danger';
    }

    setExpiry() {
        if (this.props.expireIn! > 0) {
            setTimeout(() => {
                this.setState({ isEnabled: false });
                this.props.onExpire!(this);
            }, this.props.expireIn);
        }
    }

    disable() {
        this.setState({ isEnabled: false });
        this.props.onDismiss!(this);
    }

    render() {
        return this.state.isEnabled ? (
            <Alert
                className="info-bar animated slideInDown"
                color={this.getAlertColour()}
                isOpen={this.state.isEnabled}
                toggle={this.disable}
            >
                <div className="d-flex">
                    <div>{this.props.type === infoBarType.success ? <ConfirmIcon /> : <AlertIcon />}</div>
                    <div className="mt-1">
                        {this.props.message ? (
                            this.props.message
                        ) : (
                            <FormattedMessage id="CommonComponents.InfoBar.ErrorMessage" />
                        )}
                    </div>
                </div>
            </Alert>
        ) : null;
    }
}

export interface InfoBarProps {
    message: string;
    type?: string;
    expireIn?: number;
    onExpire?(infoBar: InfoBar): void;
    onDismiss?(infoBar: InfoBar): void;
    onMount?(infoBar: InfoBar): void;
}

export default InfoBar;
