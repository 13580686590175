import * as React from 'react';
import { Navigate, matchPath, useLocation } from 'react-router-dom';
import signInStatus from '../enums/signInStatus';
import userOnboardingStageEnum from '../enums/userOnboardingStage';
import ConditionalRoute from './ConditionalRoute';
import { useAuthentication } from '../contexts/AuthenticationContext';
import { useOnboarding } from '../contexts/OnboardingContext';

interface IProtectedRouteProps {
    isAdminRoute?: boolean;
}

const ProtectedRoute = ({ isAdminRoute = false }: IProtectedRouteProps) => {
    const location = useLocation();
    const { authInfo } = useAuthentication();
    const { onboardingStage } = useOnboarding();

    // Check if user is logged in
    if (authInfo?.status !== signInStatus.Success) {
        return <Navigate to={'Accounts/Login'} state={{ from: location }} />;
    }

    if (authInfo.isAdmin) {
        return <ConditionalRoute condition={isAdminRoute} defaultUrl={'Admin'} />;
    }

    switch (onboardingStage) {
        case userOnboardingStageEnum.OrganisationSetup:
            return (
                <ConditionalRoute
                    condition={matchPath('Onboarding/OrganisationSetup', location.pathname) != null}
                    defaultUrl={'Onboarding/OrganisationSetup'}
                />
            );
        case userOnboardingStageEnum.AccountDetails:
            return (
                <ConditionalRoute
                    condition={matchPath('Onboarding/Questionnaire', location.pathname) != null}
                    defaultUrl={'Onboarding/Questionnaire'}
                />
            );
        default:
            return <ConditionalRoute condition={!isAdminRoute} defaultUrl={'/'} />;
    }
};

export default ProtectedRoute;
