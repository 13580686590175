import * as React from 'react';
import { AuthenticationProvider } from './AuthenticationContext';
import { OrganisationProvider } from './OrganisationContext';
import { OnboardingProvider } from './OnboardingContext';
import { ConfigurationsProvider } from './ConfigurationsContext';
import { NotificationProvider } from './NotificationProvider';

type AppContextProviderProps = {
    children: React.ReactNode;
};

const AppContextProvider = ({ children }: AppContextProviderProps) => {
    return (
        <ConfigurationsProvider>
            <AuthenticationProvider>
                <OnboardingProvider>
                    <OrganisationProvider>
                        <NotificationProvider>{children}</NotificationProvider>
                    </OrganisationProvider>
                </OnboardingProvider>
            </AuthenticationProvider>
        </ConfigurationsProvider>
    );
};

export default AppContextProvider;
