import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import { useOrganisation } from '../../contexts/OrganisationContext';
import OrganisationService from '../../services/OrganisationService';
import ErrorMessage from '../../model/services/ErrorMessage';
import LoadingSpinner from '../common/LoadingSpinner';
import TeamMember from '../../model/user/TeamMember';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import InviteTeamMemberModal from '../common/InviteTeamMemberModal';
import ConfirmDeleteUserModal from './ConfirmDeleteUserModal';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import { ReactComponent as MailIcon } from '../../content/icons/Icon-Mail.svg';
import { ReactComponent as DeleteIcon } from '../../content/icons/Icon-Delete.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ProfileCircle from '../common/ProfileCircle';

const MAX_SEATS = 15;

const TeamMembersPage = () => {
    const { defaultOrganisation } = useOrganisation();
    const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showConfirmDeleteUserModal, setShowConfirmDeleteUserModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const authContext = useAuthentication();
    const { formatMessage } = useIntl();

    useEffect(() => {
        setIsLoading(true);
        OrganisationService.getTeamMembers(defaultOrganisation!.id)
            .then((response) => {
                setTeamMembers(response);
            })
            .catch((error: ErrorMessage) => {
                // TODO: Add error info bar
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [defaultOrganisation]);

    const deleteTeamMember = () => {
        // TODO: Call delete team member
    };

    const handleInviteSuccess = (newTeamMember: TeamMember) => {
        setTeamMembers([...teamMembers, ...[newTeamMember]]);
    };

    const getStatusLabel = (status: string) => {
        if (status === 'InviteSent') {
            return <FormattedMessage id="Profile.TeamMembersPage.InviteSent" />;
        } else if (status === 'Active') {
            return <FormattedMessage id="Profile.TeamMembersPage.Active" />;
        } else if (status === 'Inactive') {
            return <FormattedMessage id="Profile.TeamMembersPage.Inactive" />;
        } else {
            return '-';
        }
    };

    const listRoles = (roles: string[]) => {
        if (roles.length > 0) {
            return (
                <Fragment>
                    {roles.map((role, index) => {
                        return (
                            <span key={index}>
                                {role} <br />
                            </span>
                        );
                    })}
                </Fragment>
            );
        } else {
            return <span>&#45;</span>;
        }
    };

    const renderHeader = () => {
        return (
            <Grid container justifyContent="space-between" alignItems="flex-end" p={2}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        <FormattedMessage id="Profile.TeamMembersPage.TeamMembers" />
                    </Typography>
                </Grid>
                {defaultOrganisation!.isOwner && (
                    <React.Fragment>
                        <Grid item>
                            <Typography variant="subtitle1">
                                <FormattedMessage id="Profile.TeamMembersPage.InviteTeamMembersPrompt" />
                            </Typography>
                            <Typography variant="subtitle1" color="primary">
                                <b>
                                    {formatMessage(
                                        { id: 'Profile.TeamMembersPage.NumberOfSeatsLeft' },
                                        { seatsLeft: MAX_SEATS - teamMembers.length }
                                    )}
                                </b>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={teamMembers.length >= MAX_SEATS}
                                variant="contained"
                                color="primary"
                                onClick={() => setShowInviteModal(true)}
                            >
                                <AddIcon />
                                <FormattedMessage id="Profile.TeamMembersPage.InviteTeamMember" />
                            </Button>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    };

    const renderTable = () => {
        return (
            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormattedMessage id="Profile.TeamMembersPage.NameColumn" />
                            </TableCell>
                            <TableCell align="left">
                                <FormattedMessage id="Profile.TeamMembersPage.StatusColumn" />
                            </TableCell>
                            <TableCell align="left">
                                <FormattedMessage id="Profile.TeamMembersPage.RoleColumn" />
                            </TableCell>
                            {defaultOrganisation!.isOwner && (
                                <TableCell align="left">
                                    <FormattedMessage id="Profile.TeamMembersPage.Actions" />
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teamMembers.map((member, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <ProfileCircle fullName={`${member.firstName} ${member.lastName}`} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">
                                                <b>
                                                    {member.firstName} {member.lastName}
                                                </b>
                                            </Typography>
                                            <Typography variant="body2">{member.email}</Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell align="left">{getStatusLabel(member.status)}</TableCell>
                                <TableCell align="left">{listRoles(member.roleNames)}</TableCell>
                                {defaultOrganisation!.isOwner && (
                                    <TableCell align="left">
                                        {member.status === 'InviteSent' && (
                                            <Tooltip
                                                title={formatMessage({
                                                    id: 'Profile.TeamMembersPage.ResendInvite',
                                                })}
                                            >
                                                <IconButton>
                                                    <MailIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {authContext.authInfo.username !== member.email && (
                                            <Tooltip
                                                title={formatMessage({ id: 'Profile.TeamMembersPage.RemoveMember' })}
                                            >
                                                <IconButton onClick={deleteTeamMember}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <React.Fragment>
            <Grid container direction="column" spacing={5}>
                <Grid item component={Paper} square elevation={0}>
                    <Container maxWidth="md">{renderHeader()}</Container>
                </Grid>
                <Grid item>
                    <Container maxWidth="md">{isLoading ? <LoadingSpinner /> : renderTable()}</Container>
                </Grid>
            </Grid>
            <InviteTeamMemberModal
                setIsModalOpen={setShowInviteModal}
                isModalOpen={showInviteModal}
                onSuccess={handleInviteSuccess}
                teamMembers={teamMembers}
            />
            <ConfirmDeleteUserModal
                setIsModalOpen={setShowConfirmDeleteUserModal}
                isOpen={showConfirmDeleteUserModal}
                deleteTeamMemberFunction={deleteTeamMember}
            />
        </React.Fragment>
    );
};

export default TeamMembersPage;
