import * as React from 'react';
import { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, Alert } from 'reactstrap';
import { ReactComponent as ResIcon } from '../../content/images/RD_hub_logo.svg';
import { ReactComponent as AlertIcon } from '../../content/icons/Icon-Alert.svg';
import useQueryParam from '../../hooks/useQueryParam';
import LoadingSpinner from '../common/LoadingSpinner';
import config from '../../config/app';
import SignInService from '../../services/SignInService';
import { useIntl, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import PasswordField from '../common/PasswordField';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { passwordValidCheck } from '../../helpers/passwordValidator';

interface ResetPasswordForm {
    password: string;
    confirmPassword: string | undefined;
}

const ResetPasswordPage = () => {
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const navigate = useNavigate();
    const intl = useIntl();
    const id = useQueryParam('id');
    const resetToken = useQueryParam('token');
    const expiredTokenRedirectPath = config.forgotPasswordPath + '?expiredResetToken=true';

    const passwordValidationRules = [
        intl.formatMessage({ id: 'Login.ResetPassword.PasswordValidationRule1' }),
        intl.formatMessage({ id: 'Login.ResetPassword.PasswordValidationRule2' }),
        intl.formatMessage({ id: 'Login.ResetPassword.PasswordValidationRule3' }),
        intl.formatMessage({ id: 'Login.ResetPassword.PasswordValidationRule4' }),
        intl.formatMessage({ id: 'Login.ResetPassword.PasswordValidationRule5' }),
    ];

    const resetPasswordFormSchema = Yup.object<ResetPasswordForm>().shape({
        password: Yup.string()
            .required(intl.formatMessage({ id: 'Common.FieldIsRequired' }))
            .test('invalid-password', '', (value) => {
                return passwordValidCheck(value);
            }),
        confirmPassword: Yup.string()
            .required(intl.formatMessage({ id: 'Common.FieldIsRequired' }))
            .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'Login.ResetPassword.PasswordsMustMatch' })),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(resetPasswordFormSchema) });

    useEffect(() => {
        if (!resetToken || !id) {
            navigate(expiredTokenRedirectPath);
            return;
        }

        setIsLoading(true);
        SignInService.checkPasswordResetToken(resetToken!, id!)
            .then((result) => {
                if (result !== true) {
                    // Redirect to forgot password if token has expired
                    navigate(expiredTokenRedirectPath);
                }
            })
            .catch(() => {
                // Redirect to forgot password page if token has expired
                navigate(expiredTokenRedirectPath);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [id, resetToken, expiredTokenRedirectPath, navigate]);

    const submitResetPassword = (data: ResetPasswordForm) => {
        setIsSubmitting(true);
        SignInService.resetPassword(resetToken!, data.password, id!)
            .then((response) => {
                if (response !== true) {
                    setErrorMessage(response.errorMessage);
                } else {
                    setErrorMessage(undefined);
                    setSubmitted(true);
                }
            })
            .catch((err) => {
                setErrorMessage(err);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const toLoginPage = () => {
        navigate(config.loginPath);
    };

    const getResetPasswordForm = () => {
        return (
            <Form className="account-form" onSubmit={handleSubmit(submitResetPassword)}>
                <div className="account-form__title">
                    <FormattedMessage id={submitted ? 'Common.Success' : 'Login.ResetPassword.ResetPasswordLabel'} />
                </div>
                {errorMessage && (
                    <Alert className="account-form__error">
                        <AlertIcon />
                        {errorMessage}
                    </Alert>
                )}
                {/* 
                        NOTE:   The validation error messages below are shown inside an alert box instead of having it in the built-in helperText of the TextField.
                                Do we really need to render these validation messages differently? 
                    */}
                {errors?.password?.type === 'invalid-password' && (
                    <Alert className="account-form__error">
                        <AlertIcon />
                        <FormattedMessage id="Login.ResetPassword.PasswordValidationRuleTitle" />
                        <Stack pl={4} alignItems="baseline">
                            {passwordValidationRules.map((rule, i) => {
                                return (
                                    <Typography key={i} variant="subtitle2">
                                        {rule}
                                    </Typography>
                                );
                            })}
                        </Stack>
                    </Alert>
                )}
                {submitted && (
                    <Fragment>
                        <FormGroup className="form-group--double-margin">
                            <div className="text-left mb-4">
                                {intl.formatMessage({ id: 'Login.ResetPassword.PasswordResetSuccess' })}
                            </div>
                        </FormGroup>
                        <FormGroup className="form-group--double-margin">
                            <div className="d-flex align-items-center justify-content-center">
                                <Button className="col" color="primary" variant="contained" onClick={toLoginPage}>
                                    <FormattedMessage id="Login.ResetPassword.ReturnToLogin" />
                                </Button>
                            </div>
                        </FormGroup>
                    </Fragment>
                )}
                {!submitted && (
                    <Fragment>
                        <FormGroup className="form-group--double-margin">
                            <PasswordField
                                fullWidth
                                name="password"
                                label={intl.formatMessage({ id: 'Login.ResetPassword.EnterPassword' })}
                                placeholder={intl.formatMessage({ id: 'Common.Password' })}
                                control={control}
                                dataTestId="password"
                            />
                        </FormGroup>
                        <FormGroup>
                            <PasswordField
                                fullWidth
                                name="confirmPassword"
                                label={intl.formatMessage({ id: 'Login.ResetPassword.ConfirmPassword' })}
                                placeholder={intl.formatMessage({ id: 'Common.Password' })}
                                control={control}
                                dataTestId="confirmPassword"
                            />
                        </FormGroup>
                        <FormGroup className="form-group--double-margin">
                            <Button
                                className="col"
                                color="primary"
                                type="submit"
                                variant="contained"
                                data-testid="reset-password-form-submit-button"
                                disabled={isSubmitting}
                            >
                                <FormattedMessage id="Login.ResetPassword.SetNewPassword" />
                            </Button>
                        </FormGroup>
                    </Fragment>
                )}
            </Form>
        );
    };

    return (
        <Container>
            <div className="flex-container-center">
                <div className="account-page__body__logo">
                    <ResIcon className="brand-svg" />
                </div>
                <div>{isLoading ? <LoadingSpinner loadingText="Checking token..." /> : getResetPasswordForm()}</div>
            </div>
        </Container>
    );
};

export default ResetPasswordPage;
