import React from 'react';
import PropTypes from 'prop-types';

const ButtonBarItem = (props) => {
    const getClassName = () => {
        let className = 'button-group__item';
        if (props.isActive) {
            className = className + ' button-group__item--active';
        }
        return className;
    };

    return (
        <li className={getClassName()} onClick={() => props.setValue(props.value)}>
            {props.label}
        </li>
    );
};

ButtonBarItem.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    isActive: PropTypes.bool,
    label: PropTypes.string,
};

export default ButtonBarItem;
