/* eslint-disable */
import { adminApi } from '../../helpers/ApiHelper';
import ErrorMessage from '../../model/services/ErrorMessage';
import User from '../../model/user/User';
import IsExistingUser from '../../model/user/admin/IsExistingUser';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getAllUsers = async () => {
    return await adminApi.get<User[], string>('User').then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const isExistingUser = async (email: string) => {
    return await adminApi.get<IsExistingUser, string>(`user/exists/email/${encodeURI(email)}`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const inviteTeamMembers = async (teamMembers: User[]) => {
    return await adminApi.post<User[], string>('user/teamMembers', JSON.stringify(teamMembers)).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const resendInvite = async (user: User) => {
    return await adminApi.post<User, string>('user/reinvite', JSON.stringify(user)).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const revokeUser = async (userId: string) => {
    return await adminApi.put<undefined, string>(`User/Revoke/${userId}`).then((response) => {
        if (response.ok) {
            return true;
        } else if (response.data !== undefined && !response.ok) {
            let errorMessage: ErrorMessage = { errorMessage: response.data };
            return errorMessage;
        } else {
            return undefinedResponse();
        }
    });
};

export default {
    getAllUsers,
    isExistingUser,
    inviteTeamMembers,
    revokeUser,
    resendInvite,
};
