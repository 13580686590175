import React from 'react';
import { ReactComponent as SuccessIcon } from '../../content/icons/Icon-Success.svg';
import PropTypes from 'prop-types';

function SuccessIconWrapper(props) {
    function getIconClass() {
        let className = 'progress-icon-container';
        switch (props.status) {
            case 'completed':
                className += ' completed';
                break;
            case 'incomplete':
                className += ' incomplete';
                break;
            case 'notStarted':
                className += ' not-started';
                break;
            default:
                className += ' incomplete';
        }
        return className;
    }

    return (
        <div className={getIconClass()}>
            <SuccessIcon />
        </div>
    );
}

SuccessIconWrapper.propTypes = {
    isCompleted: PropTypes.bool,
    notStarted: PropTypes.bool,
    status: PropTypes.string,
};

export default SuccessIconWrapper;
