import { Button } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ConfirmDeleteUserModal = (props: ConfirmDeleteUserModalProps) => {
    const deleteUser = () => {
        props.setIsModalOpen(false);
        props.deleteTeamMemberFunction();
    };

    return (
        <Modal centered size="sm" isOpen={props.isOpen}>
            <ModalHeader></ModalHeader>
            <ModalBody></ModalBody>
            <ModalFooter>
                <Button variant="text">
                    <FormattedMessage id="Common.Cancel" />
                </Button>
                <Button variant="contained" onClick={() => deleteUser()}>
                    <FormattedMessage id="Common.Confirm" />
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export interface ConfirmDeleteUserModalProps {
    setIsModalOpen(isOpen: boolean): void;
    isOpen: boolean;
    deleteTeamMemberFunction(): void;
}

export default ConfirmDeleteUserModal;
