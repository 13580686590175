import * as React from 'react';
import InfoBar from './InfoBar';
import { InfoBarProps } from './InfoBar';

class InfoBarManager extends React.Component<InfoBarManagerProps> {
    static defaultProps = {
        classes: [],
    };

    generateInfoBars() {
        let infoBarArray: JSX.Element[] = [];
        this.props.infoBarArray.forEach((element, index) => {
            infoBarArray.push(<InfoBar key={index} {...element} />);
        });
        return infoBarArray;
    }

    getClasses() {
        return this.props.classes!.length > 0
            ? `info-bar-wrapper ${this.props.classes!.join(' ')}`
            : `info-bar-wrapper`;
    }

    render() {
        return <div className={this.getClasses()}>{this.generateInfoBars()}</div>;
    }
}

export interface InfoBarManagerProps {
    classes?: string[];
    infoBarArray: InfoBarProps[];
}

export default InfoBarManager;
