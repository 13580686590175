import React, { useState, useEffect } from 'react';
import SelectList from '../../../components/common/SelectList';
import SearchInput from '../../../components/common/SearchInput';
import OrganisationRow from './OrganisationRow';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import Paginator from '../../../components/common/Paginator';
import { getPageSizes } from '../../../helpers/pagingHelper';
import OrganisationService from '../../../services/admin/OrganisationService';
import InfoBarManager from '../../../components/common/InfoBarManager';
import infoBarType from '../../../enums/infoBarType';
import { FormattedMessage, useIntl } from 'react-intl';

const OrganisationListPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [organisations, setOrganisations] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPageSize, setSelectedPageSize] = useState(10);
    const [infoBars, setInfoBars] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        OrganisationService.getAllOrganisations(selectedPageSize, currentPage)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setOrganisations(response.data);
                    setTotalPages(response.totalPages);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [selectedPageSize, currentPage]);

    const handlePageSizeChange = (e) => {
        setSelectedPageSize(e.target.value);
    };

    const filterBySearchPhrase = (e) => {
        // TODO: After design provide UI for search results
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const addErrorBar = (message) => {
        setInfoBars((i) => [...i, { type: infoBarType.error, message: message }]);
    };

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { type: infoBarType.success, message: message }]);
    };

    return (
        <main className="container-fluid organisation-management-page">
            <InfoBarManager infoBarArray={infoBars} />
            <h1 className="mb-4">
                <FormattedMessage id="Admin.Organisations.OrganisationsTitle" />
            </h1>
            <div className="d-flex mb-4">
                <div className="col-md-6">
                    <SearchInput
                        placeholder={intl.formatMessage({ id: 'Admin.Organisations.SearchPlaceholder' })}
                        onChange={(e) => filterBySearchPhrase(e)}
                    />
                </div>
                <div className="col-md-6">
                    <SelectList
                        className="float-right"
                        label={intl.formatMessage({ id: 'Admin.Organisations.PageSize' })}
                        options={getPageSizes()}
                        onChange={(e) => handlePageSizeChange(e)}
                    />
                </div>
            </div>

            {organisations.map((organisation, index) => {
                return (
                    <OrganisationRow
                        id={organisation.id}
                        name={organisation.name}
                        numberOfUsers={organisation.numberOfUsers}
                        key={index}
                        addErrorBar={addErrorBar}
                        addSuccessBar={addSuccessBar}
                    />
                );
            })}

            {totalPages > 1 && (
                <Paginator pages={totalPages} currentPage={currentPage} handlePaginate={setCurrentPage} />
            )}
        </main>
    );
};

export default OrganisationListPage;
