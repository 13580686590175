import $ from 'jquery';

/**
 * All mobileNav related logics e.g.
 * + Show, hide sidebar menu
 * + Hide sidebar on resizing
 */
export default function mobileNav() {
    $('.mobile-nav__header').on('click', toggle);
    // hide mobile-nav sidebar on window resize so that the sidebar won't suddenly appear
    // when resizing window from large to small
    $(window).on('resize', hideMobileNav);
    // hide the mobile nav so that user can see the content after navigation
    $('.mobile-nav__sidebar-container').on('click', hideMobileNav);
}

const mobileNavIsExpanded = () => $('.mobile-nav').hasClass('mobile-nav--expanded');

const hideMobileNav = () => {
    if (mobileNavIsExpanded()) {
        toggle();
    }
};

const toggle = () => {
    if (mobileNavIsExpanded()) {
        $('body').css('overflow', 'auto');
        $('.mobile-nav').removeClass('mobile-nav--expanded');
    } else {
        // In mobile UI, we want to hide the body scrollbar so that there's no 2 scrollbars display at the same time
        // (body's scrollbar and sidebar's scrollbar)
        $('body').css('overflow', 'hidden');
        $('.mobile-nav').addClass('mobile-nav--expanded');
    }
};
