import $ from 'jquery';

const createToggleLinkContent = (isEnumAttributes, collapsed) => {
    return `
        ${isEnumAttributes ? 'Possible enum values' : 'Nested properties'}
        <img src="/images/Chevron.svg" class="${collapsed ? 'rotate-180deg' : ''}"/>
    `;
};

export default function addToggleLinks() {
    $('.toggle-container').each(function () {
        const isEnumAttributes = $(this).hasClass('toggle-container--enum');
        const collapsed = !$(this).children('ul').is(':visible');
        const link = `
            <div class="toggle-container__link">
                ${createToggleLinkContent(isEnumAttributes, collapsed)}        
            </div>
        `;
        $(this).prepend(link);
    });
    $('.toggle-container__link').on('click', function () {
        const isEnumAttributes = $(this).parent().hasClass('toggle-container--enum');
        const collapsed = !$(this).next().is(':visible');
        $(this).next().toggle('fast');
        $(this).html(createToggleLinkContent(isEnumAttributes, !collapsed));
    });
}
