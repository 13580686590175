import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as HideEye } from '../../content/icons/Hide.svg';
import { ReactComponent as ShowEye } from '../../content/icons/Preview.svg';

function TogglePasswordVisibility({ inputType, togglePassword, index, className }) {
    const togglePasswordVisiblity = () => {
        togglePassword(inputType === 'password' ? 'text' : 'password', index);
    };

    const showHide = () => {
        return inputType === 'password' ? <HideEye title="Hide" /> : <ShowEye className="showEye" title="Show" />;
    };
    return (
        <i className={`password-eye ${className}`} onClick={togglePasswordVisiblity}>
            {showHide()}
        </i>
    );
}

TogglePasswordVisibility.propTypes = {
    inputType: PropTypes.string.isRequired,
    togglePassword: PropTypes.func.isRequired,
    index: PropTypes.number,
    className: PropTypes.string,
};
export default TogglePasswordVisibility;
