import { create } from 'apisauce';

const api = create({
    baseURL: '/api/',
});

const adminApi = create({
    baseURL: '/api/admin/',
});

const apiBase = create({
    baseURL: '/',
});

export { api, adminApi, apiBase };
