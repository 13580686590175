export default function WebhooksMenu() {
    const webhooksMenu = [
        {
            title: 'Webhooks',
            path: '/Docs/Reference/Webhooks',
            isActive: false,
            subItems: [
                {
                    title: 'Setting up webhooks',
                    path: '/Docs/Reference/Webhooks#setting-up-webhooks',
                    subItems: [
                        {
                            title: 'Webhooks settings',
                            path: '/Docs/Reference/Webhooks#webhooks-settings',
                        },
                        {
                            title: 'Logs',
                            path: '/Docs/Reference/Webhooks#logs',
                        },
                        {
                            title: 'Booking payload format',
                            path: '/Docs/Reference/Webhooks#booking-payload-format',
                        },
                        {
                            title: 'Booking event types',
                            path: '/Docs/Reference/Webhooks#booking-event-types',
                        },
                        {
                            title: 'Customer payload format',
                            path: '/Docs/Reference/Webhooks#customer-payload-format',
                        },
                        {
                            title: 'Customer event types',
                            path: '/Docs/Reference/Webhooks#customer-event-types',
                        },
                        {
                            title: 'Errors',
                            path: '/Docs/Reference/Webhooks#errors',
                        },
                        {
                            title: 'Timeouts',
                            path: '/Docs/Reference/Webhooks#timeouts',
                        },
                        {
                            title: 'Retries',
                            path: '/Docs/Reference/Webhooks#retries',
                        },
                    ],
                },
            ],
        },
    ];

    return { webhooksMenu: webhooksMenu };
}
