import * as React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import ClientSecretModal from './ClientSecretModal';
import ClientSecretWarningModal from './ClientSecretWarningModal';
import AppAuthenticationSettings from '../../../../model/appManagement/AppAuthenticationSettings';
import AppService from '../../../../services/AppService';
import MomentHelper from '../../../../helpers/MomentHelper';
import { useLocation } from 'react-router-dom';

const ClientSecretCard = (props: ClientSecretCardProps) => {
    const { state } = useLocation();
    const [clientSecret, setClientSecret] = useState(state?.clientSecret);
    const [clientSecretModalOpen, setClientSecretModalOpen] = useState(state?.clientSecret.length > 0);

    const [clientSecretWarningModalOpen, setClientSecretWarningModalOpen] = useState(props.isSecretNull);
    const [isLoading, setIsLoading] = useState(false);
    const [maskedSecret, setMaskedSecret] = useState(props.appAuthSettings?.maskedClientSecret);

    useEffect(() => {
        setMaskedSecret(props.appAuthSettings?.maskedClientSecret);
    }, [props.appAuthSettings?.maskedClientSecret]);

    const rotateClientSecret = () => {
        setIsLoading(true);
        AppService.rotateClientSecret(props.appAuthSettings)
            .then((response) => {
                setClientSecret(response.clientSecret);
                setMaskedSecret(
                    `${response.clientSecret.substring(0, 4)}************${response.clientSecret.substring(
                        response.clientSecret.length - 4
                    )}`
                );
            })
            .catch(() => {
                // TODO: Add error toast
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onModalClose = () => {
        setClientSecret('');
        setClientSecretModalOpen(false);
    };

    const onModalOpen = () => {
        rotateClientSecret();
        setClientSecretModalOpen(true);
    };

    const onWarningModalClose = () => {
        setClientSecretWarningModalOpen(false);
    };

    return (
        <div className="client-secret-card mb-4">
            <h5 className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.ClientSecret" />
            </h5>
            <div className="secret-box">
                <div className="col-6 secret-section mr-1">
                    <p>
                        <FormattedMessage id="AppManagement.AppDetails.SecretKey" />
                    </p>
                    <div className="text-wrap">{maskedSecret}</div>
                </div>
                <div className="col-6 secret-section">
                    <p>
                        <FormattedMessage id="AppManagement.AppDetails.DateCreated" />
                    </p>
                    <div className="text-wrap">
                        {maskedSecret && MomentHelper.newInstance(props.appAuthSettings?.createdDate).format('LL')}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button onClick={() => setClientSecretWarningModalOpen(true)} color="dark" className="text-uppercase">
                    <FormattedMessage id="AppManagement.AppDetails.GenerateNewSecret" />
                </Button>
            </div>
            <ClientSecretModal
                clientSecret={clientSecret}
                isOpen={clientSecretModalOpen}
                onClose={onModalClose}
                isLoading={isLoading}
            />
            <ClientSecretWarningModal
                isOpen={clientSecretWarningModalOpen}
                closeModal={onWarningModalClose}
                openClientSecretModal={onModalOpen}
                isSecretNull={props.isSecretNull}
            />
        </div>
    );
};

export interface ClientSecretCardProps {
    appAuthSettings: AppAuthenticationSettings;
    isSecretNull: boolean;
}

export default ClientSecretCard;
