import $ from 'jquery';
import ApiDocsService from '../services/ApiDocsService';

/**
 * To replace parameters (e.g. `$Model.ProviderId`) in code blocks by a value
 */
export default async function evaluateParametersInCodeBlocks() {
    const apiExamples = $('.api-example');
    if (!apiExamples.length) {
        return;
    }
    const parameters = await ApiDocsService.parametersForApiCallExamples();
    apiExamples.each(function () {
        const code = $(this).children('code');
        let codeText = code.text();
        for (let key in parameters) {
            let value = parameters[key];
            codeText = codeText.replaceAll(key, value);
        }
        code.text(codeText);
    });
}
