import React, { Fragment, useState, useEffect } from 'react';
import { ReactComponent as AccountIcon } from '../../../content/icons/Icon-Account.svg';
import { ReactComponent as NoResultsIcon } from '../../../content/icons/Icon-NoResults.svg';
import AdminUserService from '../../../services/admin/UserService';
import ProfileCircle from '../../../components/common/ProfileCircle';
import InviteTeamMemberModal from '../../../components/common/InviteTeamMemberModal';
import userStatus from '../../../enums/userStatus';
import SearchInput from '../../../components/common/SearchInput';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { Button } from 'reactstrap';
import RevokeUserModal from '../RevokeUserModal';
import InfoBarManager from '../../../components/common/InfoBarManager';
import infoBarType from '../../../enums/infoBarType';
import MomentHelper from '../../../helpers/MomentHelper';
import { useAuthentication } from '../../../contexts/AuthenticationContext';
import { FormattedMessage, useIntl } from 'react-intl';
import Table from '../../../components/common/DataTable';

function UserManagementPage() {
    const [users, setUsers] = useState([]);
    const [displayableUsers, setDisplayableUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
    const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);
    const [userToRevoke, setUserToRevoke] = useState(null);
    const [infoBars, setInfoBars] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState();
    const intl = useIntl();

    const addSuccessBar = (message) => {
        setInfoBars((i) => [...i, { type: infoBarType.success, message: message }]);
    };

    const addErrorBar = (message) => {
        setInfoBars((i) => [...i, { type: infoBarType.error, message: message }]);
    };

    const toggleRevokeModal = () => {
        setIsRevokeModalOpen(!isRevokeModalOpen);
    };
    const { authInfo } = useAuthentication();

    useEffect(() => {
        AdminUserService.getAllUsers()
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setUsers(response);
                    setDisplayableUsers(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (searchTerm && searchTerm.length > 0) {
            setDisplayableUsers(users.filter((user) => user.email.toLowerCase().includes(searchTerm)));
        } else {
            setDisplayableUsers([...users]);
        }
    }, [searchTerm, users]);

    function getActionButton(user) {
        if (authInfo.username !== user.email) {
            if (user.status === userStatus.Active)
                return (
                    <Button onClick={() => revokeButtonClicked(user)} color="outline-primary">
                        <FormattedMessage id="Admin.UserManagement.Revoke" />
                    </Button>
                );

            return (
                <Button onClick={() => handleResendInvite(user)} color="primary">
                    <FormattedMessage id="Admin.UserManagement.Resend" />
                </Button>
            );
        }
        return (
            <Button color="primary" disabled>
                <FormattedMessage id="Admin.UserManagement.Revoke" />
            </Button>
        );
    }

    function handleResendInvite(user) {
        AdminUserService.resendInvite(user).then((response) => {
            if ('errorMessage' in response) {
                addErrorBar(response.errorMessage);
            } else {
                setUsers(users.map((u) => (u.id === response.id ? { ...u, invitedDate: response.invitedDate } : u)));
                addSuccessBar(
                    intl.formatMessage(
                        { id: 'Admin.UserManagement.ResendSuccessMsg' },
                        {
                            firstName: user.firstName,
                            lastName: user.lastName,
                        }
                    )
                );
            }
        });
    }

    function revokeButtonClicked(user) {
        setUserToRevoke(user);
        toggleRevokeModal();
    }

    const filterBySearchPhrase = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const submitAddTeamMembers = (invites) => {
        setIsSubmitting(true);
        // filter out invitations with email and name only
        let _invitations = invites.filter((invitation) => invitation.name && invitation.email);
        AdminUserService.inviteTeamMembers(_invitations)
            .then((response) => {
                if ('errorMessage' in response) {
                    addErrorBar(response.errorMessage);
                } else {
                    const copyMembers = [...users];
                    response.forEach((user) => {
                        copyMembers.push(user);
                    });
                    setUsers(copyMembers);
                    setDisplayableUsers(copyMembers);
                    setIsInvitationModalOpen(false);
                    addSuccessBar(
                        intl.formatMessage(
                            { id: 'Admin.UserManagement.InvitationsSent' },
                            { inviteLength: _invitations.length }
                        )
                    );
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const setIsModalOpen = (e) => {
        setIsInvitationModalOpen(e);
    };

    const validateEmail = async (value) => {
        let isNotResdiaryUser = value.toLowerCase().indexOf('resdiary.com') < 0;
        if (isNotResdiaryUser) {
            return intl.formatMessage({ id: 'Admin.UserManagement.EmailResDiary' });
        }
        //check if email is being used already no need to filter on organisation as RD Admin user shouldn't have more than 1 role/organisation
        let isExisting = users.some((el) => el.email === value);
        return { isExistingUser: isExisting };
    };

    const lastLoggedIn = (date) => {
        let fromNow = MomentHelper.fromNow(date);

        return fromNow;
    };
    const renderOwnerBadge = (email) => {
        if (authInfo.username === email) {
            return (
                <div className="section d-inline-block">
                    <span className="badge badge-info badge-outline">
                        <FormattedMessage id="Admin.UserManagement.Owner" />
                    </span>
                </div>
            );
        }
    };
    const noResultsContainer = () => {
        return (
            <div className="col-md-6 offset-md-3 pt-2 align-items-center text-center mt-5">
                <div className="test-diary-icon text-center">
                    <NoResultsIcon />
                </div>
                <div className="row  m-3 p-2">
                    <h5 className="col-12 pb-2">
                        <FormattedMessage id="Admin.UserManagement.NoResults" />
                    </h5>
                    <p className="col-12 mb-0">
                        <FormattedMessage id="Admin.UserManagement.EmailCorrectPrompt" />
                    </p>
                </div>
            </div>
        );
    };

    const renderInviteTeamMemberModal = () => {
        return (
            <InviteTeamMemberModal
                isModalOpen={isInvitationModalOpen}
                teamMembers={users}
                title={intl.formatMessage({ id: 'Admin.UserManagement.InviteTeamMembers' })}
                submitAddTeamMembers={submitAddTeamMembers}
                setIsModalOpen={(e) => setIsModalOpen(e)}
                validateEmail={validateEmail}
                isDisableSubmit={isSubmitting}
            />
        );
    };

    function revokeUserSuccessCallback(userId) {
        const usersCopy = [...users];
        const userIndex = usersCopy.findIndex((user) => user.id === userId);
        usersCopy[userIndex].status = userStatus.Inactive;
        setUsers(usersCopy);
        setDisplayableUsers(usersCopy);
    }

    const columns = [
        {
            name: intl.formatMessage({ id: 'Admin.UserManagement.NameCol' }),
            displayFormat: (user) => {
                return (
                    <div className="user-details">
                        <div className="section d-inline-block">
                            <ProfileCircle fullName={`${user.firstName} ${user.lastName}`} />
                        </div>
                        <div className="section d-inline-block">
                            <label>
                                <b>
                                    {user.firstName} {user.lastName}
                                </b>
                            </label>
                            <br />
                            {user.email}
                        </div>
                        {renderOwnerBadge(user.email)}
                    </div>
                );
            },
        },
        {
            name: intl.formatMessage({ id: 'Admin.UserManagement.StatusCol' }),
            className: 'user-status',
            displayFormat: (user) => userStatus[user.status],
        },
        {
            name: intl.formatMessage({ id: 'Admin.UserManagement.LastActiveCol' }),
            className: 'user-last-login',
            displayFormat: (user) => lastLoggedIn(user.lastLogin),
        },
        {
            name: intl.formatMessage({ id: 'Admin.UserManagement.ActionsCol' }),
            className: 'action-button',
            displayFormat: (user) => <>{user.status !== userStatus.Inactive && getActionButton(user)}</>,
        },
    ];

    if (isLoading) return <LoadingSpinner />;

    return (
        <Fragment>
            <InfoBarManager infoBarArray={infoBars} />
            <div className="container-fluid user-management-page">
                <main>
                    <h1 className="mb-4">
                        <FormattedMessage id="Admin.UserManagement.Title" />
                    </h1>
                    <div className="d-flex mb-4">
                        <div className="col-md-8">
                            <SearchInput
                                placeholder={intl.formatMessage({ id: 'Admin.UserManagement.SearchPlaceholder' })}
                                onChange={(e) => filterBySearchPhrase(e)}
                            />
                        </div>
                        <div className="col-md-4">
                            <button
                                className="btn btn-primary float-right"
                                onClick={() => {
                                    setIsInvitationModalOpen(true);
                                }}
                            >
                                <AccountIcon className="profile-icon" />
                                <FormattedMessage id="Admin.UserManagement.InviteTeamMembers" />
                            </button>
                        </div>
                    </div>
                    <Table
                        className="users-table"
                        tableName={'Roles Table'}
                        columns={columns}
                        data={displayableUsers}
                        isLoading={isLoading}
                        renderEmptyResult={noResultsContainer}
                    />
                    {isRevokeModalOpen && (
                        <RevokeUserModal
                            isOpen={isRevokeModalOpen}
                            user={userToRevoke}
                            toggleModal={toggleRevokeModal}
                            addSuccessBar={addSuccessBar}
                            addErrorBar={addErrorBar}
                            revokeUserSuccessCallback={revokeUserSuccessCallback}
                        />
                    )}
                </main>
            </div>
            {renderInviteTeamMemberModal()}
        </Fragment>
    );
}

export default UserManagementPage;
