import React from 'react';
import DataTable from '../common/DataTable';
import appInstallationStatus from '../../enums/appInstallationStatus';
import PropTypes from 'prop-types';
import MomentHelper from '../../helpers/MomentHelper';
import { Button } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';

const InstallationTable = (props) => {
    const intl = useIntl();

    const actionType = {
        [appInstallationStatus.pending]: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.InvitedBy' }),
        [appInstallationStatus.installed]: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.InstalledBy' }),
        [appInstallationStatus.uninstalled]: intl.formatMessage({
            id: 'AppManagement.AppInstallationsPage.UninstalledBy',
        }),
        [appInstallationStatus.revoked]: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.RevokedBy' }),
    };

    const columns = [
        {
            id: 'diaryId',
            name: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.DiaryIdColumn' }),
            displayFormat: (x) => x.diaryId,
        },
        {
            id: 'venueName',
            name: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.VenueNameColumn' }),
            displayFormat: (x) => x.venueName,
        },
        {
            id: 'type',
            name: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.TypeColumn' }),
            displayFormat: (x) => {
                return (
                    <div>
                        {actionType[x.status]} by {x.type.actionBy.name} <br />({x.type.actionBy.email}) <br />
                        on {formatDate(x.type.actionDate)}
                    </div>
                );
            },
        },
        {
            id: 'status',
            name: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.StatusColumn' }),
            displayFormat: (x) => {
                let className = 'td-installation-status';
                if (x.status === appInstallationStatus.pending) {
                    className += ' td-installation-status-pending';
                } else if (x.status === appInstallationStatus.installed) {
                    className += ' td-installation-status-installed';
                } else if (x.status === appInstallationStatus.uninstalled) {
                    className += ' td-installation-status-uninstalled';
                } else if (x.status === appInstallationStatus.revoked) {
                    className += ' td-installation-status-revoked';
                }
                return <div className={className}>{x.status.toUpperCase()}</div>;
            },
        },
        {
            id: 'installationDate',
            name: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.InstallationDateColumn' }),
            displayFormat: (x) => {
                if (x.status === appInstallationStatus.installed) {
                    return formatDate(x.installationDate);
                }
            },
        },
        {
            id: 'action',
            name: intl.formatMessage({ id: 'AppManagement.AppInstallationsPage.ActionColumn' }),
            displayFormat: (x) => getActionButton(x.status),
        },
    ];

    function getActionButton(status) {
        if (status === appInstallationStatus.revoked || status === appInstallationStatus.uninstalled) {
            return (
                <Button color="primary">
                    <FormattedMessage id="AppManagement.AppInstallationsPage.Resend" />
                </Button>
            );
        } else if (status === appInstallationStatus.installed) {
            return (
                <Button color="outline-primary">
                    <FormattedMessage id="AppManagement.AppInstallationsPage.Revoke" />
                </Button>
            );
        }
        return (
            <Button color="primary" disabled>
                <FormattedMessage id="AppManagement.AppInstallationsPage.Resend" />
            </Button>
        );
    }

    function formatDate(timeStamp) {
        if (!timeStamp) return;
        return MomentHelper.newInstance(timeStamp).format('DD/MM/YYYY hh:mm:ss A');
    }

    return (
        <div>
            <DataTable
                className="mb-0"
                columns={columns}
                data={props.data}
                isLoading={props.isLoading}
                renderEmptyResult={props.renderEmptyResult}
            />
        </div>
    );
};

InstallationTable.protoTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    application: PropTypes.object,
    handleApplicationSetup: PropTypes.func,
    isLoading: PropTypes.bool,
    renderEmptyResult: PropTypes.elementTypes,
};

export default InstallationTable;
