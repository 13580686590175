import React, { useState, useEffect, Fragment } from 'react';
import { Button, Collapse } from 'reactstrap';
import { ReactComponent as ChevronUp } from '../../../content/icons/Chevron.svg';
import { ReactComponent as ChevronDown } from '../../../content/icons/chevron-down.svg';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import ProfileCircle from '../../../components/common/ProfileCircle';
import userStatus from '../../../enums/userStatus';
import PropTypes from 'prop-types';
import OrganisationService from '../../../services/admin/OrganisationService';
import MomentHelper from '../../../helpers/MomentHelper';
import RevokeUserModal from '../RevokeUserModal';
import AdminOrganisationService from '../../../services/admin/OrganisationService';
import { FormattedMessage, useIntl } from 'react-intl';

const OrganisationRow = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);
    const [userToRevoke, setUserToRevoke] = useState(null);
    const intl = useIntl();

    useEffect(() => {
        if (!isOpen) return;
        OrganisationService.getUsersInOrganisation(props.id)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setUsers(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [isOpen, props.id]);

    const getRoleName = (role) => {
        switch (role) {
            case 'Admin':
                return intl.formatMessage({ id: 'Admin.Organisations.AdminRole' });
            case 'Owner':
                return intl.formatMessage({ id: 'Admin.Organisations.OwnerRole' });
            case 'Member':
                return intl.formatMessage({ id: 'Admin.Organisations.MemberRole' });
            default:
                return role;
        }
    };

    const toggleRevokeModal = () => {
        setIsRevokeModalOpen(!isRevokeModalOpen);
    };

    const getActionButton = (user) => {
        if (user.status === userStatus.Active)
            return (
                <Button
                    onClick={() => {
                        setUserToRevoke(user);
                        setIsRevokeModalOpen(true);
                    }}
                    color="outline-primary"
                >
                    <FormattedMessage id="Admin.Organisations.Revoke" />
                </Button>
            );

        return (
            <Button
                disabled={user.invitationDate ? MomentHelper.durationAsHours(user.invitationDate) < 24 : false}
                onClick={() => resendOrganisationInvitation(user)}
                color="primary"
            >
                <FormattedMessage id="Admin.Organisations.Resend" />
            </Button>
        );
    };

    const resendOrganisationInvitation = (user) => {
        AdminOrganisationService.resendOrganisationInvitation(props.id, user.id).then((response) => {
            if (!('errorMessage' in response)) {
                props.addSuccessBar(
                    intl.formatMessage({ id: 'Admin.Organisations.InviteSuccess' }, { email: user.email })
                );
            } else {
                props.addErrorBar(
                    intl.formatMessage({ id: 'Admin.Organisations.InviteFailed' }, { email: user.email })
                );
            }
        });
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const listRoles = (roles) => {
        if (roles.length > 0) {
            return (
                <Fragment>
                    {roles.map((role, index) => {
                        let isLast = index === roles.length - 1;
                        return (
                            <div key={index}>
                                {getRoleName(role)} {isLast ? '' : ','}
                            </div>
                        );
                    })}
                </Fragment>
            );
        } else {
            return <div>-</div>;
        }
    };

    function getFormattedDate(timeStamp) {
        if (!timeStamp) {
            return;
        }
        return MomentHelper.newInstance(timeStamp).format('DD/MM/YYYY');
    }

    function revokeUserSuccessCallback(userId) {
        const usersCopy = [...users];
        const userIndex = usersCopy.findIndex((user) => user.id === userId);
        usersCopy[userIndex].status = userStatus.Inactive;
        setUsers(usersCopy);
    }

    return (
        <div className="organisation-row" key={props.id}>
            <div className="organisation-row-header" onClick={() => setIsOpen(!isOpen)}>
                <div className="organisation-name col-6">{props.name}</div>
                <div className="col-6 organisation-user-count">
                    <label className="mb-0">{users.length === 0 ? props.numberOfUsers : users.length} member(s)</label>
                    <span className="ml-4">{isOpen ? <ChevronUp /> : <ChevronDown />}</span>
                </div>
            </div>
            <Collapse isOpen={isOpen}>
                <Fragment>
                    <table className="users-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th className="user-status">
                                    <FormattedMessage id="Admin.Organisations.StatusColumn" />
                                </th>
                                <th className="user-role">
                                    <FormattedMessage id="Admin.Organisations.RoleColumn" />
                                </th>
                                <th className="user-last-login">
                                    <FormattedMessage id="Admin.Organisations.LastLoggedInColumn" />
                                </th>
                                <th className="action-button">
                                    <FormattedMessage id="Admin.Organisations.ActionsColumn" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={index}>
                                    <td className="user-details">
                                        <div className="section d-inline-block">
                                            <ProfileCircle fullName={`${user.firstName} ${user.lastName}`} />
                                        </div>
                                        <div className="section d-inline-block">
                                            <label>
                                                {user.firstName} {user.lastName}
                                            </label>
                                            <br />
                                            {user.email}
                                        </div>
                                    </td>
                                    <td className="user-status">{userStatus[user.status]}</td>
                                    <td className="user-role">{listRoles(user.assignedRoles)}</td>
                                    <td className="user-last-login">{getFormattedDate(user.lastLoginDate)}</td>
                                    {user.status !== userStatus.Inactive && (
                                        <td className="action-button">{getActionButton(user)}</td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Fragment>
                {isRevokeModalOpen && (
                    <RevokeUserModal
                        isOpen={isRevokeModalOpen}
                        user={userToRevoke}
                        toggleModal={toggleRevokeModal}
                        addSuccessBar={props.addSuccessBar}
                        addErrorBar={props.addErrorBar}
                        revokeUserSuccessCallback={revokeUserSuccessCallback}
                    />
                )}
            </Collapse>
        </div>
    );
};

OrganisationRow.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    numberOfUsers: PropTypes.number.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
};

export default OrganisationRow;
