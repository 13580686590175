import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { ReactComponent as SearchIcon } from '../../content/icons/Search.svg';
import { debounce } from 'lodash';

const SearchInput = (props: SearchInputProps) => {
    const debouncedResults = useMemo(() => {
        return debounce(props.onChange, 500);
    }, [props.onChange]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <div className="search">
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <SearchIcon />
                </InputGroupAddon>

                <Input type="search" placeholder={props.placeholder} onChange={debouncedResults} />
            </InputGroup>
        </div>
    );
};

interface SearchInputProps {
    onChange(e: React.ChangeEvent<HTMLInputElement>): void;
    placeholder?: string;
}

export default SearchInput;
