import * as React from 'react';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import InstallUrlCard from './InstallUrlCard';
import RedirectUrlCard from './RedirectUrlCard';
import AppAuthenticationSettings from '../../../../model/appManagement/AppAuthenticationSettings';
import AppService from '../../../../services/AppService';
import { useNotification } from '../../../../contexts/NotificationProvider';
import Button from '@mui/material/Button';
import { urlRegex } from '../../../../helpers/urlValidator';
import App from '../../../../model/appManagement/App';

interface OAuthSettingsForm {
    installUrl?: string | undefined;
    redirectUrl: string;
}

export interface OAuthSettingsPageProps {
    app: App | undefined;
    isTestEnvironment: boolean;
    onSettingsUpdated: (settings: AppAuthenticationSettings) => void;
}

const OAuthSettingsPage = ({ app, isTestEnvironment, onSettingsUpdated }: OAuthSettingsPageProps) => {
    const environment = isTestEnvironment ? 'Test' : 'Production';
    let authSettings = app?.appAuthenticationSettings.find((x) => x.environment === environment);

    const { displayNotification } = useNotification();
    const intl = useIntl();

    const oauthSettingsFormSchema = Yup.object<OAuthSettingsForm>().shape({
        installUrl: Yup.string().matches(urlRegex, {
            message: intl.formatMessage({ id: 'AppManagement.AppDetails.InvalidUrlMessage' }),
            excludeEmptyString: true,
        }),
        redirectUrl: Yup.string()
            .required(intl.formatMessage({ id: 'AppManagement.AppDetails.RedirectUrlRequiredMessage' }))
            .matches(urlRegex, intl.formatMessage({ id: 'AppManagement.AppDetails.InvalidUrlMessage' })),
    });

    const {
        getValues,
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid, isSubmitted },
    } = useForm<OAuthSettingsForm>({
        resolver: yupResolver(oauthSettingsFormSchema),
    });

    useEffect(() => {
        if (authSettings) {
            reset({
                installUrl: authSettings.installUrl,
                redirectUrl: authSettings.redirectUrl,
            });
        }
    }, [authSettings, reset]);

    const getInstallUrl = () => getValues('installUrl') || '';
    const getRedirectUrl = () => getValues('redirectUrl');

    const saveApiSettings = (data: OAuthSettingsForm) => {
        if (authSettings) {
            let updatedSettings: AppAuthenticationSettings = {
                ...authSettings,
                installUrl: data.installUrl,
                redirectUrl: data.redirectUrl,
            };
            AppService.saveApplicationAuthenticationSettings(updatedSettings).then((response) => {
                onSettingsUpdated(response);
                displayNotification({
                    message: intl.formatMessage({ id: 'AppManagement.AppDetails.SettingSavedSuccessMessage' }),
                });
            });
        }
    };

    if (!authSettings) return <></>;

    return (
        <>
            <div>
                <h3 className="mb-3">
                    {environment.toUpperCase()} <FormattedMessage id="AppManagement.AppDetails.SettingsMenuLabel" />
                </h3>
                <p className="mb-2">
                    <FormattedMessage id="AppManagement.AppDetails.SettingsDescription" />
                </p>
            </div>
            <InstallUrlCard register={register('installUrl')} errors={errors.installUrl} getValue={getInstallUrl} />
            <RedirectUrlCard register={register('redirectUrl')} errors={errors.redirectUrl} getValue={getRedirectUrl} />
            <div className="oauth-card">
                <div className="d-flex justify-content-start w-100">
                    <Button
                        disabled={isSubmitted && !isValid}
                        disableElevation
                        onClick={handleSubmit(saveApiSettings)}
                        variant="contained"
                    >
                        {intl.formatMessage({ id: 'Common.Save' })}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default OAuthSettingsPage;
