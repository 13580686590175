import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#054169', // RD-Navy
            dark: '#052A42',
            light: '#BFDFF9',
        },
        secondary: {
            main: '#00B8F0',
            dark: '#0096C3',
            light: '#C1EFFE',
        },
        error: {
            main: '#EA2C2C',
            dark: '#980904',
            light: '#FFEBEE',
        },
        warning: {
            main: '#FD883F',
            dark: '#934106',
            light: '#FFF5E5',
        },
        success: {
            main: '#00A043',
            dark: '#0F5714',
            light: '#E8F5E9',
        },
        background: {
            //default: '#e5e9f0',
            default: '#FFF',
        },
    },
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f0f0f0',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: '#42474E',
                    '&:first-of-type': {
                        borderTopLeftRadius: '5px',
                    },
                    '&:last-of-type': {
                        borderTopRightRadius: '5px',
                    },
                },
            },
        },
    },
});

export default theme;
