import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import CopyTextCard from './CopyTextCard';
import ClientSecretCard from './ClientSecretCard';
import App from '../../../../model/appManagement/App';

const OAuthCredentialsPage = (props: OAuthCredentialsPageProps) => {
    const environment = props.isTestEnvironment ? 'Test' : 'Production';
    let authSettings = props.app?.appAuthenticationSettings.find((x) => x.environment === environment);
    if (!authSettings) return <></>;

    return (
        <React.Fragment>
            <div data-testid="oauth-credential-page">
                <h3 className="mb-3">
                    {environment!.toUpperCase()} <FormattedMessage id="AppManagement.AppDetails.CredentialsMenuLabel" />
                </h3>
                <p className="mb-2">
                    <FormattedMessage id="AppManagement.AppDetails.CredentialsDescription" />
                </p>
            </div>
            <CopyTextCard
                copyText={authSettings.oAuthUrl}
                formattedMessageHeader="AppManagement.AppDetails.OAuthUrl"
                id="oauthUrl"
            />
            <CopyTextCard
                copyText={authSettings.clientId}
                formattedMessageHeader="AppManagement.AppDetails.ClientId"
                id="clientId"
            />
            <ClientSecretCard appAuthSettings={authSettings} isSecretNull={!authSettings.maskedClientSecret} />
        </React.Fragment>
    );
};

export interface OAuthCredentialsPageProps {
    app: App | undefined;
    isTestEnvironment: boolean;
}

export default OAuthCredentialsPage;
