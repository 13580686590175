export const isMatching = (value1: string, value2: string): boolean => {
    return value1 === value2;
};

export const passwordValidCheck = (password: string): boolean => {
    if (
        password.match('[A-Z]') &&
        password.match('[a-z]') &&
        password.match('\\d') &&
        password.match('[~!@#\\$%\\^&\\*\\(\\)\\-_\\+=\\{\\}\\[\\]\\|\\:;\\?\\/,\\.<>]') &&
        password.match('^.{6,}$')
    ) {
        return true;
    } else {
        return false;
    }
};
