import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SignInResponse from '../model/login/SignInResponse';
import SignInService from '../services/SignInService';
import config from './../config/app';

type AuthenticationProviderProps = {
    children: React.ReactNode;
};

const defaultState: IAuthenticationContext = {
    authInfo: {
        username: '',
        isAdmin: false,
        fullName: '',
        firstName: '',
        lastName: '',
    },
    login: (_username: string, _password: string) => new Promise<SignInResponse>(() => {}),
    logout: () => new Promise<void>(() => {}),
    clearAuthInfo: () => {},
};

const AuthenticationContext = React.createContext<IAuthenticationContext>(defaultState);

export interface IAuthInfo extends SignInResponse {}

export interface IAuthenticationContext {
    authInfo: IAuthInfo;
    login: (username: string, password: string) => Promise<SignInResponse>;
    logout: () => Promise<void>;
    clearAuthInfo: () => void;
}

export const useAuthentication = () => {
    return useContext(AuthenticationContext);
};

export const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
    let navigate = useNavigate();
    const [authInfo, setAuthInfo] = useState<IAuthInfo>({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        SignInService.getStatus().then((response) => {
            setAuthInfo(response);
            setIsLoading(false);
        });
    }, []);

    function login(username: string, password: string) {
        return SignInService.signIn(username, password).then((response) => {
            setAuthInfo(response);
            return response;
        });
    }

    function logout() {
        return SignInService.signOut().then(() => {
            navigate(config.loginPath);
        });
    }

    function clearAuthInfo() {
        setAuthInfo(defaultState.authInfo);
    }

    const value = {
        authInfo,
        login,
        logout,
        clearAuthInfo,
    };

    return <AuthenticationContext.Provider value={value}>{!isLoading && children}</AuthenticationContext.Provider>;
};
