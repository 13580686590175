import React, { useState, useEffect, Fragment } from 'react';
import SelectList from '../../../components/common/SelectList';
import ButtonBar from '../../../components/common/ButtonBar';
import SearchInput from '../../../components/common/SearchInput';
import Paginator from '../../../components/common/Paginator';
import auditTrailEventType from '../../../enums/Admin/auditTrailEventType';
import { ReactComponent as AuditTrailIcon } from '../../../content/images/Audit-trail.svg';
import appType from '../../../enums/appType';
import { getPageSizes } from '../../../helpers/pagingHelper';
import AuditTrailTable from './AuditTrailTable';
import { FormattedMessage, useIntl } from 'react-intl';

const AuditTrailPage = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [pagedResult, setPagedResult] = useState();
    const [buttonOptions, setButtonOptions] = useState([]);
    const [selectedFilterButton, setSelectedFilterButton] = useState('All'); // eslint-disable-line
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [showPaginator, setShowPaginator] = useState();
    const intl = useIntl();

    useEffect(() => {
        const initialButtonSetup = [
            {
                index: 'All',
                value: 'All',
                isActive: true,
                label: intl.formatMessage({ id: 'Admin.AuditTrailPage.All' }),
            },
            {
                index: appType.private,
                value: appType.private,
                isActive: false,
                label: appType.private,
            },
            {
                index: appType.public,
                value: appType.public,
                isActive: false,
                label: appType.public,
            },
        ];

        if (isInitialLoad) {
            setButtonOptions(initialButtonSetup);
            setIsInitialLoad(false);
        }
        let testData = [
            {
                event: 'Update basic information',
                appType: appType.private,
                auditTrailEventType: auditTrailEventType.update,
                auditSubject: 'email address',
                before: 'jon.snow@abccorporation.co.uk',
                after: 'jon.snow123@abccorporation.co.uk',
                updatedDate: '10/06/2022 14:20:32',
                updatedBy: 'lisa@resdiary.com',
            },
            {
                event: 'Delete basic information',
                appType: appType.private,
                auditTrailEventType: auditTrailEventType.delete,
                auditSubject: 'email address',
                before: 'jon.snow@abccorporation.co.uk',
                after: '',
                updatedDate: '10/06/2022 14:20:32',
                updatedBy: 'claudia@resdiary.com',
            },
            {
                event: 'Add basic information',
                appType: appType.public,
                auditTrailEventType: auditTrailEventType.add,
                auditSubject: 'email address',
                before: '',
                after: 'jon.snow@abccorporation.com',
                updatedDate: '10/06/2022 14:20:32',
                updatedBy: 'craig@resdiary.com',
            },
            {
                event: 'Edit basic information',
                appType: appType.public,
                auditTrailEventType: auditTrailEventType.edit,
                auditSubject: 'Invited by Jon Snow',
                before: 'jon.snow@abccorporation.com',
                after: 'jon.snow@abccorporation.com',
                updatedDate: '10/06/2022 14:20:32',
                updatedBy: 'craig@resdiary.com',
            },
            {
                event: 'Edit basic information',
                appType: appType.private,
                auditTrailEventType: auditTrailEventType.edit,
                auditSubject: 'Invited by Jon Snow',
                before: 'jon.snow@abccorporation.com',
                after: 'jon.snow@abccorporation.com',
                updatedDate: '10/06/2022 14:20:32',
                updatedBy: 'craig@resdiary.com',
            },
            {
                event: 'Update basic information',
                appType: appType.private,
                auditTrailEventType: auditTrailEventType.update,
                auditSubject: 'email address',
                before: 'jon.snow@abccorporation.co.uk',
                after: 'jon.snow123@abccorporation.co.uk',
                updatedDate: '10/06/2022 14:20:32',
                updatedBy: 'lisa@resdiary.com',
            },
            {
                event: 'Delete basic information',
                appType: appType.private,
                auditTrailEventType: auditTrailEventType.delete,
                auditSubject: 'email address',
                before: 'jon.snow@abccorporation.co.uk',
                after: '',
                updatedDate: '10/06/2022 14:20:32',
                updatedBy: 'claudia@resdiary.com',
            },
            {
                event: 'Add basic information',
                appType: appType.public,
                auditTrailEventType: auditTrailEventType.add,
                auditSubject: 'email address',
                before: '',
                after: 'jon.snow@abccorporation.com',
                updatedDate: '10/06/2022 14:20:32',
                updatedBy: 'craig@resdiary.com',
            },
            {
                event: 'Edit basic information',
                appType: appType.public,
                auditTrailEventType: auditTrailEventType.edit,
                auditSubject: 'Invited by Jon Snow',
                before: 'jon.snow@abccorporation.com',
                after: 'jon.snow@abccorporation.com',
                updatedDate: '10/06/2022 14:20:32',
                updatedBy: 'craig@resdiary.com',
            },
            {
                event: 'Edit basic information',
                appType: appType.private,
                auditTrailEventType: auditTrailEventType.edit,
                auditSubject: 'Invited by Jon Snow',
                before: 'jon.snow@abccorporation.com',
                after: 'jon.snow@abccorporation.com',
                updatedDate: '10/06/2022 14:20:32',
                updatedBy: 'craig@resdiary.com',
            },
        ];

        setPagedResult({
            data: testData,
            totalPages: 5,
            pageNumber: 1,
            nextPageUrl: '',
        });
        setShowPaginator(true);
        setIsLoading(false);
    }, [isInitialLoad, intl]);

    const onButtonGroupChange = (e) => {
        setSelectedFilterButton(e);
        let copyButtonOptions = buttonOptions;
        copyButtonOptions.forEach((btn) => {
            btn.isActive = btn.value === e;
        });
        setButtonOptions(copyButtonOptions);
    };

    const handlepageSizeChange = (e) => {};

    const renderPaginator = () => {
        if (showPaginator) {
            return (
                <Paginator
                    pages={pagedResult?.totalPages || 0}
                    handleNextButtonClick={(i) => {}}
                    handlePrevButtonClick={(i) => {}}
                    handlePaginate={(i) => {}}
                />
            );
        }
    };

    const renderEmptyResult = () => {
        return (
            <div className="col col-12 align-items-center text-center">
                <div className="test-diary-icon text-center">
                    <AuditTrailIcon />
                    <p className="mt-3">
                        <b>
                            <FormattedMessage id="Admin.AuditTrailPage.NoAuditTrail" />
                        </b>
                    </p>
                    <p className="mt-2">
                        <FormattedMessage id="Admin.AuditTrailPage.CheckLater" />
                    </p>
                </div>
            </div>
        );
    };

    const filterByEmail = (e) => {
        //TODO:
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row flex-xl-nowrap">
                    <main className="col col-12 content pb-5">
                        <div className="col-12">
                            <h2 className="mb-4">
                                <FormattedMessage id="Admin.AuditTrailPage.PageHeader" />
                            </h2>
                            <p>
                                <FormattedMessage id="Admin.AuditTrailPage.PageDescription" />
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <SearchInput
                                        placeholder={intl.formatMessage({
                                            id: 'Admin.AuditTrailPage.SearchPlaceholder',
                                        })}
                                        onChange={(e) => filterByEmail(e)}
                                    />
                                </div>
                                <div className="col-6 d-flex">
                                    <div className="col-8 d-flex justify-content-end">
                                        <ButtonBar items={buttonOptions} setValue={onButtonGroupChange} />
                                    </div>
                                    <div className="col-4 d-flex justify-content-end mb-2">
                                        <SelectList
                                            label={intl.formatMessage({ id: 'Admin.AuditTrailPage.PageSize' })}
                                            options={getPageSizes()}
                                            onChange={handlepageSizeChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="pt-3 pb-5">
                                    <AuditTrailTable
                                        data={pagedResult?.data || []}
                                        isLoading={isLoading}
                                        renderEmptyResult={renderEmptyResult}
                                    />
                                </div>
                                {renderPaginator()}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </Fragment>
    );
};

export default AuditTrailPage;
