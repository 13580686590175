import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import ConfirmationModal from '../../../common/ConfirmationModal';
import { useNotification } from '../../../../contexts/NotificationProvider';

const ClientSecretModal = (props: ClientSecretModalProps) => {
    const intl = useIntl();
    const { displayNotification } = useNotification();
    const closeModal = () => {
        props.onClose();
    };

    const modalBody = () => {
        return (
            <div>
                <div className="mb-3">
                    <FormattedMessage id="AppManagement.AppDetails.SecretModalText" />
                </div>
                <div className="alert alert-rd-info ">{props.clientSecret}</div>
            </div>
        );
    };
    const copySecret = () => {
        navigator.clipboard.writeText(props.clientSecret);
        closeModal();
        displayNotification({
            message: intl.formatMessage({ id: 'AppManagement.AppDetails.SecretCopied' }),
        });
    };

    return (
        <ConfirmationModal
            isOpen={props.isOpen}
            children={modalBody()}
            isHideCancel={true}
            hasHeaderBorder={true}
            isLoading={props.isLoading}
            header={
                <div>
                    <h3 className="mb-0">
                        {' '}
                        <FormattedMessage id="AppManagement.AppDetails.NewSecretGenerated" />
                    </h3>
                </div>
            }
            firstButtonText={intl.formatMessage({ id: 'AppManagement.AppDetails.CopyKey' })}
            onAccept={copySecret}
            onClose={closeModal}
        />
    );
};

export interface ClientSecretModalProps {
    isOpen: boolean;
    clientSecret: string;
    onClose(): void;
    isLoading: boolean;
}

export default ClientSecretModal;
