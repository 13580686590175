import * as React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import SidebarMenuItem from '../../../model/layout/SidebarMenuItem';
import SidebarItem from './SidebarItem';

const SidebarTitle = (props: SidebarTitleProps) => {
    const location = useLocation();
    const checkIsSubItemActive = (item: SidebarMenuItem) => {
        if (location.pathname === item.path) {
            return true;
        } else {
            for (let i = 0; i < (item.subItems || []).length; i++) {
                if (checkIsSubItemActive(item.subItems![i])) {
                    return true;
                }
            }
            return false;
        }
    };

    const [menuActive, setMenuActive] = useState(
        location.pathname === props.item.path || checkIsSubItemActive(props.item)
    );

    const showMenuItems = () => {
        props.handleToggle(!menuActive, props.sideBarTitleKey);
        setMenuActive(!menuActive);
    };

    const isActive = (isOpen: boolean) => {
        return isOpen ? 'active' : '';
    };

    const formatMenu = () => {
        if (props.item.id) {
            return (
                <React.Fragment>
                    <FormattedMessage id={`Layout.Sidebar.${props.item.id}`} />
                </React.Fragment>
            );
        } else {
            return <React.Fragment>`Layout.Sidebar.${props.item.title}`;</React.Fragment>;
        }
    };

    return (
        <div className="dp-sidebar__base" key={props.sideBarTitleKey}>
            <div className={`dp-sidebar__header-name ${isActive(props.isOpen)}`} onClick={showMenuItems}>
                {props.item.subItems && (
                    <img
                        src="/images/Chevron.svg"
                        className={menuActive && props.isOpen ? 'rotate-180deg' : 'rotate-90deg'}
                        alt=""
                    />
                )}
                <Link to={props.item.path} onClick={showMenuItems}>
                    {formatMenu()}
                </Link>
            </div>
            {menuActive &&
                props.isOpen &&
                props.item.subItems?.map((item, index) => {
                    return <SidebarItem item={item} sideBarMenuItemkey={index} key={index} />;
                })}
        </div>
    );
};

export interface SidebarTitleProps {
    sideBarTitleKey: number;
    item: SidebarMenuItem;
    isOpen: boolean;
    handleToggle: (isOpen: boolean, key: number) => void;
}

export default SidebarTitle;
