import React, { useState, useEffect } from 'react';
import Table from '../common/DataTable';
import PropTypes from 'prop-types';
import Toggle from '../common/Toggle';
import { useIntl } from 'react-intl';
function AssignPermissions({ data, updatePermissions }) {
    const [isRendering, setIsRendering] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        const dataCopy = [...data];
        setPermissions(dataCopy);
        setIsRendering(false);
    }, [data]);

    const getPermissionsTables = () => {
        let items = [];
        permissions.forEach((tm) => {
            const item = [
                {
                    name: tm.tableName,
                    selector: (x) => x.name,
                    displayFormat: (x) => {
                        // Todo: Translatable permissions
                        return <div className="mt-2">{x.description}</div>;
                    },
                },
                {
                    name: '',
                    displayFormat: (x) => (
                        <div className="float-right mr-5">
                            <Toggle
                                label=""
                                checked={x.isPermissionSelected}
                                handleChange={(e) => updatePermission(e, x)}
                                checkedLabel={intl.formatMessage({ id: 'Common.Yes' })}
                                uncheckedLabel={intl.formatMessage({ id: 'Common.No' })}
                            />
                        </div>
                    ),
                },
            ];
            items.push(item);
        });

        const tables = permissions.map((permission, i) => (
            <Table
                key={i}
                tableName={permission.tableName}
                columns={items[i]}
                data={permission}
                isLoading={isRendering}
            />
        ));

        return tables;
    };

    const updatePermission = (enabled, permission) => {
        setIsRendering(true);
        let copy = [];
        permissions.forEach((pg) => {
            const newPermissions = pg.map((p) =>
                p.name === permission.name ? { ...p, isPermissionSelected: enabled } : p
            );
            copy.push(newPermissions);
        });
        setPermissions(copy);
        updatePermissions(copy);
        setIsRendering(false);
    };

    return <div>{getPermissionsTables()}</div>;
}

AssignPermissions.propTypes = {
    data: PropTypes.arrayOf(PropTypes.array),
    updatePermissions: PropTypes.func,
};
export default AssignPermissions;
