import { api, adminApi, apiBase } from './helpers/ApiHelper';
import config from '../src/config/app';
import { useAuthentication } from './contexts/AuthenticationContext';

const useInterceptors = () => {
    let { clearAuthInfo } = useAuthentication();

    const handleUnAuthorizedRequests = (error: any) => {
        if (
            error.response.status === 401 &&
            ![config.authenticationPath, config.authenticationPath].includes(error.config.url)
        ) {
            clearAuthInfo();
            return;
        }

        throw error;
    };

    const handleResponse = (response: any) => {
        return response;
    };

    const handleErrors = (error: any) => {
        handleUnAuthorizedRequests(error);
    };

    api.axiosInstance.interceptors.response.use(handleResponse, handleErrors);
    adminApi.axiosInstance.interceptors.response.use(handleResponse, handleErrors);
    apiBase.axiosInstance.interceptors.response.use(handleResponse, handleErrors);
};

export { useInterceptors };
