import $ from 'jquery';

/**
 * Add language to code blocks and do syntax cleanup (e.g. format JSON content, fix syntax errors in shell script)
 */
export default function addLanguageToCodeBlocks() {
    $('code').each(function () {
        const className = $(this).attr('class') || ''; // e.g. `language-json`
        const parts = className.trim().split('-');
        if (parts.length === 2 && parts[0] === 'language') {
            const languageElement = $(this).parent().parent().find('.code__toolbox__language');
            languageElement.text(parts[1]);
            languageElement.removeClass('d-none');
        }
        // format JSON content
        const language = parts[1];
        if (language === 'json') {
            jsonCleanup($(this));
        } else if (language === 'shell') {
            shellCleanup($(this));
        }
    });
}

const jsonCleanup = (element) => {
    const content = element.text();
    try {
        const json = JSON.parse(content);
        if (json) {
            element.text(JSON.stringify(json, null, 4));
        }
    } catch (e) {}
};

const shellCleanup = (element) => {
    let content = element
        .text()
        // replace all `^` at the end of lines by `\`
        // e.g. `-H "Authorization: Bearer YOUR_TOKEN" ^` -> `-H "Authorization: Bearer YOUR_TOKEN" \`
        .replace(/\^\s*\n/g, '\\\n')
        // put double-quotes around fields with `[...]` and remove space around `=`
        // e.g. `-d Customer[Surname]  =  Smith` -> `-d "Customer[Surname]"=Smith`
        .split('\n')
        .map((line) => {
            // eslint-disable-next-line
            const isDataLine = /^\s+\-d\s+.+\=.+$/.test(line);
            if (!isDataLine) {
                return line;
            }
            const parts = line.split('-d');
            const firstEqIdx = parts[1].indexOf('=');
            const fieldName = parts[1].substring(0, firstEqIdx).trim();
            const value = parts[1].substring(firstEqIdx + 1).trim();
            if (fieldName.indexOf('[') < 0 || fieldName.indexOf(']') < 0) {
                return line;
            }
            return `${parts[0]}-d "${fieldName}"=${value}`;
        })
        .join('\n');
    element.text(content);
};
