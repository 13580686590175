/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Fragment, useState, useEffect, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Label } from 'reactstrap';
import TextWithIcon from '../common/TextWithIcon';
import AppImageContainer from './AppImageContainer';
import CopyToClipboard from '../common/CopyToClipboard';
import App from '../../model/appManagement/App';
import AppService from '../../services/AppService';
import ErrorMessage from '../../model/services/ErrorMessage';
import { useNavigate, useParams, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import { ReactComponent as BackIcon } from '../../content/icons/Icon-Arrow-Left.svg';
import OAuthCredentialsPage from './appDetails/credentials/OAuthCredentialsPage';
import OAuthSettingsPage from './appDetails/settings/OAuthSettingsPage';
import TestDiariesPage from '../testDiaries/TestDiariesPage';
import { HubAlertProps } from '../common/hubAlert/HubAlert';
import HubAlertManager from '../common/hubAlert/HubAlertManager';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import AppWebhooksLogs from './AppWebhooksLogs';
import AppApiLogs from './AppApiLogs';
import AppWebhooksConfiguration from './AppWebhooksConfiguration';
import AppSubmissionStatusTag from './AppSubmissionStatusTag';
import AppApiTypeTag from './AppApiTypeTag';
import Toggle from '../common/Toggle';
import PublishModal from '../common/ConfirmationModal';
import AppSubmissionForm from './appDetails/submit/AppSubmissionForm';
import SideBarContentPage, { SideBarItem } from '../common/SideBarContentPage';
import AppAuthenticationSettings from '../../model/appManagement/AppAuthenticationSettings';

const AppDetailsPage = () => {
    const navigate = useNavigate();
    const { appId } = useParams();
    const { state, pathname } = useLocation();
    const { authInfo } = useAuthentication();
    const intl = useIntl();
    const [alerts, setAlerts] = useState<HubAlertProps[]>([]);
    const [application, setApplication] = useState<App>();
    const [isApplicationLoading, setIsApplicationLoading] = useState(true);
    const [isShowPublishModal, setIsShowPublishModal] = useState<boolean>(false);
    const [isAppLive, setIsAppLive] = useState<boolean>(false);

    // Get application
    useEffect(() => {
        if (state?.application) {
            setApplication(state.application as App);
        } else {
            AppService.getApplicationById(appId!)
                .then((response) => {
                    setApplication(response);
                    setIsAppLive(response.isAppLive);
                })
                .catch((error: ErrorMessage) => {
                    setAlerts((i) => [...i, { type: 'error', message: error.errorMessage }]);
                })
                .finally(() => {
                    setIsApplicationLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appId, state]);

    const isMenuItemSelected = (path: string) =>
        pathname.toLowerCase().includes(`/Apps/${appId}/${path}`.toLowerCase());

    const setToPublish = () => {
        setIsShowPublishModal(true);
    };
    const publishApp = () => {
        AppService.setAppStoreListingIsLive(application!.id, !isAppLive)
            .then((response) => {
                setIsAppLive(!isAppLive);
                setIsShowPublishModal(false);
            })
            .catch((error: ErrorMessage) => {
                setAlerts((i) => [...i, { type: 'error', message: error.errorMessage }]);
            });
    };

    const getMenuItems = (_application: App) => {
        let menuItems: SideBarItem[] = [
            {
                component: intl.formatMessage({ id: 'AppManagement.AppDetails.TestMenuLabel' }),
                subItems: [
                    {
                        component: intl.formatMessage({ id: 'AppManagement.AppDetails.AppSettingsMenuLabel' }),
                        subItems: [
                            {
                                path: `Test/${appDetailsRoutes.Credentials}`,
                                isSelected: isMenuItemSelected(`Test/${appDetailsRoutes.Credentials}`),
                                component: intl.formatMessage({
                                    id: 'AppManagement.AppDetails.CredentialsMenuLabel',
                                }),
                            },
                            {
                                path: `Test/${appDetailsRoutes.Settings}`,
                                isSelected: isMenuItemSelected(`Test/${appDetailsRoutes.Settings}`),
                                component: (
                                    <span>
                                        {intl.formatMessage({
                                            id: 'AppManagement.AppDetails.SettingsMenuLabel',
                                        })}
                                        {!_application.appAuthenticationSettings.find((x) => x.environment === 'Test')
                                            ?.redirectUrl && (
                                            <Tooltip
                                                title={intl.formatMessage({
                                                    id: 'AppManagement.AppDetails.SettingsNotConfiguredMessage',
                                                })}
                                                arrow
                                                placement="top"
                                            >
                                                <WarningIcon className="float-right" color="warning"></WarningIcon>
                                            </Tooltip>
                                        )}
                                    </span>
                                ),
                            },
                        ],
                    },
                    {
                        component: intl.formatMessage({ id: 'AppManagement.AppDetails.WebhooksMenuLabel' }),
                        subItems: [
                            {
                                path: `Test/${appDetailsRoutes.WebhookConfiguration}`,
                                isSelected: isMenuItemSelected(`Test/${appDetailsRoutes.WebhookConfiguration}`),
                                component: intl.formatMessage({
                                    id: 'AppManagement.AppDetails.WebhooksConfigurationMenuLabel',
                                }),
                            },
                            {
                                path: `Test/${appDetailsRoutes.WebhooksLogs}`,
                                isSelected: isMenuItemSelected(`Test/${appDetailsRoutes.WebhooksLogs}`),
                                component: intl.formatMessage({ id: 'AppManagement.AppDetails.WebhooksLogsMenuLabel' }),
                            },
                        ],
                    },
                    {
                        path: `Test/${appDetailsRoutes.ApiLogs}`,
                        isSelected: isMenuItemSelected(`Test/${appDetailsRoutes.ApiLogs}`),
                        component: intl.formatMessage({ id: 'AppManagement.AppDetails.ApiLogsMenuLabel' }),
                    },
                    {
                        path: `Test/${appDetailsRoutes.TestDiaries}`,
                        isSelected: isMenuItemSelected(`Test/${appDetailsRoutes.TestDiaries}`),
                        component: intl.formatMessage({ id: 'AppManagement.AppDetails.TestDiariesMenuLabel' }),
                    },
                ],
            },
        ];

        if (_application && _application.submissionStatus === 'Approved') {
            menuItems.push({
                component: intl.formatMessage({ id: 'AppManagement.AppDetails.LiveMenuLabel' }),
                subItems: [
                    {
                        component: intl.formatMessage({ id: 'AppManagement.AppDetails.AppSettingsMenuLabel' }),
                        subItems: [
                            {
                                path: appDetailsRoutes.Credentials,
                                isSelected: isMenuItemSelected(appDetailsRoutes.Credentials),
                                component: (
                                    <span>
                                        {intl.formatMessage({
                                            id: 'AppManagement.AppDetails.CredentialsMenuLabel',
                                        })}
                                        {!_application.hasLiveSecret && (
                                            <Tooltip
                                                title={intl.formatMessage({
                                                    id: 'AppManagement.AppDetails.SettingsNotConfiguredMessage',
                                                })}
                                                arrow
                                                placement="top"
                                            >
                                                <WarningIcon className="float-right" color="warning"></WarningIcon>
                                            </Tooltip>
                                        )}
                                    </span>
                                ),
                            },
                            {
                                path: appDetailsRoutes.Settings,
                                isSelected: isMenuItemSelected(appDetailsRoutes.Settings),
                                component: (
                                    <span>
                                        {intl.formatMessage({
                                            id: 'AppManagement.AppDetails.SettingsMenuLabel',
                                        })}
                                        {!_application.appAuthenticationSettings.find(
                                            (x) => x.environment === 'Production'
                                        )?.redirectUrl && (
                                            <Tooltip
                                                title={intl.formatMessage({
                                                    id: 'AppManagement.AppDetails.SettingsNotConfiguredMessage',
                                                })}
                                                arrow
                                                placement="top"
                                            >
                                                <WarningIcon className="float-right" color="warning"></WarningIcon>
                                            </Tooltip>
                                        )}
                                    </span>
                                ),
                            },
                        ],
                    },
                    {
                        component: intl.formatMessage({ id: 'AppManagement.AppDetails.WebhooksMenuLabel' }),
                        subItems: [
                            {
                                path: appDetailsRoutes.WebhookConfiguration,
                                isSelected: isMenuItemSelected(appDetailsRoutes.WebhookConfiguration),
                                component: intl.formatMessage({
                                    id: 'AppManagement.AppDetails.WebhooksConfigurationMenuLabel',
                                }),
                            },
                            {
                                path: appDetailsRoutes.WebhooksLogs,
                                isSelected: isMenuItemSelected(appDetailsRoutes.WebhooksLogs),
                                component: intl.formatMessage({ id: 'AppManagement.AppDetails.WebhooksLogsMenuLabel' }),
                            },
                        ],
                    },
                    {
                        path: appDetailsRoutes.ApiLogs,
                        isSelected: isMenuItemSelected(appDetailsRoutes.ApiLogs),
                        component: intl.formatMessage({ id: 'AppManagement.AppDetails.ApiLogsMenuLabel' }),
                    },
                ],
            });
        }

        if (!authInfo.isAdmin && _application?.submissionStatus === 'InDevelopment') {
            menuItems.push({
                path: appDetailsRoutes.Submit,
                isSelected: isMenuItemSelected(appDetailsRoutes.Submit),
                component: intl.formatMessage({ id: 'AppManagement.AppDetails.SubmitForApprovalLabel' }),
            });
        }

        return menuItems;
    };

    let menuItems = useMemo(() => {
        if (isApplicationLoading || !application) {
            return [];
        }
        return getMenuItems(application);
    }, [application, isApplicationLoading]);

    const showPublishModal = () => {
        return (
            <div>
                <PublishModal
                    isOpen={isShowPublishModal}
                    isHideCancel={false}
                    hasHeaderBorder={true}
                    onClose={() => setIsShowPublishModal(false)}
                    onAccept={publishApp}
                    header={
                        <div>
                            <h3 className="mb-0">{'Publishing app: ' + application?.title}</h3>
                        </div>
                    }
                    children={publishModalContent()}
                />
            </div>
        );
    };

    const publishModalContent = () => {
        const publishModalMessageId = !isAppLive
            ? 'AppManagement.AppDetails.PublishAppModalMessage'
            : 'AppManagement.AppDetails.UnpublishAppModalMessage';
        return (
            <div className="m-2">
                <FormattedMessage id={publishModalMessageId} />
            </div>
        );
    };

    const showSettingsNotConfiguredWarningInHeader = () => {
        if (
            application?.appAuthenticationSettings.some((x) => !x.redirectUrl) ||
            (application!.submissionStatus === 'Approved' && !application!.hasLiveSecret)
        ) {
            return (
                <Grid item>
                    <Tooltip
                        title={intl.formatMessage({
                            id: 'AppManagement.AppDetails.SettingsNotConfiguredMessage',
                        })}
                        arrow
                        placement="top"
                    >
                        <span>
                            <WarningIcon color="warning"></WarningIcon>
                        </span>
                    </Tooltip>
                </Grid>
            );
        }
    };

    const handleAuthSettingsUpdate = (updatedSetting: AppAuthenticationSettings) => {
        const updatedAuthenticationSettings =
            application?.appAuthenticationSettings.map((setting) => {
                if (setting.environment === updatedSetting.environment) return updatedSetting;
                return setting;
            }) || [];

        if (application && updatedAuthenticationSettings.length > 0)
            setApplication({
                ...application,
                appAuthenticationSettings: updatedAuthenticationSettings,
            });
    };

    const getAppSettingsRoute = (_isTestEnvironment: boolean = false) => {
        return (
            <>
                <Route
                    path={appDetailsRoutes.Credentials}
                    element={<OAuthCredentialsPage app={application} isTestEnvironment={_isTestEnvironment} />}
                />
                <Route
                    path={appDetailsRoutes.Settings}
                    element={
                        <OAuthSettingsPage
                            app={application}
                            isTestEnvironment={_isTestEnvironment}
                            onSettingsUpdated={handleAuthSettingsUpdate}
                        />
                    }
                />
                <Route
                    path={appDetailsRoutes.ApiLogs}
                    element={<AppApiLogs application={application} isTestEnvironment={_isTestEnvironment} />}
                />
                <Route
                    path={appDetailsRoutes.WebhookConfiguration}
                    element={
                        <AppWebhooksConfiguration application={application} isTestEnvironment={_isTestEnvironment} />
                    }
                />
                <Route
                    path={appDetailsRoutes.WebhooksLogs}
                    element={<AppWebhooksLogs app={application} isTestEnvironment={_isTestEnvironment} />}
                />
            </>
        );
    };

    return (
        <Fragment>
            <div className="app-navigation-page">
                <HubAlertManager alerts={alerts} />
                {application && (
                    <div className="app-navigation-header">
                        <div
                            className="back-icon"
                            onClick={() => navigate(!authInfo.isAdmin ? `/Apps` : '/Admin/Apps')}
                        >
                            <TextWithIcon
                                icon={<BackIcon />}
                                className="text-uppercase"
                                text={intl.formatMessage({ id: 'AppManagement.AppDetails.BackToApps' })}
                                textClass="back-label"
                            />
                        </div>
                        <div className="app-info d-flex mt-2">
                            <div className="app-icon-container">
                                <AppImageContainer appId={appId!} imageUpdated={false} />;
                            </div>
                            <Grid container justifyContent="space-between" item xs={9}>
                                <Grid item>
                                    <h1 data-testid="app-title">{application!.title}</h1>
                                </Grid>
                                <Grid spacing={1} xs={12} item container>
                                    <Grid item>
                                        <AppApiTypeTag appApiType={application!.apiType} />
                                    </Grid>
                                    <Grid item>
                                        <AppSubmissionStatusTag submissionStatus={application!.submissionStatus} />
                                    </Grid>
                                    {showSettingsNotConfiguredWarningInHeader()}
                                </Grid>
                                <Grid item>
                                    <div className="app-id-container">
                                        <Label>
                                            <FormattedMessage id="AppManagement.AppNavigationBasePage.AppID" />
                                            {` ${application!.id}`}
                                        </Label>
                                        <CopyToClipboard isWhite={true} id="appId" getValue={() => application!.id} />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} className="float-end mt-4">
                                <Toggle
                                    label="Publish App"
                                    disabled={
                                        application == null
                                            ? true
                                            : !application.hasLiveSecret || application.submissionStatus !== 'Approved'
                                    }
                                    className="ml-3 toggle-green"
                                    checked={isAppLive}
                                    handleChange={setToPublish}
                                    checkedLabel="Yes"
                                    uncheckedLabel="No"
                                    oncolor="#00a099"
                                />
                            </Grid>
                        </div>
                    </div>
                )}
                {application && (
                    <SideBarContentPage tabLabels={menuItems}>
                        <Routes>
                            {application.submissionStatus === 'Approved' && <Route>{getAppSettingsRoute()}</Route>}
                            <Route path="Test">
                                {getAppSettingsRoute(true)}
                                <Route
                                    path={appDetailsRoutes.TestDiaries}
                                    element={<TestDiariesPage app={application} />}
                                />
                            </Route>
                            {!authInfo.isAdmin && application?.submissionStatus === 'InDevelopment' && (
                                <Route
                                    path={appDetailsRoutes.Submit}
                                    element={application && <AppSubmissionForm app={application} />}
                                />
                            )}
                            <Route
                                path="*"
                                element={
                                    <Navigate
                                        to={
                                            application.submissionStatus === 'Approved'
                                                ? appDetailsRoutes.Credentials
                                                : `Test/${appDetailsRoutes.Credentials}`
                                        }
                                        state={state}
                                        replace={true}
                                    />
                                }
                            />
                        </Routes>
                    </SideBarContentPage>
                )}
            </div>
            {showPublishModal()}
        </Fragment>
    );
};

const appDetailsRoutes = {
    Credentials: 'Credentials',
    Settings: 'Settings',
    WebhookConfiguration: 'Webhooks/Configuration',
    WebhooksLogs: 'Webhooks/Logs',
    ApiLogs: 'Api/Logs',
    TestDiaries: 'Testing/TestDiaries',
    Submit: 'Submit',
};

export default AppDetailsPage;
