/* eslint-disable */
import { api } from '../helpers/ApiHelper';
import { systemRoleName } from '../helpers/RoleNameParserHelper';
import Permission from '../model/roles/Permission';
import ErrorMessage from '../model/services/ErrorMessage';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getPermissions = async (organisationId: string, roleName: string) => {
    const url =
        typeof roleName === 'undefined'
            ? `Permissions`
            : `Permissions/${encodeURIComponent(systemRoleName(organisationId, roleName))}/inRole`;
    return api.get<Permission, string>(url).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

export default {
    getPermissions,
};
