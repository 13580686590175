/* eslint-disable */
import { api } from '../helpers/ApiHelper';
import { displayRoleName, systemRoleName } from '../helpers/RoleNameParserHelper';
import RolesRequest from '../model/roles/RolesRequest';
import RolesResponse from '../model/roles/RolesResponse';
import Permission from '../model/roles/Permission';
import AssignRole from '../model/roles/AssignRole';
import ErrorMessage from '../model/services/ErrorMessage';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const parseRoleNames = (roleDtos: RolesResponse[]) => {
    roleDtos.forEach((item) => {
        item.roleName = displayRoleName(item.roleName);
    });
    return roleDtos;
};

const getRoles = async (organisationId: string) => {
    return await api.get<RolesResponse[], string>(`Roles/${organisationId}`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return parseRoleNames(response.data);
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const addRole = async (
    username: string,
    roleName: string,
    organisationId: string,
    roleDescription: string,
    assignedMembers: AssignRole[],
    permissions: Permission[]
) => {
    const systemRole = systemRoleName(organisationId, roleName);
    const rolesDto: RolesRequest = {
        username: username,
        roleName: systemRole,
        organisationId: organisationId,
        roleDescription: roleDescription,
        assignedMembers: assignedMembers,
        permissions: permissions,
    };
    return await api.post<RolesResponse, string>('Roles', JSON.stringify(rolesDto)).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const editRole = async (rolesDto: RolesRequest) => {
    rolesDto.roleName = systemRoleName(rolesDto.organisationId, rolesDto.roleName);
    return await api.put<RolesResponse, string>('Roles', JSON.stringify(rolesDto)).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return true;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const assignRoleToMembers = async (roleName: string, organisationId: string, teamMembers: string[]) => {
    const roleInternalName = systemRoleName(organisationId, roleName);
    return await api
        .post<undefined, string>(`roles/${roleInternalName}/TeamMembers`, JSON.stringify(teamMembers))
        .then((response) => {
            if (response.ok) {
                return true;
            } else {
                return false;
            }
        });
};

export default {
    getRoles,
    addRole,
    editRole,
    assignRoleToMembers,
};
