import React, { useState, useEffect, Fragment } from 'react';
import Sidebar from '../layout/Sidebar';
import Table from '../common/DataTable';
import RolesService from '../../services/RolesService';
import { useOrganisation } from '../../contexts/OrganisationContext';
import { ReactComponent as LeftArrow } from '../../content/icons/arrow-left.svg';
import { ReactComponent as EditIcon } from '../../content/icons/edit.svg';
import { ReactComponent as AssignIcon } from '../../content/icons/Assign.svg';
import infoBarType from '../../enums/infoBarType';
import InfoBarManager from '../common/InfoBarManager';
import ProfileMenu from './ProfileMenuData';
import { ReactComponent as RolesIcon } from '../../content/images/Roles.svg';
import AssignRolesModal from './AssignRolesModal';
import OrganisationService from '../../services/OrganisationService';
import CreateEditRole from './CreateEditRole';
import { displayRoleName } from '../../helpers/RoleNameParserHelper';
import { FormattedMessage, useIntl } from 'react-intl';

const RolesPage = () => {
    const profilesMenu = ProfileMenu();
    const [isLoading, setIsLoading] = useState(true);
    const [rolesData, setRolesData] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [showAssignRolesModal, setShowAssignRolesModal] = useState(false);
    const { defaultOrganisation } = useOrganisation();
    const [infoBars, setInfoBars] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRoleData, setSelectedRoleData] = useState({});
    const [showCreateRole, setShowCreateRole] = useState(false);
    const [showNoRoles, setShowNoRoles] = useState(false);
    const [showRolesTable, setShowRolesTable] = useState(true);
    const [teamMembers, setTeamMembers] = useState([]);
    const intl = useIntl();

    function addSuccessBar(message) {
        setInfoBars((i) => [...i, { type: infoBarType.success, message: message }]);
    }

    function addErrorBar(message) {
        setInfoBars((i) => [...i, { type: infoBarType.error, message: message }]);
    }

    const showEditCreateRoleSection = () => {
        setShowNoRoles(false);
        setShowRolesTable(false);
        setShowCreateRole(true);
    };

    useEffect(() => {
        RolesService.getRoles(defaultOrganisation.id)
            .then((response) => {
                setRolesData(response);
                if (rolesData.length === 0) {
                    setShowNoRoles(true);
                    setShowCreateRole(false);
                } else {
                    setShowNoRoles(false);
                }
            })
            .catch((error) => {})
            .finally(() => {
                setIsLoading(false);
            });
    }, [setIsLoading, setShowNoRoles, setShowCreateRole, setRolesData, defaultOrganisation, rolesData.length]);

    const goBackToRolesTable = () => {
        setShowNoRoles(false);
        setShowRolesTable(true);
        setShowCreateRole(false);
    };

    const getTeamMembers = (e) => {
        setIsLoading(true);
        setTeamMembers([]);
        OrganisationService.getTeamMembers(defaultOrganisation.id).then((teamMember) => {
            const assignAll = { userId: 'ALL', isInRole: false, firstName: 'Assign to all team members(s)' };
            setTeamMembers((teamMembers) => [...teamMembers, assignAll]);
            if (typeof teamMember !== 'undefined' && teamMember !== null) {
                teamMember.forEach((tm) => {
                    tm.roleNames = tm.roleNames.map((roleName) => {
                        return displayRoleName(roleName);
                    });
                    tm.isInRole = typeof e !== 'undefined' ? tm.roleNames.includes(e.roleName) : false;
                    setTeamMembers((teamMembers) => [...teamMembers, tm]);
                });
            }
            setIsLoading(false);
        });
    };

    const assignRole = (e) => {
        setInfoBars([]);
        setIsLoading(true);
        setSelectedRole(e.roleName);
        getTeamMembers(e);
        setShowAssignRolesModal(true);
    };

    const createRole = () => {
        setInfoBars([]);
        setIsLoading(true);
        setIsEdit(false);
        getTeamMembers();
        setSelectedRoleData({});
        showEditCreateRoleSection();
        setIsLoading(false);
    };

    const editRole = (e) => {
        setInfoBars([]);
        setIsLoading(true);
        setSelectedRoleData(e);
        setIsEdit(true);
        getTeamMembers(e);
        showEditCreateRoleSection();
        setIsLoading(false);
    };

    const assignRolesToMembers = () => {
        RolesService.assignRoleToMembers(selectedRole, defaultOrganisation.id, teamMembers).then((response) => {
            if (response === true) {
                const assigned = teamMembers.filter((m) => m.isInRole === true).map((m) => m.email);
                setRolesData(
                    rolesData.map((item) =>
                        item.roleName === selectedRole ? { ...item, assignedMembers: assigned } : item
                    )
                );
                setShowAssignRolesModal(false);
                addSuccessBar(intl.formatMessage({ id: 'Profile.RolesPage.AssignedMembersUpdatedSuccess' }));
            }
        });
    };

    const submitRole = (e, isSuccess) => {
        setIsLoading(true);
        if (!isSuccess) {
            const errMsg = e.errorMessage !== typeof 'undefined' ? e.errorMessage.message : '';

            addErrorBar(
                `${
                    errMsg === ''
                        ? isEdit
                            ? intl.formatMessage({ id: 'Profile.RolesPage.ErrorMessageEditing' })
                            : intl.formatMessage({ id: 'Profile.RolesPage.ErrorMessageCreating' })
                        : errMsg
                }`
            );
        } else {
            e.roleName = displayRoleName(e.roleName);
            if (e.length !== typeof 'undefined') {
                setRolesData(e);
            } else {
                setRolesData((rolesData) => [...rolesData, e]);
            }
            addSuccessBar(
                isEdit
                    ? intl.formatMessage({ id: 'Profile.RolesPage.UpdateSuccess' }, { roleName: e.roleName })
                    : intl.formatMessage({ id: 'Profile.RolesPage.CreateSuccess' }, { roleName: e.roleName })
            );
        }
        setShowCreateRole(false);
        setShowRolesTable(true);
        setIsLoading(false);
    };

    const columns = [
        {
            name: intl.formatMessage({ id: 'Profile.RolesPage.NameColumn' }),
            selector: (x) => x.name,
            displayFormat: (x) => {
                return <b>{x.roleName}</b>;
            },
        },
        {
            name: intl.formatMessage({ id: 'Profile.RolesPage.DescriptionColumn' }),
            selector: (x) => x.roleDescription,
        },
        {
            name: intl.formatMessage({ id: 'Profile.RolesPage.CreatedByColumn' }),
            selector: (x) => x.createdBy,
        },
        {
            name: intl.formatMessage({ id: 'Profile.RolesPage.CreatedOnColumn' }),
            selector: (x) => x.createdOn.replace('T', ' ').replace(/\.\d+/, ''),
        },
        {
            name: intl.formatMessage({ id: 'Profile.RolesPage.AssignedMembersColumn' }),
            displayFormat: (x) =>
                x.assignedMembers.map((member, i) => (
                    <div className="col" key={member}>
                        {member}
                    </div>
                )),
        },
        {
            name: '',
            displayFormat: (x) => {
                const isDisabled = x.roleName === 'Owner' || x.roleName === 'GlobalAdmin' ? true : false;
                return (
                    <div>
                        <button className="btn btn-link w-25 p-0" disabled={isDisabled}>
                            <EditIcon
                                className=""
                                onClick={() => {
                                    editRole(x);
                                }}
                            />
                        </button>
                        <button className="btn btn-link w-25 pl-2">
                            <AssignIcon
                                className=""
                                onClick={() => {
                                    assignRole(x);
                                }}
                            />
                        </button>
                    </div>
                );
            },
        },
    ];

    const createRoleButton = () => {
        return (
            <button className="btn btn-primary float-right" onClick={createRole}>
                &#43; &nbsp;
                <FormattedMessage id="Profile.RolesPage.CreateNewRoleButton" />
            </button>
        );
    };

    const editCreateRole = () => {
        if (showCreateRole) {
            return (
                <div className="col-12">
                    <button className="mt-4 pl-0 btn-link btn text-dark" onClick={() => goBackToRolesTable()}>
                        <b className="float-left">
                            <LeftArrow className="mb-1" />
                            &nbsp;
                            <FormattedMessage id="Profile.RolesPage.BacktoRoles" />
                        </b>
                    </button>
                    <CreateEditRole
                        isEdit={isEdit}
                        selectedRoleData={selectedRoleData}
                        submit={submitRole}
                        goBackToRolesTable={goBackToRolesTable}
                        teamsData={teamMembers}
                    />
                </div>
            );
        }
        return '';
    };

    const noRoles = () => {
        if (showNoRoles) {
            return (
                <div className="row">
                    <div className="col-md-6 offset-md-3 pt-2 align-items-center text-center">
                        <div className="test-diary-icon text-center">
                            <RolesIcon className="" />
                            <FormattedMessage id="Profile.RolesPage.AssignNamedRoleToMembers" />
                        </div>
                        <div className="row  m-3 p-2">
                            <p className="col-12">
                                <b>
                                    &nbsp;
                                    <FormattedMessage id="Profile.RolesPage.NoRolesCreated" />
                                </b>
                            </p>
                            <div className="col-12 p-2">{createRoleButton()}</div>
                        </div>
                    </div>
                </div>
            );
        }
        return '';
    };

    const rolesTable = () => {
        if (showRolesTable) {
            return (
                <div>
                    <h1 className="my-4 col-12">
                        <FormattedMessage id="Profile.RolesPage.Title" />
                    </h1>
                    <div className="row">
                        <div className="col-6 ">
                            <p className="col td-page__description">
                                <FormattedMessage id="Profile.RolesPage.Description" />
                            </p>
                        </div>
                        <div className="col-md-4">{createRoleButton()}</div>
                    </div>
                    <div className="col-12">
                        <Table tableName={'Roles Table'} columns={columns} data={rolesData} isLoading={isLoading} />
                    </div>
                    {noRoles()}
                </div>
            );
        }
        return '';
    };

    return (
        <Fragment>
            <AssignRolesModal
                header={intl.formatMessage(
                    { id: 'Profile.RolesPage.AssignNamedRoleToMembers' },
                    { roleName: selectedRole }
                )}
                onClose={() => setShowAssignRolesModal(false)}
                isOpen={showAssignRolesModal}
                onAccept={assignRolesToMembers}
                teamsData={teamMembers}
                roleName={selectedRole}
                assignRoles={(e) => setTeamMembers(e)}
            />
            <div className="container-fluid">
                <div className="row flex-xl-nowrap">
                    <div className="sidebar-column col-md-3 col-xl-2 p-0 d-none d-md-block">
                        <Sidebar menuItems={profilesMenu} />
                    </div>
                    <main className="logs-page offset-md-3 col-md-9 offset-xl-2 col-xl-10">
                        <InfoBarManager infoBarArray={infoBars} />
                        {rolesTable()}
                        {editCreateRole()}
                    </main>
                </div>
            </div>
        </Fragment>
    );
};

export default RolesPage;
