import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../helpers/ApiHelper';
import App from '../../../../model/appManagement/App';
import AppService from '../../../../services/AppService';
import LoadingSpinner from '../../../common/LoadingSpinner';

interface AppSubmissionFormProps {
    app: App;
}

const AppSubmissionForm = ({ app }: AppSubmissionFormProps) => {
    const [formUrl, setFormUrl] = useState<string | null>(null);
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [iframeHeight, setIframeHeight] = useState(200);
    const navigate = useNavigate();

    useEffect(() => {
        api.get<any>(`Apps/${app.id}/wufoo/integrationcertification`).then((response) => {
            if (response.ok) {
                setFormUrl(response.data.formUrl);
            }
        });
    }, [app.id]);

    useEffect(() => {
        // polling the server to check if the app's status has changed since we can't get any callback from the wufoo iframe form
        if (isFormLoaded) {
            let timeout = setInterval(() => {
                AppService.getApplicationById(app.id).then((response) => {
                    if (response.submissionStatus === 'Submitted') {
                        setIsFormSubmitted(true);
                        setIframeHeight(200);
                        setTimeout(() => {
                            navigate(`/Apps/${app.id}`, { state: { application: app }, replace: true });
                        }, 2000);
                    }
                });
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [app, isFormLoaded, navigate]);

    const onFormLoad = () => {
        setIframeHeight(2000);
        setIsFormLoaded(true);
    };

    return (
        <div className="row">
            <div className="mx-auto col-md-8 m-3">
                {(!isFormLoaded || isFormSubmitted) && <LoadingSpinner />}
                {formUrl && (
                    <iframe
                        id="IntegrationCertificationForm"
                        onLoad={onFormLoad}
                        height={iframeHeight}
                        title="Embedded Wufoo Form"
                        style={{
                            width: '80%',
                            border: 'none',
                            visibility: isFormLoaded ? 'visible' : 'hidden',
                        }}
                        sandbox="allow-popups-to-escape-sandbox allow-top-navigation allow-scripts allow-popups allow-forms allow-same-origin"
                        src={formUrl}
                    >
                        <a href={formUrl}>Fill out my Wufoo form!</a>
                    </iframe>
                )}
            </div>
        </div>
    );
};

export default AppSubmissionForm;
