import * as React from 'react';
import signInStatus from '../enums/signInStatus';
import ConditionalRoute from './ConditionalRoute';
import { useAuthentication } from '../contexts/AuthenticationContext';

interface IPublicRouteProps {
    isRestricted?: boolean;
}

const PublicRoute = ({ isRestricted = false }: IPublicRouteProps) => {
    const { authInfo } = useAuthentication();
    // If isRestricted = true and user is logged in, redirect to the home page.
    // otherwise, the route is accessible with or without authorization.
    let isLoggedIn = authInfo?.status === signInStatus.Success;
    let redirectUrl = authInfo?.isAdmin ? 'Admin' : '/';
    return <ConditionalRoute condition={!isRestricted || !isLoggedIn} defaultUrl={redirectUrl} />;
};

export default PublicRoute;
