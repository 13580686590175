import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TextFieldWIthCopyToClipboard from '../settings/TextFieldWIthCopyToClipboard';

const CopyTextCard = (props: CopyTextCardProps) => {
    return (
        <div data-testid="copy-text-card">
            <div className="mb-4 oauth-card">
                <h5 className="mb-2">
                    <FormattedMessage id={props.formattedMessageHeader} />
                </h5>
                <TextFieldWIthCopyToClipboard
                    id={props.id}
                    value={props.copyText}
                    readOnly={true}
                    getValue={() => props.copyText}
                />
            </div>
        </div>
    );
};

export interface CopyTextCardProps {
    copyText: string;
    formattedMessageHeader: string;
    id: string;
}

export default CopyTextCard;
