import * as React from 'react';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

interface DateTimePickerProps extends React.Component {
    defaultValue?: string;
    size: 'small' | 'medium';
    label?: string;
    timezone?: string | 'UTC';
    format?: string | undefined;
    onChange: (value: dayjs.Dayjs | null) => void;
}

const DateTimePicker = ({ defaultValue, size, timezone = 'UTC', ...rest }: DateTimePickerProps) => {
    dayjs.extend(utc);
    dayjs.extend(dayjsTimezone);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiDateTimePicker
                {...rest}
                timezone={timezone}
                defaultValue={defaultValue ? dayjs.tz(defaultValue, timezone) : null}
                slotProps={{
                    actionBar: { actions: ['clear', 'accept'] },
                    textField: {
                        size: size,
                    },
                }}
            />
        </LocalizationProvider>
    );
};

export default DateTimePicker;
