import React, { Fragment, useEffect, useState } from 'react';
import Sidebar from '../layout/Sidebar';
import WebhooksService from '../../services/WebhooksService';
import WebhooksSettingsList from './WebhooksSettingsList';
import LoadingSpinner from '../common/LoadingSpinner';
import Toggle from '../common/Toggle';
import config from '../../config/app';
import {
    Alert,
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Tooltip,
} from 'reactstrap';
import WebhooksMenu from './WebhooksMenu';
function WebhooksSettings(props) {
    const webhooksMenu = WebhooksMenu().menu;

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [menuItems] = useState(webhooksMenu);
    const [isFormValid, seIsFormValid] = useState(false);
    const [webhookSettings, setWebhookSettings] = useState({});
    const [isTestEnvironment, setIsTestEnvironment] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenSuccessModal, setOpenSuccessModal] = useState(false);

    const activeEnvironmentString = isTestEnvironment ? config.testDisplayName : config.prodDisplayName;
    const inactiveEnvironmentString = !isTestEnvironment ? config.testDisplayName : config.prodDisplayName;

    useEffect(() => {
        setIsLoading(true);
        WebhooksService.getSettings({ isTestEnvironment: isTestEnvironment })
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setWebhookSettings(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [isTestEnvironment]);

    useEffect(() => {
        if (webhookSettings.webhooksUrl && webhookSettings.webhooksSecret?.length > 10 === true) {
            seIsFormValid(true);
        } else {
            seIsFormValid(false);
        }
    }, [webhookSettings]);

    const onSuccessModalClose = () => {
        setOpenSuccessModal(false);
    };

    const onEventTypeChanged = (eventType, isSubscribed) => {
        let updateWebhooksTypes = webhookSettings.webhooksTypes.map((item) => {
            if (item.id === eventType.id) {
                item.isSubscribed = isSubscribed;
            }
            return item;
        });
        let updateSettings = {
            ...webhookSettings,
            webhooksTypes: updateWebhooksTypes,
        };
        setWebhookSettings(updateSettings);
    };

    const onRestaurantChanged = (eventType, isSubscribed) => {
        let updateRestaurants = webhookSettings.providers.map((item) => {
            if (item.id === eventType.id) {
                item.isSubscribed = isSubscribed;
            }
            return item;
        });

        let updateSettings = {
            ...webhookSettings,
            providers: updateRestaurants,
        };

        setWebhookSettings(updateSettings);
    };

    const SaveWebhooksSettings = () => {
        WebhooksService.updateSettings({
            isTestEnvironment: isTestEnvironment,
            settings: webhookSettings,
        }).then((response) => {
            if (response === true) {
                setOpenSuccessModal(true);
            }
        });
    };

    const ReEnableWebhooks = () => {
        WebhooksService.reEnableWebhooks({
            isTestEnvironment: isTestEnvironment,
        }).then((response) => {
            if (response === true) {
                let updateSettings = {
                    ...webhookSettings,
                    webhooksDisabledBySystem: false,
                };
                setWebhookSettings(updateSettings);
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setWebhookSettings({
            ...webhookSettings,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const EnableWebhooks = (e) => {
        const { name, checked } = e.target;
        if (checked) {
            let updateWebhooksTypes = webhookSettings.webhooksTypes.map((item) => {
                item.isSubscribed = true;
                return item;
            });

            let updateSettings = {
                ...webhookSettings,
                [name]: checked,
                webhooksTypes: updateWebhooksTypes,
            };
            setWebhookSettings(updateSettings);
        } else {
            handleInputChange(e);
        }
    };

    return (
        <Fragment>
            <div className="container-fluid settings-page">
                <div className="row flex-xl-nowrap">
                    <div className="sidebar-column col-md-3 col-xl-2 p-0 d-none d-md-block">
                        <Sidebar menuItems={menuItems} />
                    </div>
                    <main className="offset-md-3 col-md-9 offset-xl-2 col-xl-10 content">
                        {isLoading ? (
                            <LoadingSpinner loadingText="Loading..." />
                        ) : (
                            <Fragment>
                                <h1>Webhooks settings</h1>

                                <Toggle
                                    label={`Viewing ${activeEnvironmentString} Webhooks Settings, toggle to view${' '}
                    ${inactiveEnvironmentString} ebhooks Settings`}
                                    checked={isTestEnvironment}
                                    handleChange={setIsTestEnvironment}
                                    checkedLabel="Test"
                                    uncheckedLabel="Live"
                                />
                                <hr />

                                {webhookSettings.webhooksDisabledBySystem && (
                                    <div className="col-md-10">
                                        <Alert className="row" color="danger">
                                            <div className="col-md-10">
                                                <p>
                                                    Your webhooks have automatically been disabled after too many failed
                                                    delivery attempts. Please check the logs to find out why this has
                                                    happened.
                                                </p>
                                                <p>
                                                    If you think that you have fixed the issue that was causing the
                                                    failed requests, you can re-enable your webhooks.
                                                </p>
                                            </div>
                                            <div className="col-md-2">
                                                <Button color="danger" onClick={ReEnableWebhooks}>
                                                    Re-Enable Webhooks
                                                </Button>
                                            </div>
                                        </Alert>
                                    </div>
                                )}
                                <div className="col-md-10">
                                    <FormGroup check inline>
                                        <Label check for="webhook-enable">
                                            <h6>
                                                <Input
                                                    type="checkbox"
                                                    checked={webhookSettings.areWebhooksEnabled}
                                                    name={'areWebhooksEnabled'}
                                                    onChange={(e) => {
                                                        EnableWebhooks(e);
                                                    }}
                                                />
                                                Enable Webhooks?
                                            </h6>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            <h6>URL</h6>
                                        </Label>
                                        <Input
                                            autoComplete="off"
                                            id={props.id}
                                            type="text"
                                            value={webhookSettings.webhooksUrl || ''}
                                            placeholder="Enter URL"
                                            name={'webhooksUrl'}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>
                                            <h6>
                                                Webhooks Secret
                                                <span className="badge">
                                                    <i color="secondary" id={'webhook-secret-info'}>
                                                        <img src="/images/info.svg" alt="Info" />
                                                    </i>
                                                </span>
                                            </h6>
                                            <Tooltip
                                                placement="top"
                                                isOpen={tooltipOpen}
                                                target={'webhook-secret-info'}
                                                toggle={toggle}
                                            >
                                                This is a secret shared between yourself and ResDiary. It is used to
                                                sign each webhooks request that we send you so that you can verify that
                                                the request came from ResDiary, and not somewhere else. If you leave
                                                this field empty, no signature will be generated.
                                            </Tooltip>
                                        </Label>
                                        <Input
                                            autoComplete="off"
                                            id={props.id}
                                            type="text"
                                            value={webhookSettings.webhooksSecret || ''}
                                            placeholder="Enter webhooks secret"
                                            name={'webhooksSecret'}
                                            onChange={handleInputChange}
                                        />
                                        {webhookSettings.webhooksSecret?.length > 10 !== true && (
                                            <span className="help-block">Please enter at least 10 characters.</span>
                                        )}
                                    </FormGroup>
                                </div>

                                <hr />

                                <div className="col-md-10">
                                    <WebhooksSettingsList
                                        title={'Event Types'}
                                        items={webhookSettings.webhooksTypes}
                                        onSettingChanged={onEventTypeChanged}
                                    />
                                </div>

                                <hr />

                                <div className="col-md-10">
                                    <WebhooksSettingsList
                                        title={'Restaurants'}
                                        items={webhookSettings.providers}
                                        onSettingChanged={onRestaurantChanged}
                                    />
                                </div>

                                <div className="col">
                                    <Button color="primary" disabled={!isFormValid} onClick={SaveWebhooksSettings}>
                                        Save
                                    </Button>
                                </div>
                            </Fragment>
                        )}
                        <Modal centered isOpen={isOpenSuccessModal} toggle={onSuccessModalClose}>
                            <ModalHeader className="border-bottom-0" toggle={onSuccessModalClose}>
                                Saved
                            </ModalHeader>
                            <ModalBody>
                                <p>Your webhooks settings have been saved successfully.</p>
                                <p>It can take up to 10 minutes for your changes to take effect.</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={onSuccessModalClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </main>
                </div>
            </div>
        </Fragment>
    );
}

export default WebhooksSettings;
