import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Chevron } from '../../../content/icons/Chevron.svg';
import MomentHelper from '../../../helpers/MomentHelper';
import DataTable from '../../../components/common/DataTable';
import Tag from '../../../components/common/Tag';
import appType from '../../../enums/appType';
import tagColour from '../../../enums/tagColour';
import auditTrailEventType from '../../../enums/Admin/auditTrailEventType';
import { useIntl } from 'react-intl';

function AuditTrailTable({ data, isLoading, renderEmptyResult }) {
    const gotoAuditTrailDetails = () => {
        alert('TODO: auditTrailsDetails page');
    };
    const intl = useIntl();
    let columns = [
        {
            id: 'event',
            name: intl.formatMessage({ id: 'Admin.AuditTrailTable.Event' }),
            selector: (x) => x.event,
        },
        {
            id: 'appType',
            name: intl.formatMessage({ id: 'Admin.AuditTrailTable.Type' }),
            selector: (x) => x.appType,
            displayFormat: (x) => (
                <div className="mr-4">
                    <Tag value={x.appType} color={x.appType === appType.private ? tagColour.Blue : tagColour.Purple} />
                </div>
            ),
        },
        {
            id: 'details',
            name: intl.formatMessage({ id: 'Admin.AuditTrailTable.Details' }),
            selector: (x) => x.createdByUser.email,
            displayFormat: (x) => {
                if (x.auditTrailEventType === auditTrailEventType.update)
                    return (
                        <div>
                            <b>{x.auditTrailEventType}</b>
                            <br />
                            {x.auditSubject} from <br />
                            {x.before}
                            <br />
                            {x.after}
                        </div>
                    );
                else {
                    return (
                        <div>
                            <b>{x.auditTrailEventType}</b>
                            <br />
                            {x.auditSubject} <br />
                            {x.auditTrailEventType === auditTrailEventType.delete ? (
                                <div>{x.before}</div>
                            ) : (
                                <div>{x.after}</div>
                            )}
                        </div>
                    );
                }
            },
        },
        {
            id: 'lastUpdate',
            name: intl.formatMessage({ id: 'Admin.AuditTrailTable.LastUpdate' }),
            selector: (x) => x.createdDate,
            displayFormat: (x) => MomentHelper.newInstance(x.updatedDate).format('LL'),
        },
        {
            id: 'type',
            name: intl.formatMessage({ id: 'Admin.AuditTrailTable.UpdatedBy' }),
            selector: (x) => x.updatedBy,
        },
        {
            id: 'action',
            name: intl.formatMessage({ id: 'Admin.AuditTrailTable.Action' }),
            displayFormat: (x) => (
                <Chevron className="d-inline-block mt-2 rotate-90deg" onClick={gotoAuditTrailDetails} />
            ),
        },
    ];

    return (
        <div className="audit-trail-table">
            <DataTable
                className="mb-0"
                columns={columns}
                data={data}
                isLoading={isLoading}
                renderEmptyResult={renderEmptyResult}
            />
        </div>
    );
}

AuditTrailTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    renderEmptyResult: PropTypes.elementType,
};

export default AuditTrailTable;
