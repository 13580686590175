/* eslint-disable */
import { api } from '../helpers/ApiHelper';
import ErrorMessage from '../model/services/ErrorMessage';
import SignInResponse from '../model/login/SignInResponse';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const checkPasswordResetToken = (token: string, id: string) => {
    return api.get<boolean, string>(`Auth/CheckPasswordResetToken?token=${token}&uId=${id}`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const forgotPassword = async (emailAddress: string) => {
    return api
        .post<undefined, string>('Auth/ForgotPassword', JSON.stringify({ emailAddress: emailAddress }))
        .then((response) => {
            if (response.ok) {
                return true;
            } else if (!response.ok && response.data !== undefined) {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            } else {
                return undefinedResponse();
            }
        });
};

const getStatus = async () => {
    return await api.get<SignInResponse, string>('Auth/Status').then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const resetPassword = async (token: string, password: string, userId: string) => {
    return await api
        .post<undefined, string>(
            'Auth/ResetPassword',
            JSON.stringify({ token: token, password: password, userId: userId })
        )
        .then((response) => {
            console.log(response.data);
            if (response.ok) {
                return true;
            } else if (!response.ok && response.data !== undefined) {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            } else {
                return undefinedResponse();
            }
        });
};

const signIn = async (username: string, password: string) => {
    return await api
        .post<SignInResponse, SignInResponse>('Auth/SignIn', {
            Username: username,
            Password: password,
        })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    return response.data;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

const signOut = async () => {
    return await api.post('Auth/SignOut');
};

export default {
    checkPasswordResetToken,
    forgotPassword,
    resetPassword,
    signIn,
    signOut,
    getStatus,
};
