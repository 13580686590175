import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import signInStatus from '../enums/signInStatus';
import userOnboardingStageEnum from '../enums/userOnboardingStage';
import AccountService from '../services/AccountService';
import { useAuthentication } from './AuthenticationContext';

interface IOnboardingContext {
    onboardingStage: string;
    setOnboardingStage: (_onboardingStage: string) => void;
}

type OnboardingProviderProps = {
    children: React.ReactNode;
};

const OnboardingContext = React.createContext<IOnboardingContext>({
    onboardingStage: userOnboardingStageEnum.NotRequired,
    setOnboardingStage: (_onboardingStage: string) => {},
});

export const useOnboarding = () => {
    return useContext(OnboardingContext);
};

export const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
    const [onboardingStage, setOnboardingStage] = useState<string>(userOnboardingStageEnum.NotRequired);
    const [isLoading, setIsLoading] = useState(true);
    const { authInfo } = useAuthentication();

    useEffect(() => {
        if (authInfo && authInfo?.status === signInStatus.Success && authInfo.username && !authInfo.isAdmin) {
            AccountService.getUserOnboardingStage(authInfo.username).then((response) => {
                setOnboardingStage(response as string);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [authInfo]);

    const value = {
        onboardingStage,
        setOnboardingStage,
    };

    return <OnboardingContext.Provider value={value}>{!isLoading && children}</OnboardingContext.Provider>;
};
