import React, { useState, useEffect, Fragment } from 'react';
import AppSetupSection from './AppSetupSection';
import { Form, FormGroup, Button } from 'reactstrap';
import AppService from '../../../services/AppService';
import AppStartingGuideSection from '../../../domainObjects/AppStartingGuideSection';
import AppStartingGuideSectionStep from '../../../domainObjects/AppStartingGuideSectionStep';
import LoadingSpinner from '../../common/LoadingSpinner';
import RichTextEditor from '../../common/RichTextEditor';
import AppStartingGuideSectionType from '../../../enums/appManagement/AppStartingGuideSectionType';
import useValidation from '../../../hooks/useValidation';
import { FormattedMessage } from 'react-intl';

function AppStartingGuidePage(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [startingGuideSection, setStartingGuideSection] = useState(null);
    const { submit } = useValidation();

    useEffect(() => {
        AppService.getStartingGuide(props.application.id)
            .then((response) => {
                if (response === null || !('errorMessage' in response)) {
                    setStartingGuideSection(new AppStartingGuideSection(response));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [props.application.id]);

    function insertNewSectionStep(type) {
        const sectionDetailCopy = { ...startingGuideSection };

        if (type === AppStartingGuideSectionType.setup) {
            sectionDetailCopy.setupSteps = [
                ...sectionDetailCopy.setupSteps,
                new AppStartingGuideSectionStep(null, AppStartingGuideSectionType.setup),
            ];
        } else {
            sectionDetailCopy.usageSteps = [
                ...sectionDetailCopy.usageSteps,
                new AppStartingGuideSectionStep(null, AppStartingGuideSectionType.usage),
            ];
        }
        setStartingGuideSection(sectionDetailCopy);
    }

    function removeSectionStep(uniqueIdentifier, type) {
        const sectionDetailCopy = { ...startingGuideSection };

        if (type === AppStartingGuideSectionType.setup) {
            sectionDetailCopy.setupSteps = sectionDetailCopy.setupSteps.filter(
                (s) => s.uniqueIdentifier !== uniqueIdentifier
            );
        } else {
            sectionDetailCopy.usageSteps = sectionDetailCopy.usageSteps.filter(
                (s) => s.uniqueIdentifier !== uniqueIdentifier
            );
        }
        setStartingGuideSection(sectionDetailCopy);
    }

    function onValueChange(key, value, uniqueIdentifier, type) {
        const sectionDetailCopy = { ...startingGuideSection };

        if (type === AppStartingGuideSectionType.setup) {
            const index = sectionDetailCopy.setupSteps.findIndex((s) => s.uniqueIdentifier === uniqueIdentifier);
            sectionDetailCopy.setupSteps[index][key] = value;
        } else {
            const index = sectionDetailCopy.usageSteps.findIndex((s) => s.uniqueIdentifier === uniqueIdentifier);
            sectionDetailCopy.usageSteps[index][key] = value;
        }
        setStartingGuideSection(sectionDetailCopy);
    }

    function onImageChange(image, uniqueIdentifier, type) {
        const sectionDetailCopy = { ...startingGuideSection };

        if (type === AppStartingGuideSectionType.setup) {
            const index = sectionDetailCopy.setupSteps.findIndex((s) => s.uniqueIdentifier === uniqueIdentifier);
            sectionDetailCopy.setupSteps[index].image = image;
        } else {
            const index = sectionDetailCopy.usageSteps.findIndex((s) => s.uniqueIdentifier === uniqueIdentifier);
            sectionDetailCopy.usageSteps[index].image = image;
        }
        setStartingGuideSection(sectionDetailCopy);
    }

    function setEditorState(value) {
        const sectionDetailCopy = { ...startingGuideSection };
        sectionDetailCopy.description = value;
        setStartingGuideSection(sectionDetailCopy);
    }

    function saveStartingGuide() {
        let formData = configureFormData();

        setIsLoading(true);
        AppService.saveStartingGuide(props.application.id, formData)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setStartingGuideSection(new AppStartingGuideSection(response));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function configureFormData() {
        let data = new FormData();
        let startingGuide = new AppStartingGuideSection(startingGuideSection);
        data.append('startingGuide', JSON.stringify(startingGuide.toJson()));
        return data;
    }

    if (isLoading) {
        return <LoadingSpinner />;
    } else {
        return (
            <Fragment>
                <h1 className="mb-3">
                    <FormattedMessage id="AppManagement.AppManagementPage.StartingGuide" />
                </h1>
                <p className="mb-5">
                    <FormattedMessage id="AppManagement.AppManagementPage.ProvideInStoreAppInfo" />
                </p>
                <hr />
                <Form onSubmit={submit(saveStartingGuide)}>
                    <div className="starting-guide-section-container row mb-5">
                        <div className="col-md-3 mr-4">
                            <h6>What does your app do?</h6>
                            <p>Describe your app in one sentence, followed by the list of the key features.</p>
                        </div>
                        <div className="col-md-5">
                            <RichTextEditor
                                placeholder="Describe your app in one sentence"
                                setEditorState={setEditorState}
                                content={startingGuideSection.description ? AppStartingGuideSection.description : ''}
                            />
                        </div>
                    </div>
                    <hr />
                    <AppSetupSection
                        sectionTitle="How to set up the app?"
                        sectionDetailsList={startingGuideSection.setupSteps}
                        insertNewSectionStep={insertNewSectionStep}
                        onValueChange={onValueChange}
                        removeSectionStep={removeSectionStep}
                        onImageChange={onImageChange}
                        sectionType={AppStartingGuideSectionType.setup}
                    />
                    <hr />
                    <AppSetupSection
                        sectionTitle="How to use the app?"
                        sectionDetailsList={startingGuideSection.usageSteps}
                        insertNewSectionStep={insertNewSectionStep}
                        onValueChange={onValueChange}
                        removeSectionStep={removeSectionStep}
                        onImageChange={onImageChange}
                        sectionType={AppStartingGuideSectionType.usage}
                    />
                    <hr />
                    <FormGroup>
                        <div>
                            <Button type="submit" color="primary">
                                Save and Continue
                            </Button>
                        </div>
                    </FormGroup>
                </Form>
            </Fragment>
        );
    }
}

export default AppStartingGuidePage;
