import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Label } from 'reactstrap';
import TextWithIcon from '../../components/common/TextWithIcon';
import { ReactComponent as WarningIcon } from '../../content/icons/Icon-Alert.svg';
import AdminUserService from '../../services/admin/UserService';

function RevokeUserModal(props) {
    const [revokeInput, setRevokeInput] = useState('');

    function getCloseButton() {
        return (
            <button className="close" onClick={() => props.toggleModal()}>
                &times;
            </button>
        );
    }

    function revokeUserAccount() {
        AdminUserService.revokeUser(props.user.id)
            .then((response) => {
                if (response === true) {
                    props.revokeUserSuccessCallback(props.user.id);
                    props.addSuccessBar(`${props.user.email} access has been succesfully revoked`);
                } else {
                    props.addErrorBar(response.errorMessage);
                }
            })
            .finally(() => {
                props.toggleModal();
            });
    }

    return (
        <Modal className="revoke-modal modal" centered isOpen={props.isOpen}>
            <ModalHeader className="border-bottom-0" close={getCloseButton()}>
                <TextWithIcon
                    icon={<WarningIcon />}
                    iconClass="warning-icon"
                    text={`You are about to revoke ${props.user.email} access`}
                />
            </ModalHeader>
            <ModalBody>
                <Label>To confirm your action, type the text "REVOKE" below.</Label>

                <Input type="text" name="revoke-input" onChange={(e) => setRevokeInput(e.target.value)} />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => props.toggleModal()}>
                    No, I changed my mind
                </Button>
                <Button color="danger" disabled={revokeInput !== 'REVOKE'} onClick={() => revokeUserAccount()}>
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    );
}

RevokeUserModal.propTypes = {
    user: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    revokeUserSuccessCallback: PropTypes.func.isRequired,
    addErrorBar: PropTypes.func.isRequired,
    addSuccessBar: PropTypes.func.isRequired,
};

export default RevokeUserModal;
