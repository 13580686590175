import * as React from 'react';

const TextWithIcon = (props: TextWithIconProps) => {
    return (
        <div className={props.className ? 'd-flex text-with-icon ' + props.className : 'd-flex text-with-icon'}>
            <div className={props.iconClass ? 'icon ' + props.iconClass : 'icon'}>{props.icon}</div>
            <div className={props.textClass ? 'text ' + props.textClass : 'text'} data-testid="TextWithIcon-text">
                {props.text}
            </div>
        </div>
    );
};

export interface TextWithIconProps {
    icon: React.ReactNode;
    text?: string | number;
    iconClass?: string;
    textClass?: string;
    className?: string;
}

export default TextWithIcon;
