import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import LocalisedApp from './LocalisedApp';
import { unregister } from './registerServiceWorker';
import './styles/common.scss';
import '../src/styles/documentation.scss';
import theme from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined;
const rootElement = document.getElementById('root');

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter basename={baseUrl}>
            <LocalisedApp />
        </BrowserRouter>
    </ThemeProvider>,
    rootElement
);

// service-worker causes infinite redirect in production env
// TODO: https://github.com/ResDiary/ResDiary-DeveloperPortal/issues/45
unregister();
