const providerType = { providerType: 'RDDevHub' };

class AnalyticsHelper {
    static loadAnalytics(analyticsKey) {
        const script = document.createElement('script');
        const segmentContent = document.createTextNode(
            `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";analytics.load("${analyticsKey}");}}();`
        );
        script.appendChild(segmentContent);
        document.body.appendChild(script);
    }

    static getProviderType() {
        return providerType;
    }

    static trackPage(pageName) {
        window.analytics.page(pageName, providerType);
    }

    static trackClick(pageName) {
        window.analytics.track(pageName, providerType);
    }

    static trackClickWithProperties(event, properties) {
        properties = { ...properties, ...providerType };
        window.analytics.track(event, properties);
    }

    static identifyUserWithProperties(properties) {
        window.analytics.identify(properties);
    }

    static identifyUserId(provId) {
        window.analytics.identify(provId);
    }

    static identifyUserIdWithProperties(provId, properties, options, callBack) {
        window.analytics.identify(provId, properties, options, callBack);
    }

    static resetUser() {
        window.analytics.reset();
    }

    static setAnonymousId(anonymousId) {
        window.analytics.setAnonymousId(anonymousId);
    }
}
export default AnalyticsHelper;
