import * as React from 'react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import CreateAppModal from '../appManagement/CreateAppModal';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
/*import { NavLink } from 'react-router-dom';*/
//import { ReactComponent as AppIcon } from '../../content/icons/Icon-Apps.svg';
//import { ReactComponent as OrganisationIcon } from '../../content/icons/Icon-Organisations.svg';
//import { ReactComponent as DiaryManagementIcon } from '../../content/icons/Icon-Diary-Management.svg';
//import { ReactComponent as SubscriptionIcon } from '../../content/icons/Icon-Subscription.svg';
//import { ReactComponent as FileIcon } from '../../content/icons/Icon-File.svg';
//import { ReactComponent as SupportIcon } from '../../content/icons/Icon-Support-Person.svg';
//import config from '../../config/app';

function LandingPage(): JSX.Element {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    return (
        <Fragment>
            {isCreateModalOpen && <CreateAppModal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen} />}
            <div className="landing-page">
                <div className="header-container">
                    <div className="header-bg ">
                        <div className="landing-header">
                            <div className="mb-4">
                                <h1 className="header-title__header-2">
                                    <FormattedMessage id="Layout.LandingPage.Welcome" />
                                </h1>
                                <h1 className="header-title__header-2">
                                    <FormattedMessage id="Layout.LandingPage.ResDiaryHub" />
                                </h1>
                            </div>
                            <p className="header-title__description">
                                <FormattedMessage id="Layout.LandingPage.AppGetStartedMessage" />
                            </p>
                            <div className="float-right mt-3 ">
                                <Button
                                    color="primary"
                                    className="mr-3 text-uppercase"
                                    onClick={() => setIsCreateModalOpen(true)}
                                >
                                    <FormattedMessage id="Layout.LandingPage.CreateNewApp" />
                                </Button>
                                <Link to="/docs" className="btn btn-primary text-uppercase">
                                    <FormattedMessage id="Layout.LandingPage.ExploreDocs" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="landing-content">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-md-6">*/}
                {/*            <NavLink to={config.appsListPath}>*/}
                {/*                <div className="lg-nav-button">*/}
                {/*                    <AppIcon />*/}
                {/*                    <div className="nav-description">*/}
                {/*                        <h4>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.ManageApps" />*/}
                {/*                        </h4>*/}
                {/*                        <p>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.ManageAppsDescription" />*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </NavLink>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-6">*/}
                {/*            <NavLink to={config.profileTeamMembersPath}>*/}
                {/*                <div className="lg-nav-button">*/}
                {/*                    <OrganisationIcon />*/}
                {/*                    <div className="nav-description">*/}
                {/*                        <h4>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.Organisations" />*/}
                {/*                        </h4>*/}
                {/*                        <p>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.OrganisationsDescription" />*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </NavLink>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="row mb-5">*/}
                {/*        <div className="col-md-6">*/}
                {/*            <NavLink to={config.testDiariesPath}>*/}
                {/*                <div className="lg-nav-button">*/}
                {/*                    <DiaryManagementIcon />*/}
                {/*                    <div className="nav-description">*/}
                {/*                        <h4>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.DiariesManagement" />*/}
                {/*                        </h4>*/}
                {/*                        <p>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.DiariesManagementDescription" />*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </NavLink>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-6">*/}
                {/*            <NavLink to="/Subscription">*/}
                {/*                <div className="lg-nav-button">*/}
                {/*                    <SubscriptionIcon />*/}
                {/*                    <div className="nav-description">*/}
                {/*                        <h4>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.Subscription" />*/}
                {/*                        </h4>*/}
                {/*                        <p>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.SubscriptionDescription" />*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </NavLink>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="row mb-2">*/}
                {/*        <div className="col-md-6">*/}
                {/*            <h5>*/}
                {/*                <FormattedMessage id="Layout.LandingPage.Shortcuts" />*/}
                {/*            </h5>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="row mb-2">*/}
                {/*        <div className="col-md-4">*/}
                {/*            <NavLink to="/Docs">*/}
                {/*                <div className="lg-shortcut-button">*/}
                {/*                    <FileIcon />*/}
                {/*                    <div>*/}
                {/*                        <h5>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.Hub" />*/}
                {/*                        </h5>*/}
                {/*                        <p>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.HubDescription" />*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </NavLink>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-4">*/}
                {/*            <NavLink to="/Support">*/}
                {/*                <div className="lg-shortcut-button">*/}
                {/*                    <SupportIcon />*/}
                {/*                    <div>*/}
                {/*                        <h5>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.Support" />*/}
                {/*                        </h5>*/}
                {/*                        <p>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.SupportDescription" />*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </NavLink>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-4">*/}
                {/*            <NavLink to="/BetaUserProgramme">*/}
                {/*                <div className="lg-shortcut-button">*/}
                {/*                    <SupportIcon />*/}
                {/*                    <div>*/}
                {/*                        <h5>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.BetaUserProgramme" />*/}
                {/*                        </h5>*/}
                {/*                        <p>*/}
                {/*                            <FormattedMessage id="Layout.LandingPage.BetaUserProgrammeDescription" />*/}
                {/*                        </p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </NavLink>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </Fragment>
    );
}

export default LandingPage;
