import * as React from 'react';
import Tag from '../common/Tag';
import tagColour from '../../enums/tagColour';
import apiType from '../../enums/apiType';
import { useIntl } from 'react-intl';

interface AppApiTypeTagProps {
    appApiType: string;
}

const AppApiTypeTag = ({ appApiType }: AppApiTypeTagProps) => {
    const intl = useIntl();

    switch (appApiType) {
        case apiType.consumerApi:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppDetails.ConsumerApi' })}
                    color={tagColour.DarkBlue}
                />
            );
        case apiType.dataExtractApi:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppDetails.DataExtractApi' })}
                    color={tagColour.DarkBlue}
                />
            );
        case apiType.eposApi:
        default:
            return (
                <Tag
                    value={intl.formatMessage({ id: 'AppManagement.AppDetails.EposApi' })}
                    color={tagColour.DarkBlue}
                />
            );
    }
};

export default AppApiTypeTag;
