import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Controller, Control } from 'react-hook-form';

interface PasswordFieldProps {
    placeholder: string;
    label: string;
    name: string;
    fullWidth: boolean;
    control: Control<any>;
    dataTestId: string;
}

const PasswordField = ({ name, control, fullWidth, label, placeholder, dataTestId }: PasswordFieldProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
                <TextField
                    type={showPassword ? 'text' : 'password'}
                    label={label}
                    placeholder={placeholder}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    fullWidth={fullWidth}
                    variant="outlined"
                    data-testid={dataTestId}
                />
            )}
        />
    );
};

export default PasswordField;
