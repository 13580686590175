import Moment from 'moment-timezone';
import 'moment/locale/en-gb';

class MomentHelper {
    static newInstance(dateTimeString?: string) {
        if (dateTimeString && dateTimeString.length > 0) {
            return Moment(dateTimeString);
        } else {
            return Moment();
        }
    }

    static fromNow(date: Date) {
        const minDate = Moment.utc('0001-01-01');
        if (date != null && Moment.utc(date).isAfter(minDate)) {
            return Moment(date).fromNow();
        }
    }

    static durationAsHours(date: Date) {
        const minDate = Moment.utc('0001-01-01');
        if (date != null && Moment.utc(date).isAfter(minDate)) {
            let duration = Moment.duration(Moment.utc().diff(date));
            return duration.asHours();
        }
    }

    static now() {
        return Moment();
    }

    static init(date: Date) {
        return Moment(date);
    }
}

export default MomentHelper;
