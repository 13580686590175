import * as React from 'react';
import { Fragment } from 'react';
import { useLocation, Link /*, useNavigate*/ } from 'react-router-dom';
import config from '../../config/app';
import ProfileCircle from '../common/ProfileCircle';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import { useOrganisation } from '../../contexts/OrganisationContext';
import {
    Navbar,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { ReactComponent as SignoutIcon } from '../../content/icons/Icon-Signout.svg';
import { /*useIntl, */ FormattedMessage } from 'react-intl';
/*import { ReactComponent as SupportIcon } from '../../content/icons/Icon-Support.svg';*/

const Header = () => {
    const { authInfo, logout } = useAuthentication();
    const { defaultOrganisation /*, userOrganisations, setDefaultOrganisation*/ } = useOrganisation();
    const location = useLocation();
    /* const intl = useIntl();*/
    /*    let navigate = useNavigate();*/

    const noHeaderPaths = [
        config.loginPath,
        config.joinTeamPath,
        config.organisationSetupPath,
        config.createAccountPath,
        config.verifyAccountPath,
        config.accountQuestionnairePath,
        '/testapp/callback',
        '/testapp/app-install',
    ];

    if (
        authInfo?.status !== 'Success' ||
        noHeaderPaths.findIndex((i: string) => {
            return i.toLowerCase() === location.pathname.toLowerCase();
        }) !== -1
    ) {
        return null;
    }

    const handleLogoutClick = async () => {
        await logout();
    };

    //const handleContactSupportClick = () => {
    //    window.location.assign('mailto:support-api@resdiary.com');
    //};

    //const handleUserManagementClick = () => {
    //    navigate(config.adminUsersPath);
    //};

    //const handleDocumentManagementClick = () => {
    //    navigate(config.adminDocumentsPath);
    //};

    //const isInProfileTab = () => {
    //    return location.pathname.substring(0, 8) === '/Profile';
    //};

    function getUserSection() {
        return (
            <Fragment>
                <UncontrolledDropdown nav inNavbar className="user-section">
                    <DropdownToggle nav caret className="user-section-dropdown-toggle d-flex align-items-center">
                        <div className="user-control d-flex mr-5">
                            <ProfileCircle fullName={authInfo?.fullName!} />
                            <div className="ml-2 mt-1 text-left">
                                <div className="font-weight-bold text-capitalize">{authInfo?.fullName}</div>
                                {authInfo.isAdmin && <div>{authInfo?.username}</div>}
                                {!authInfo.isAdmin && (
                                    <div className="text-capitalize">{defaultOrganisation?.name}</div>
                                )}
                            </div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu className="user-dropdown-menu">
                        {/*{!authInfo.isAdmin && (*/}
                        {/*    <Fragment>*/}
                        {/*        <DropdownItem onClick={() => navigate(config.profileLandingPath)}>*/}
                        {/*            <Fragment>*/}
                        {/*                <div className="font-weight-bold text-capitalize">{authInfo?.fullName}</div>*/}
                        {/*                <div className="overflow-text">{authInfo?.username}</div>*/}
                        {/*            </Fragment>*/}
                        {/*        </DropdownItem>*/}
                        {/*        <DropdownItem divider />*/}
                        {/*        <DropdownItem header className="dropdown-header">*/}
                        {/*            {userOrganisations.length > 1*/}
                        {/*                ? intl.formatMessage({ id: 'Layout.Header.Organisations' })*/}
                        {/*                : intl.formatMessage({ id: 'Layout.Header.Organisation' })}*/}
                        {/*        </DropdownItem>*/}
                        {/*        {userOrganisations.map((org) => {*/}
                        {/*            return (*/}
                        {/*                <DropdownItem*/}
                        {/*                    key={org.id}*/}
                        {/*                    className={*/}
                        {/*                        defaultOrganisation?.id === org.id*/}
                        {/*                            ? 'organisation-dropdown-item selected overflow-text'*/}
                        {/*                            : 'organisation-dropdown-item overflow-text'*/}
                        {/*                    }*/}
                        {/*                    tag="a"*/}
                        {/*                    disabled={userOrganisations.length === 1}*/}
                        {/*                    onClick={() => setDefaultOrganisation(org.id)}*/}
                        {/*                >*/}
                        {/*                    {org.name}*/}
                        {/*                </DropdownItem>*/}
                        {/*            );*/}
                        {/*        })}*/}
                        {/*        <DropdownItem divider className="organisation-divider" />*/}
                        {/*    </Fragment>*/}
                        {/*)}*/}
                        {/*{authInfo.isAdmin && (*/}
                        {/*    <Fragment>*/}
                        {/*        <DropdownItem tag="a" onClick={handleUserManagementClick}>*/}
                        {/*            <div className="overflow-text">*/}
                        {/*                <FormattedMessage id="Layout.Header.UserManagement" />*/}
                        {/*            </div>*/}
                        {/*        </DropdownItem>*/}
                        {/*    </Fragment>*/}
                        {/*)}*/}
                        {/*{authInfo.isAdmin && (*/}
                        {/*    <Fragment>*/}
                        {/*        <DropdownItem tag="a" onClick={handleDocumentManagementClick}>*/}
                        {/*            <div className="overflow-text">*/}
                        {/*                <FormattedMessage id="Layout.Header.DocumentManagement" />*/}
                        {/*            </div>*/}
                        {/*        </DropdownItem>*/}
                        {/*    </Fragment>*/}
                        {/*)}*/}
                        {/*{!authInfo.isAdmin && (*/}
                        {/*    <Fragment>*/}
                        {/*        <DropdownItem onClick={handleContactSupportClick}>*/}
                        {/*            <div className="dropdown-items-row">*/}
                        {/*                <div className="dropdown-item-icon">*/}
                        {/*                    <SupportIcon />*/}
                        {/*                </div>*/}
                        {/*                <div>*/}
                        {/*                    <FormattedMessage id="Layout.Header.ContactSupport" />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </DropdownItem>*/}
                        {/*    </Fragment>*/}
                        {/*)}*/}
                        {/*<DropdownItem divider className="organisation-divider" />*/}
                        <DropdownItem tag="a" onClick={handleLogoutClick}>
                            <div className="dropdown-items-row">
                                <div className="dropdown-item-icon">
                                    <SignoutIcon />
                                </div>
                                <div>
                                    <FormattedMessage id="Layout.Header.LogOut" />
                                </div>
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Fragment>
        );
    }

    return (
        <Navbar id="header-nav-bar" expand="md" className={'fixed-top'}>
            <Nav className="ml-auto align-center" navbar>
                <div className="navbar-brand-container">
                    <Link className="navbar-brand" to={'/'}>
                        <img
                            className="res-developer-icon"
                            src={authInfo.isAdmin ? 'images/ADMIN_logo.svg' : 'images/RD_hub_logo.svg'}
                            alt=""
                        />
                        <span className="beta">BETA</span>
                    </Link>
                </div>
                <div className="page-nav-container">
                    {authInfo !== undefined && (
                        <NavItem className="nav-item">
                            <NavLink
                                tag={(props) => <Link {...props} />}
                                className={location.pathname === '/Docs' ? 'current-nav-location' : ''}
                                to="/Docs"
                            >
                                <FormattedMessage id="Layout.Header.Docs" />
                            </NavLink>
                        </NavItem>
                    )}
                    {!authInfo.isAdmin && (
                        <Fragment>
                            <NavItem>
                                <NavLink
                                    tag={(props) => <Link {...props} />}
                                    className={location.pathname === '/Apps' ? 'current-nav-location' : ''}
                                    to="/Apps"
                                >
                                    <FormattedMessage id="Layout.Header.Apps" />
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    tag={(props) => <Link {...props} />}
                                    className={
                                        location.pathname === config.testDiariesPath ? 'current-nav-location' : ''
                                    }
                                    to={config.testDiariesPath}
                                >
                                    <FormattedMessage id="Layout.Header.TestDiaries" />
                                </NavLink>
                            </NavItem>
                            {/*<NavItem className="nav-item">*/}
                            {/*    <NavLink*/}
                            {/*        tag={(props) => <Link {...props} />}*/}
                            {/*        className={isInProfileTab() ? 'current-nav-location' : ''}*/}
                            {/*        to="/Profile"*/}
                            {/*    >*/}
                            {/*        <FormattedMessage id="Layout.Header.Profile" />*/}
                            {/*    </NavLink>*/}
                            {/*</NavItem>*/}
                            {/*<NavItem className="nav-item">*/}
                            {/*    <NavLink*/}
                            {/*        tag={(props) => <Link {...props} />}*/}
                            {/*        className={*/}
                            {/*            location.pathname === config.teamLandingPath ? 'current-nav-location' : ''*/}
                            {/*        }*/}
                            {/*        to="/Team"*/}
                            {/*    >*/}
                            {/*        <FormattedMessage id="Layout.Header.Team" />*/}
                            {/*    </NavLink>*/}
                            {/*</NavItem>*/}
                        </Fragment>
                    )}
                    {authInfo.isAdmin && (
                        <Fragment>
                            <NavItem>
                                <NavLink
                                    tag={(props) => <Link {...props} />}
                                    className={location.pathname === config.adminAppsPath ? 'current-nav-location' : ''}
                                    to={config.adminAppsPath}
                                >
                                    <FormattedMessage id="Layout.Header.Apps" />
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item">
                                <NavLink
                                    tag={(props) => <Link {...props} />}
                                    className={
                                        location.pathname === config.adminTestDiariesPath ? 'current-nav-location' : ''
                                    }
                                    to={config.adminTestDiariesPath}
                                >
                                    <FormattedMessage id="Layout.Header.TestDiaries" />
                                </NavLink>
                            </NavItem>
                            {/*<NavItem className="nav-item">*/}
                            {/*    <NavLink*/}
                            {/*        tag={(props) => <Link {...props} />}*/}
                            {/*        className={*/}
                            {/*            location.pathname === config.adminAuditPath ? 'current-nav-location' : ''*/}
                            {/*        }*/}
                            {/*        to={config.adminAuditPath}*/}
                            {/*    >*/}
                            {/*        <FormattedMessage id="Layout.Header.AuditTrails" />*/}
                            {/*    </NavLink>*/}
                            {/*</NavItem>*/}
                            {/*<NavItem className="nav-item">*/}
                            {/*    <NavLink*/}
                            {/*        tag={(props) => <Link {...props} />}*/}
                            {/*        className={*/}
                            {/*            location.pathname === config.adminOrganisationsPath*/}
                            {/*                ? 'current-nav-location'*/}
                            {/*                : ''*/}
                            {/*        }*/}
                            {/*        to={config.adminOrganisationsPath}*/}
                            {/*    >*/}
                            {/*        <FormattedMessage id="Layout.Header.Organisations" />*/}
                            {/*    </NavLink>*/}
                            {/*</NavItem>*/}
                        </Fragment>
                    )}
                </div>
                <div className="user-section-container">{getUserSection()}</div>
            </Nav>
        </Navbar>
    );
};

export default Header;
