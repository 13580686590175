import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { Container } from 'reactstrap';
import countries from 'i18n-iso-countries';
import enLocal from 'i18n-iso-countries/langs/en.json';
import AccountService from '../../services/AccountService';
import OrganisationService from '../../services/OrganisationService';
import ValidationMessage from '../common/ValidationMessage';
import InfoBarManager from '../common/InfoBarManager';
import infoBarType from '../../enums/infoBarType';
import ProgressPage from '../common/ProgressPage';
import ProgressBar from '../common/ProgressBar';
import { useOnboarding } from '../../contexts/OnboardingContext';
import { useOrganisation } from '../../contexts/OrganisationContext';
import userOnboardingStage from '../../enums/userOnboardingStage';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { useIntl } from 'react-intl';
import useTrackPage from '../../hooks/useTrackPage';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

let _ID = 0;

const QuestionnairePage = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { setOnboardingStage } = useOnboarding();
    const { defaultOrganisation } = useOrganisation();

    const [questionnaireAnswers, setQuestionnaireAnswers] = useState({});
    const [isInvalidForm, setIsInvalidForm] = useState(true);
    const [showValidationMessage, setShowValidationMessage] = useState();
    const [questionnaireItems, setQuestionnaireItems] = useState(null);
    const [infoBars, setInfoBars] = useState([]);

    useTrackPage('Hub Account Creation Details page');

    countries.registerLocale(enLocal);
    const countryObj = countries.getNames('en', { select: 'official' });
    const countryList = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key,
        };
    });

    const addSuccessBar = useCallback((invitedUsers) => {
        setInfoBars((i) => [
            ...i,
            {
                id: _ID++,
                type: infoBarType.success,
                message: `Invitations to ${invitedUsers} team member(s) have been sent`,
            },
        ]);
    }, []);

    const addErrorBar = useCallback((message) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message, expireIn: 0 }]);
    }, []);

    useEffect(() => {
        // Get questionnaire items api call
        AccountService.getQuestionnaire().then((response) => {
            if (response.errorMessage) {
                addErrorBar(response.errorMessage);
            } else {
                setQuestionnaireItems(response);
            }
        });
    }, [addErrorBar]);

    useEffect(() => {
        OrganisationService.getTeamMembers(defaultOrganisation.id).then((result) => {
            if (result.length > 1) {
                const invitedUsers = result.length - 1; // excluding the user signing up
                addSuccessBar(invitedUsers);
            }
        });
    }, [addSuccessBar, defaultOrganisation.id]);

    useEffect(() => {
        // Check if can submit questionnaire

        const isFormValid =
            questionnaireAnswers.role &&
            questionnaireAnswers.purpose &&
            questionnaireAnswers.companySize &&
            questionnaireAnswers.companyLocation &&
            questionnaireAnswers.commonProblems?.length > 0;

        setIsInvalidForm(!isFormValid);
    }, [questionnaireAnswers]);

    const submitQuestionnaire = () => {
        const companyName =
            questionnaireAnswers.purpose === 'Client, as a freelancer' || 'Myself'
                ? 'N/A'
                : questionnaireAnswers.companyName;
        const isInvalidForm =
            !questionnaireAnswers.position ||
            !companyName ||
            (questionnaireAnswers.commonProblems.includes('other') && !questionnaireAnswers.commonProblemsOther);
        setShowValidationMessage(isInvalidForm);
        if (!isInvalidForm) {
            AccountService.saveQuestionnaire(questionnaireAnswers)
                .then((response) => {
                    if (response.errorMessage) {
                        setInfoBars([
                            ...infoBars,
                            {
                                message: response.errorMessage,
                                type: infoBarType.error,
                            },
                        ]);
                    } else {
                        const questionnaireProperties = {
                            descriptionRole: questionnaireAnswers.role,
                            apiUsageFor: questionnaireAnswers.purpose,
                            businessSize: questionnaireAnswers.companySize,
                            businessLocation: questionnaireAnswers.companyLocation,
                            apiProblemsUsage: questionnaireAnswers.commonProblems,
                        };
                        AnalyticsHelper.trackClickWithProperties('Web Hub Start', questionnaireProperties);
                        setOnboardingStage(userOnboardingStage.Complete);
                        // Redirecting to landing page
                        navigate('/');
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const setAnswer = (e) => {
        const fieldName = e.target.name;
        let fieldValue = e.target.value;

        if (e.target.type === 'radio') {
            fieldValue = e.target.checked ? e.target.value : null;
        }

        if (e.target.type === 'checkbox') {
            let newArray = [];
            const commonProblems = questionnaireAnswers.commonProblems ?? [];
            if (e.target.checked && !commonProblems.includes(e.target.value)) {
                newArray = [...commonProblems, e.target.value];
            } else {
                newArray = commonProblems.filter((item) => item !== e.target.value);
            }
            fieldValue = newArray;
        }

        // Reset sub question answers if main answer is changed
        if (fieldName === 'role') {
            questionnaireAnswers.position = null;
        }

        if (fieldName === 'purpose') {
            if (fieldValue === 'Client, as a freelancer' || fieldValue === 'Myself') {
                questionnaireAnswers.companyName = null;
            }
            questionnaireAnswers.entityName = null;
        }

        if (fieldName === 'commonProblems' && fieldValue === 'Other') {
            questionnaireAnswers.commonProblemsOther = null;
        }

        const questionnaire = {
            ...questionnaireAnswers,
            [fieldName]: fieldValue,
        };

        setQuestionnaireAnswers(questionnaire);
        /*** Leaving the below commented out console log in for debugging ***/
        // console.log(questionnaire);
    };

    const getCompanySizeImg = (option) => {
        switch (option) {
            case '2 - 10':
                return '/images/RD-Employees-2.svg';
            case '11 - 50':
                return '/images/RD-Employees-11.svg';
            case '51 - 200':
                return '/images/RD-Employees-51.svg';
            case '201 - 1000':
                return '/images/RD-Employees-201.svg';
            case '1001+':
                return '/images/RD-Employees-1001.svg';
            default:
                break;
        }
    };

    const renderQuestionnaireOptions = (questionnaireItem, showSubQuestion) => {
        return (
            <Fragment>
                {questionnaireItem.label}
                <div className="selection-container">
                    {questionnaireItem.options.map((option, index) => (
                        <div className="selection-item" key={index}>
                            <label className="rd-input-container mb-2">
                                <input
                                    type={questionnaireItem.type}
                                    name={questionnaireItem.fieldName}
                                    value={questionnaireItem.type === 'checkbox' ? option.key : option.value}
                                    onChange={setAnswer}
                                />
                                <span className="checkmark"></span>
                                {option.value}
                            </label>
                            {option.subQuestion && showSubQuestion(option) && renderSubQuestion(option.subQuestion)}
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    };

    const renderSubQuestion = (subQuestion) => {
        const subQuestionClass =
            subQuestion.isRequired && !questionnaireAnswers[subQuestion.fieldName] && showValidationMessage
                ? 'form-control col-md-8 is-invalid'
                : 'form-control col-md-8';

        return (
            <div className="form-group sub-question">
                {subQuestion.options?.length > 0 && (
                    <select
                        className={subQuestionClass}
                        name={subQuestion.fieldName}
                        onChange={setAnswer}
                        defaultValue=""
                    >
                        <option value="" disabled>
                            {subQuestion.label}
                        </option>
                        {subQuestion.options.map((subtype, subtypeIndex) => (
                            <option key={subtypeIndex} value={subtype.value}>
                                {subtype.value}
                            </option>
                        ))}
                    </select>
                )}

                {subQuestion?.type === 'text' && (
                    <input
                        type={subQuestion?.type}
                        className={subQuestionClass}
                        name={subQuestion.fieldName}
                        placeholder={subQuestion.label}
                        onChange={setAnswer}
                    />
                )}

                {showValidationMessage && !questionnaireAnswers[subQuestion.fieldName] && (
                    <div className="validation-input-feedback">
                        <ValidationMessage message={'This field is required'} />
                    </div>
                )}
            </div>
        );
    };

    const progressBar = () => {
        return (
            <ProgressPage
                pageTitle={intl.formatMessage({ id: 'Common.GetStartedMessage' })}
                progressBar={
                    <ProgressBar
                        createAccountStatus={'completed'}
                        organisationSetupStatus={'completed'}
                        accountDetailsStatus={'incomplete'}
                    />
                }
            />
        );
    };

    return (
        <Container className="account-page">
            <div className="flex-container-center">
                <div className="container">
                    <div>{progressBar()}</div>
                    <div className="form-wrapper">
                        <div className="form-content">
                            <InfoBarManager infoBarArray={infoBars} />
                            <div className="intro-header">
                                <div className="heading text-uppercase bold-text">Account Details</div>
                                <p>
                                    Last but not least, we would like to know a little bit more about you and your
                                    organisation.
                                </p>
                            </div>
                            <div className="account-questionnaire">
                                {questionnaireItems && (
                                    <div>
                                        <ol>
                                            <li className="questionnaire-item">
                                                {renderQuestionnaireOptions(
                                                    questionnaireItems.role,
                                                    (option) =>
                                                        questionnaireAnswers[questionnaireItems.role.fieldName] ===
                                                        option.value
                                                )}
                                            </li>
                                            <li className="questionnaire-item">
                                                {renderQuestionnaireOptions(
                                                    questionnaireItems.purpose,
                                                    (option) =>
                                                        questionnaireAnswers[questionnaireItems.purpose.fieldName] ===
                                                        option.value
                                                )}
                                            </li>
                                            <li className="questionnaire-item">
                                                {questionnaireItems.companySize.label}
                                                <br />
                                                <div
                                                    className="btn-group btn-group-toggle selection-container"
                                                    data-toggle="buttons"
                                                >
                                                    {questionnaireItems.companySize.options.map((option, index) => (
                                                        <label
                                                            className="btn btn-secondary btn-company-size"
                                                            key={index}
                                                        >
                                                            <input
                                                                type="radio"
                                                                name="companySize"
                                                                value={option.value}
                                                                onClick={setAnswer}
                                                            />
                                                            <img
                                                                src={getCompanySizeImg(option.value)}
                                                                height="16"
                                                                alt=""
                                                            />
                                                            <p className="employee-count">{option.value}</p>
                                                            <p className="employee-label">employees</p>
                                                        </label>
                                                    ))}
                                                </div>
                                            </li>
                                            <li className="questionnaire-item">
                                                {questionnaireItems.companyLocation.label}
                                                <div className="selection-container">
                                                    <select
                                                        className="col-md-6 form-control"
                                                        name={questionnaireItems.companyLocation.fieldName}
                                                        onChange={setAnswer}
                                                        defaultValue=""
                                                    >
                                                        <option value="" disabled>
                                                            {questionnaireAnswers['country'] ?? 'Select a country'}
                                                        </option>
                                                        {countryList.map((country, subtypeIndex) => (
                                                            <option key={subtypeIndex} value={country.label}>
                                                                {country.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </li>
                                            <li className="questionnaire-item">
                                                {renderQuestionnaireOptions(
                                                    questionnaireItems.commonProblems,
                                                    (option) =>
                                                        Array.isArray(
                                                            questionnaireAnswers[
                                                                questionnaireItems.commonProblems.fieldName
                                                            ]
                                                        ) &&
                                                        questionnaireAnswers[
                                                            questionnaireItems.commonProblems.fieldName
                                                        ].includes(option.key)
                                                )}
                                            </li>
                                        </ol>
                                    </div>
                                )}
                                <Button
                                    className=""
                                    type="button"
                                    variant="contained"
                                    onClick={submitQuestionnaire}
                                    disabled={isInvalidForm}
                                >
                                    <FormattedMessage id="Common.StartUsingTheHub" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default QuestionnairePage;
