import { useState, useEffect } from 'react';

const useMediaQuery = (query: string) => {
    const [doesMatch, onSetDoesMatch] = useState(false);

    useEffect(() => {
        const onUpdateMatch = ({ matches }: { matches: boolean }) => {
            onSetDoesMatch(matches);
        };

        const matcher = window.matchMedia(query);

        if (matcher.addEventListener) {
            matcher.addEventListener('change', onUpdateMatch);
        } else {
            matcher.addListener(onUpdateMatch);
        }

        onUpdateMatch(matcher);

        return () => {
            if (matcher.removeEventListener) {
                matcher.removeEventListener('change', onUpdateMatch);
            } else {
                matcher.removeListener(onUpdateMatch);
            }
        };
    }, [query, onSetDoesMatch]);

    return doesMatch;
};

export default useMediaQuery;
