import React, { useEffect, useState, useRef, Fragment, useCallback } from 'react';
import { useParams, useLocation } from 'react-router';
import htmlParser from 'html-react-parser';
import Sidebar from '../layout/Sidebar';
import LoadingSpinner from '../common/LoadingSpinner';
import ApiDocsService from '../../services/ApiDocsService';
import { initDocs, initMobileNav } from './initDocs';
import WebhooksMenu from '../webhooks/WebhooksMenu';

const ApiDocumentationDetail = (props) => {
    const location = useLocation();
    let { productId, categoryId, articleId } = useParams();
    let [isLoading, setIsLoading] = useState(true);
    let [htmlBody, setHtmlBody] = useState('');
    let [menuItems, setMenuItems] = useState([]);
    let menuItemsRef = useRef([]);

    const formatMenu = useCallback((products, path) => {
        if (products && products.length > 0)
            return products.map((item) => {
                let urlPath = path ? `${path}/${item.id}` : item.id;
                return {
                    id: item.id,
                    title: item.name,
                    path: `/Docs/${urlPath}`,
                    subItems: formatMenu(item.children, urlPath),
                    isActive: false,
                };
            });
        return [];
    }, []);

    const buildDocsUrl = useCallback(() => {
        return [productId, categoryId, articleId].filter((d) => d).join('/');
    }, [productId, articleId, categoryId]);

    const configureWebhookItems = useCallback((products) => {
        if (products && products.length > 0) {
            const webhookMenuItems = WebhooksMenu().webhooksMenu;
            return products.concat(webhookMenuItems);
        }
        return [];
    }, []);

    useEffect(() => {
        ApiDocsService.getProductDirectory().then((data) => {
            let products = data.products;
            if (!('errorMessage' in data)) {
                if (products.length > 0) {
                    const formattedMenuItems = formatMenu(products);
                    menuItemsRef.current = configureWebhookItems(formattedMenuItems);
                    setSelectedMenuItem(buildDocsUrl());
                }
                if (products.length > 0) {
                    const formattedMenuItems = formatMenu(products);
                    menuItemsRef.current = formattedMenuItems;
                    setSelectedMenuItem(buildDocsUrl());
                }
            }
        });
    }, [buildDocsUrl, formatMenu, configureWebhookItems]);
    // initialize mobile nav bar on page load.
    useEffect(initMobileNav, []);

    useEffect(() => {
        setIsLoading(true);
        let path = location.pathname.substr(6);
        ApiDocsService.getApiDocumentation(path)
            .then((data) => {
                setHtmlBody(data.htmlContent);
            })
            .finally(() => {
                setIsLoading(false);
                // Call initDocs function for additional jQuery functions to initialize documentation functionalities/markup from
                // he html markup that was generated from the server.
                initDocs();
            });
    }, [location.pathname]);

    function setSelectedMenuItem(path) {
        menuItemsRef.current.forEach((product) => {
            // Setting the main product category to active if that first part of the pathname matches,
            // this is so that the toggle dropdown remains open when entering the new page url
            const productPath = product.path.substr(6);
            product.isActive = productPath === path.substr(0, productPath.length);
            product.subItems.forEach((category) => {
                category.subItems.forEach((article) => {
                    article.isActive = article.path === path;
                });
            });
        });
        setMenuItems(menuItemsRef.current);
        return menuItemsRef;
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row flex-xl-nowrap">
                    <Fragment>
                        <div className="sidebar-column col-md-3 col-xl-2 p-0 d-none d-md-block">
                            <Sidebar menuItems={menuItems} />
                        </div>
                    </Fragment>
                    {isLoading ? (
                        <LoadingSpinner loadingText="Loading..." />
                    ) : (
                        <main className="offset-md-3 col-md-9 offset-xl-2 col-xl-10 content">
                            {htmlParser(htmlBody)}
                        </main>
                    )}
                </div>
            </div>
        </Fragment>
    );
};
export default ApiDocumentationDetail;
