import * as React from 'react';
import { Fragment } from 'react';
//import { NavLink } from 'react-router-dom';
//import { ReactComponent as AppIcon } from '../../content/icons/Icon-Apps.svg';
//import { ReactComponent as OrganisationIcon } from '../../content/icons/Icon-Organisations.svg';
//import { ReactComponent as DiaryManagementIcon } from '../../content/icons/Icon-Diary-Management.svg';
//import { ReactComponent as SubscriptionIcon } from '../../content/icons/Icon-Subscription.svg';
//import { ReactComponent as UserManagement } from '../../content/icons/Icon-User-Management.svg';
//import config from '../../config/app';
import { FormattedMessage } from 'react-intl';

function AdminLandingPage(): JSX.Element {
    return (
        <Fragment>
            <div className="landing-page">
                <div className="header-container">
                    <div className="header-bg ">
                        <div className="landing-header">
                            <h1 className="header-title__light-header">
                                <FormattedMessage id="Layout.LandingPage.Welcome" />
                            </h1>
                            <h1 className="header-title__header-2">
                                <FormattedMessage id="Layout.LandingPage.ResdiaryAdminHub" />
                            </h1>
                            <h3 className="mt-4 text-white">
                                <FormattedMessage id="Layout.LandingPage.ResdiaryAdminHubDescription" />
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="landing-content">
                    {/*<div className="row">*/}
                    {/*    <div className="col-md-6">*/}
                    {/*        <NavLink to={config.adminAppsPath}>*/}
                    {/*            <div className="lg-nav-button">*/}
                    {/*                <AppIcon />*/}
                    {/*                <div className="nav-description">*/}
                    {/*                    <h4>*/}
                    {/*                        <FormattedMessage id="Layout.LandingPage.ManageApps" />*/}
                    {/*                    </h4>*/}
                    {/*                    <p>*/}
                    {/*                        <FormattedMessage id="Layout.LandingPage.ManageAppsDescriptionAdmin" />*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </NavLink>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-6">*/}
                    {/*        <NavLink to={config.adminOrganisationsPath}>*/}
                    {/*            <div className="lg-nav-button">*/}
                    {/*                <OrganisationIcon />*/}
                    {/*                <div className="nav-description">*/}
                    {/*                    <h4>*/}
                    {/*                        <FormattedMessage id="Layout.LandingPage.Organisations" />*/}
                    {/*                    </h4>*/}
                    {/*                    <p>*/}
                    {/*                        <FormattedMessage id="Layout.LandingPage.OrganisationsDescriptionAdmin" />*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </NavLink>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row mb-5">*/}
                    {/*    <div className="col-md-6">*/}
                    {/*        <NavLink to={config.adminTestDiariesPath}>*/}
                    {/*            <div className="lg-nav-button">*/}
                    {/*                <DiaryManagementIcon />*/}
                    {/*                <div className="nav-description">*/}
                    {/*                    <h4>*/}
                    {/*                        <FormattedMessage id="Layout.LandingPage.DiariesManagement" />*/}
                    {/*                    </h4>*/}
                    {/*                    <p>*/}
                    {/*                        <FormattedMessage id="Layout.LandingPage.DiariesManagementDescription" />*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </NavLink>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-6">*/}
                    {/*        <NavLink to={config.adminSubscriptionsPath}>*/}
                    {/*            <div className="lg-nav-button">*/}
                    {/*                <SubscriptionIcon />*/}
                    {/*                <div className="nav-description">*/}
                    {/*                    <h4>*/}
                    {/*                        <FormattedMessage id="Layout.LandingPage.Subscription" />*/}
                    {/*                    </h4>*/}
                    {/*                    <p>*/}
                    {/*                        <FormattedMessage id="Layout.LandingPage.SubscriptionDescriptionAdmin" />*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </NavLink>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-md-4">*/}
                    {/*        <NavLink to={config.adminAuditPath}>*/}
                    {/*            <div className="lg-admin-shortcut">*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-md-2">*/}
                    {/*                        <UserManagement />*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-8">*/}
                    {/*                        <h5>*/}
                    {/*                            <FormattedMessage id="Layout.LandingPage.AuditTrails" />*/}
                    {/*                        </h5>*/}
                    {/*                        <p>*/}
                    {/*                            <FormattedMessage id="Layout.LandingPage.AuditTrailsDescription" />*/}
                    {/*                        </p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </NavLink>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-4">*/}
                    {/*        <NavLink to={config.adminUsersPath}>*/}
                    {/*            <div className="lg-admin-shortcut">*/}
                    {/*                <div className="row">*/}
                    {/*                    <div className="col-md-2">*/}
                    {/*                        <UserManagement />*/}
                    {/*                    </div>*/}
                    {/*                    <div className="col-md-8">*/}
                    {/*                        <h5>*/}
                    {/*                            <FormattedMessage id="Layout.LandingPage.UserManagement" />*/}
                    {/*                        </h5>*/}
                    {/*                        <p>*/}
                    {/*                            <FormattedMessage id="Layout.LandingPage.UserManagementDescription" />*/}
                    {/*                        </p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </NavLink>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </Fragment>
    );
}
export default AdminLandingPage;
