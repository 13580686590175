import React, { useState, useEffect, useCallback } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import useValidation from '../../hooks/useValidation';
import RolesService from '../../services/RolesService';
import { useOrganisation } from '../../contexts/OrganisationContext';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import { ValidationInput } from '..';
import PermissionsService from '../../services/PermissionsService';
import AssignPermissions from './AssignPermissions';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import AssignRoles from './AssignRoles';
function CreateEditRole({ isEdit, selectedRoleData, goBackToRolesTable, submit, teamsData }) {
    const { authInfo } = useAuthentication();
    const { defaultOrganisation } = useOrganisation();
    const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState(true);
    const [teamMembers, setTeamMembers] = useState(teamsData);
    const [permissions, setPermissions] = useState([]);
    const rolesForm = useValidation();
    const [roleData, setRoleData] = useState(selectedRoleData);
    const intl = useIntl();

    useEffect(() => {
        setTeamMembers(teamsData);
        setIsLoadingTeamMembers(false);
    }, [teamsData]);

    const buildTableFromPermissions = useCallback((permissions) => {
        if (typeof permissions !== 'undefined') {
            // Todo: Shape this data in the back end perhaps?
            const accData = [];
            const apiData = [];
            const configData = [];
            permissions.forEach((p) => {
                switch (p.name) {
                    case 'InvitingTeamMembers':
                        if (accData.filter((x) => x.name === p.name).length <= 0) {
                            accData.tableName = 'Account';
                            accData.push(p);
                        }
                        break;
                    case 'AccessApiCredentials':
                    case 'ViewLogs':
                        if (apiData.filter((x) => x.name === p.name).length <= 0) {
                            apiData.tableName = 'API';
                            apiData.push(p);
                        }
                        break;
                    case 'CreateTestDiary':
                    case 'LinkTestDiary':
                    case 'DeleteTestDiary':
                        if (configData.filter((x) => x.name === p.name).length <= 0) {
                            configData.tableName = 'Configuration';
                            configData.push(p);
                        }
                        break;
                    default:
                        break;
                }
            });

            return [accData, apiData, configData];
        }
    }, []);

    useEffect(() => {
        PermissionsService.getPermissions(selectedRoleData.organisationId, selectedRoleData.roleName).then(
            (response) => {
                let tableData = buildTableFromPermissions(response);
                setPermissions(tableData);
            }
        );
    }, [buildTableFromPermissions, selectedRoleData.organisationId, selectedRoleData.roleName]);

    const goBack = () => {
        goBackToRolesTable();
    };

    const submitRole = () => {
        function getUpdatedPermissions() {
            const allPermissions = [];
            permissions.forEach((permissionsGroup) => {
                permissionsGroup.forEach((p) => {
                    allPermissions.push(p);
                });
            });
            return allPermissions;
        }

        const updatedPermissions = getUpdatedPermissions();
        if (!isEdit) {
            RolesService.addRole(
                authInfo.username,
                roleData.roleName,
                defaultOrganisation.id,
                roleData.roleDescription,
                teamMembers,
                updatedPermissions
            ).then((response) => {
                if (!response.errorMessage) {
                    submit(response, true);
                } else {
                    submit(response, false);
                }
            });
        } else {
            roleData.assignedMembers = teamMembers;
            roleData.permissions = getUpdatedPermissions();
            RolesService.editRole(roleData).then((response) => {
                if (!response.errorMessage) {
                    submit(roleData, true);
                } else {
                    submit(response, false);
                }
            });
        }
    };

    const renderLoadingSafeSubmitButton = () => {
        // Todo: Should the reliance on the team member list be removed?
        // The back-end call uses the list of team members for this update
        // which feels unnecessary for us to supply.

        // Since editing a role here only allows changing the permissions
        // should we look up the team members on the back-end instead?
        const buttonType = isLoadingTeamMembers ? 'button' : 'submit';
        return (
            <button type={buttonType} className="btn btn-primary">
                {intl.formatMessage({ id: 'Common.Save' })}
            </button>
        );
    };

    const editCreateRole = () => {
        const title = isEdit ? 'Edit a role' : 'Create a role';
        return (
            <div>
                <h2 className="mb-4 col-12"> {title} </h2>
                <div className="col-6">
                    <Form onSubmit={rolesForm.submit(submitRole)}>
                        <FormGroup>
                            <div className="d-flex">
                                <Label for="roleName">Name</Label>
                            </div>
                            <ValidationInput
                                testId="roleName"
                                type="text"
                                name="roleName"
                                value={roleData.roleName}
                                onChange={(e) => setRoleData({ ...roleData, roleName: e.currentTarget.value })}
                                innerRef={rolesForm.register({
                                    required: 'A role name is required',
                                })}
                                errors={rolesForm.errors}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div className="d-flex">
                                <Label for="roleDescription">Description - optional</Label>
                            </div>
                            <ValidationInput
                                testId="roleDescription"
                                type="text"
                                name="roleDescription"
                                value={roleData.roleDescription}
                                onChange={(e) => setRoleData({ ...roleData, roleDescription: e.currentTarget.value })}
                                errors={rolesForm.errors}
                            />
                        </FormGroup>
                        <h3 className="font-weight-light mt-5">Permissions</h3>
                        <AssignPermissions
                            data={permissions}
                            updatePermissions={(e) => {
                                const flattenedPermissions = [].concat(...e);
                                setPermissions(buildTableFromPermissions(flattenedPermissions));
                            }}
                        />
                        {!isEdit && (
                            <h3 className="font-weight-light mt-5">Assign this role to the selected team member(s)</h3>
                        )}
                        {!isEdit && (
                            <AssignRoles
                                rData={teamMembers}
                                isLoading={isLoadingTeamMembers}
                                updateRoles={(e) => setTeamMembers(e)}
                            />
                        )}
                        <div className="row">
                            <FormGroup className="col-6">
                                {renderLoadingSafeSubmitButton()}
                                <input
                                    type="button"
                                    value="Cancel"
                                    className="btn btn-outline-primary mx-1"
                                    onClick={() => goBack()}
                                />
                            </FormGroup>
                        </div>
                    </Form>
                </div>
            </div>
        );
    };
    return <div>{editCreateRole()}</div>;
}

CreateEditRole.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    selectedRoleData: PropTypes.object,
    goBackToRolesTable: PropTypes.func,
    submit: PropTypes.func,
    teamsData: PropTypes.array,
};

export default CreateEditRole;
