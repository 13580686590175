import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import TextFieldWIthCopyToClipboard from './TextFieldWIthCopyToClipboard';

const InstallUrlCard = (props: InstallUrlProps) => {
    const intl = useIntl();
    return (
        <div className="oauth-card">
            <h5 className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.InstallUrl" />
            </h5>
            <p className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.InstallUrlDescription" />
            </p>
            <TextFieldWIthCopyToClipboard
                id="installUrl"
                register={props.register}
                errors={props.errors}
                placeholder={intl.formatMessage({
                    id: 'AppManagement.AppDetails.InstallUrlPlaceholder',
                })}
                getValue={props.getValue}
            />
        </div>
    );
};

export interface InstallUrlProps {
    errors?: FieldError;
    register?: UseFormRegisterReturn;
    getValue: () => string;
}

export default InstallUrlCard;
