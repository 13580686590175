/* eslint-disable */
import { api } from '../helpers/ApiHelper';
import ProductDirectory from '../model/apiDocs/ProductDirectory';
import RenderingResult from '../model/apiDocs/RenderingResult';
import ErrorMessage from '../model/services/ErrorMessage';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const parametersForApiCallExamples = async () => {
    const url = 'api-call-examples/parameters';
    return await api.get<Object[], string>(url).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const getApiDocumentation = async (path: string) => {
    let url = `Products/${path}`;
    return await api.get<RenderingResult, string>(url).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const getProductDirectory = async () => {
    let url = `ProductDirectory`;
    return await api.get<ProductDirectory, string>(url).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

export default {
    getApiDocumentation,
    getProductDirectory,
    parametersForApiCallExamples,
};
