import * as React from 'react';
import ProfileCircle from '../common/ProfileCircle';
import MomentHelper from '../../helpers/MomentHelper';
import { useNavigate } from 'react-router-dom';
import AppImageContainer from './AppImageContainer';
import App from '../../model/appManagement/App';
import { useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import AppSubmissionStatusTag from './AppSubmissionStatusTag';
import AppApiTypeTag from './AppApiTypeTag';
import appSubmissionStatus from '../../enums/appSubmissionStatus';

const AppManagementCard = ({ app }: AppManagementCardProps) => {
    const navigate = useNavigate();
    const intl = useIntl();
    const getCreatedByDate = () => {
        const formattedDate = MomentHelper.newInstance(app.createdDate).format('ll');
        return (
            <span className="mr-2">
                <b>{`${intl.formatMessage({ id: 'Common.Created' })}`}&#58;</b>&nbsp;{`${formattedDate}`}
            </span>
        );
    };

    const showSettingsNotConfiguredWarning = () => {
        if (
            !app.isSettingsConfigured ||
            (app.submissionStatus.toLowerCase() === appSubmissionStatus.approved.toLowerCase() && !app.hasLiveSecret)
        ) {
            return (
                <Grid item>
                    <Tooltip
                        title={intl.formatMessage({
                            id: 'AppManagement.AppDetails.SettingsNotConfiguredMessage',
                        })}
                        arrow
                        placement="top"
                    >
                        <span>
                            <WarningIcon color="warning"></WarningIcon>
                        </span>
                    </Tooltip>
                </Grid>
            );
        }
    };

    return (
        <div className="app-management-card" onClick={() => navigate(`/Apps/${app.id}`)}>
            <div className="d-flex flex-row p-3 align-items-center created-by">
                <div>
                    {getCreatedByDate()}
                    <AppSubmissionStatusTag submissionStatus={app.submissionStatus} />
                </div>
                <div>
                    <ProfileCircle fullName={app.createdByUser} />
                </div>
            </div>
            <div className="icon-container justify-content-center d-flex">
                <AppImageContainer appId={app.id} />
            </div>
            <Grid spacing={1} sx={{ m: 1 }} container justifyContent="space-between">
                <Grid item>
                    <h4>{app.title}</h4>
                </Grid>
                <Grid spacing={1} xs={12} item container>
                    <Grid item>
                        <AppApiTypeTag appApiType={app!.apiType} />
                    </Grid>
                    {showSettingsNotConfiguredWarning()}
                </Grid>
            </Grid>
        </div>
    );
};

export interface AppManagementCardProps {
    app: App;
}

export default AppManagementCard;
