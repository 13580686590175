import * as React from 'react';
import { useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// TODO: Add property for notification types when designs are available (Success, Error, Warning, Info)
interface INotification {
    message: string;
}

interface INotificationProviderProps {
    children: React.ReactNode;
}

interface INotificationContext {
    isOpen: boolean;
    notification: INotification | undefined;
    displayNotification: (notification: INotification) => void;
    clearNotification: () => void;
}

const NotificationContext = React.createContext<INotificationContext>({
    isOpen: false,
    notification: undefined,
    displayNotification: (_: INotification) => {},
    clearNotification: () => {},
});

export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }: INotificationProviderProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [notification, setNotification] = useState<INotification | undefined>();

    const getUniqueKey = () => new Date().getTime() + Math.random();

    const displayNotification = (newNotification: INotification) => {
        setNotification(newNotification);
        setIsOpen(true);
    };

    const clearNotification = () => {
        setNotification(undefined);
        setIsOpen(false);
    };

    const value: INotificationContext = {
        isOpen,
        notification,
        displayNotification,
        clearNotification,
    };

    // TODO: Apply styles for notification types when designs are available (Success, Error, Warning, Info)
    return (
        <NotificationContext.Provider value={value}>
            {children}
            <Snackbar
                key={getUniqueKey()}
                open={isOpen}
                autoHideDuration={6000}
                onClose={clearNotification}
                message={notification?.message}
                action={
                    <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={clearNotification}>
                        <CloseIcon />
                    </IconButton>
                }
            />
        </NotificationContext.Provider>
    );
};
