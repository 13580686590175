import * as React from 'react';
import { Fragment, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputProps } from 'reactstrap';
import ValidationMessage from './ValidationMessage';
import TogglePasswordVisibility from './TogglePasswordVisibility';
import { FieldErrors } from 'react-hook-form-6';

const ValidationInput = (props: ValidationInputProps) => {
    const [inputType, setInputType] = useState(props.type || 'text');

    const getClassNamesString = () => {
        if (props.errors && props.errors[props.name]) {
            if (props.className) return `is-invalid ${props.className}`;
            else return 'is-invalid';
        } else {
            if (props.className) return props.className;
            else return undefined;
        }
    };

    const getGroupClassNameString = () => {
        if (props.type === 'checkbox') {
            return 'input-group-checkbox has-validation';
        } else {
            return 'has-validation';
        }
    };

    const getInputLengthClass = () => {
        return props.value && typeof props.value !== 'number' && props.value.length === props.maxLength
            ? 'validation-input-count warning'
            : 'validation-input-count';
    };

    return (
        <Fragment>
            <InputGroup className={getGroupClassNameString()}>
                <Input
                    className={getClassNamesString()}
                    defaultValue={props.value}
                    onInput={props.onChange}
                    data-testid={props.testId}
                    innerRef={props.innerRef}
                    name={props.name}
                    type={inputType}
                    placeholder={props.placeholder}
                    rows={props.rows}
                    maxLength={props.maxLength}
                    autoFocus={props.autoFocus}
                    disabled={props.disabled}
                    autoComplete={props.autoComplete}
                    tabIndex={props.tabIndex}
                />
                {props.showPasswordToggle && props.type === 'password' && props.value && (
                    <InputGroupAddon addonType="append" className="password-toggle">
                        <TogglePasswordVisibility
                            className="border-left-0"
                            inputType={inputType}
                            togglePassword={setInputType}
                        />
                    </InputGroupAddon>
                )}
            </InputGroup>
            {!props.hideValidationMessage && (
                <div className="validation-input-feedback">
                    {props.errors && props.errors[props.name] && props.errors[props.name].message.length > 0 && (
                        <ValidationMessage message={props.errors[props.name].message} />
                    )}
                    {props.displayCharacterCount && (
                        <div className={`input-character-count ${getInputLengthClass()}`}>
                            {typeof props.value !== 'number' ? props.value?.length : 0}/{props.maxLength}
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export interface ValidationInputProps extends InputProps {
    name: string;
    errors?: FieldErrors;
    className?: string;
    testId?: string;
}

export default ValidationInput;
