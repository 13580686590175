import * as React from 'react';
import { useState } from 'react';
import config from '../../config/app';
import { Alert, Container, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import ValidationInput from '../common/ValidationInput';
import useValidation from '../../hooks/useValidation';
import AccountService from '../../services/AccountService';
import ProgressPage from '../common/ProgressPage';
import ProgressBar from '../common/ProgressBar';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import { useIntl, FormattedMessage } from 'react-intl';
import useTrackPage from '../../hooks/useTrackPage';
import ValidationMessage from '../common/ValidationMessage';
import { Button } from '@mui/material';

function CreateAccountPage(): JSX.Element {
    const intl = useIntl();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [organisation, setOrganisation] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [createAccountError, setCreateAccountError] = useState('');
    const [tAndCCheckbox, setTAndCCheckbox] = useState(false);

    const { register, errors, submitDisabled, submit, errorCount } = useValidation();

    useTrackPage('Hub Account Creation page');

    const trackAccountCreation = (firstName: string, lastName: string, email: string, organisation: string) => {
        const userProperties = { firstName: firstName, lastName: lastName, email: email, organisation: organisation };
        AnalyticsHelper.identifyUserWithProperties({ email: email });
        AnalyticsHelper.trackClickWithProperties('Web Hub AcctCreate', userProperties);
    };

    const submitCreateAccount = (): void => {
        AccountService.register(firstName, lastName, emailAddress, organisation, password)
            .then((response) => {
                if ('errorMessage' in response) {
                    setCreateAccountError(response.errorMessage);
                } else {
                    trackAccountCreation(firstName, lastName, emailAddress, organisation);
                    navigate(config.verifyAccountPath, { state: { email: response.email } });
                }
            })
            .catch((err) => {
                setCreateAccountError(err);
            });
    };

    const validatePasswordMatch = (value: string): boolean | string => {
        if (value !== password) {
            return intl.formatMessage({ id: 'Profile.CreateAccountPage.PasswordsMustMatch' });
        } else {
            return true;
        }
    };

    const navigateToLoginPage = (): void => {
        AnalyticsHelper.trackClick('Web Hub AccountCreation SignIn');
        navigate(config.loginPath);
    };

    const renderCreateAccountForm = (): JSX.Element => {
        return (
            <Form className="account-form--fixed-width" onSubmit={submit(submitCreateAccount)}>
                <div className="account-form__title">
                    <FormattedMessage id="Profile.CreateAccountPage.CreateAnAccountHeader" />
                </div>
                <div className="account-form__content">
                    {renderError()}
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="d-flex">
                                    <Label for="firstName">
                                        <FormattedMessage id="Profile.CreateAccountPage.FirstName" />
                                    </Label>
                                </div>
                                <ValidationInput
                                    testId="firstName"
                                    type="text"
                                    name="firstName"
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setFirstName(e.currentTarget.value)
                                    }
                                    innerRef={register({
                                        required: intl.formatMessage({
                                            id: 'Profile.CreateAccountPage.FirstNameRequired',
                                        }),
                                    })}
                                    errors={errors}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <div className="d-flex">
                                    <Label for="lastName">
                                        <FormattedMessage id="Profile.CreateAccountPage.LastName" />{' '}
                                    </Label>
                                </div>
                                <ValidationInput
                                    testId="lastName"
                                    type="text"
                                    name="lastName"
                                    placeholder="Last name"
                                    value={lastName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setLastName(e.currentTarget.value)
                                    }
                                    innerRef={register({
                                        required: intl.formatMessage({
                                            id: 'Profile.CreateAccountPage.LastNameRequired',
                                        }),
                                    })}
                                    errors={errors}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="email">
                                <FormattedMessage id="Profile.CreateAccountPage.EmailAddress" />
                            </Label>
                        </div>
                        <ValidationInput
                            testId="emailAddress"
                            type="email"
                            name="emailAddress"
                            placeholder="youremail@domain.com"
                            value={emailAddress}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setEmailAddress(e.currentTarget.value)
                            }
                            innerRef={register({
                                required: intl.formatMessage({
                                    id: 'Profile.CreateAccountPage.EmailRequired',
                                }),
                            })}
                            errors={errors}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="lastName">
                                <FormattedMessage id="Profile.CreateAccountPage.Organisation" />
                            </Label>
                        </div>
                        <ValidationInput
                            testId="organisation"
                            type="text"
                            name="organisation"
                            placeholder="Organisation name"
                            value={organisation}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setOrganisation(e.currentTarget.value)
                            }
                            innerRef={register({
                                required: intl.formatMessage({
                                    id: 'Profile.CreateAccountPage.OrganisationNameRequired',
                                }),
                            })}
                            errors={errors}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="password">
                                <FormattedMessage id="Profile.CreateAccountPage.Password" />
                            </Label>
                        </div>
                        <ValidationInput
                            testId="password"
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
                            innerRef={register({
                                required: intl.formatMessage({
                                    id: 'Profile.CreateAccountPage.PasswordRequired',
                                }),
                            })}
                            errors={errors}
                            showPasswordToggle={true}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="confirmPassword">
                                <FormattedMessage id="Profile.CreateAccountPage.ConfirmPassword" />
                            </Label>
                        </div>
                        <ValidationInput
                            testId="confirmPassword"
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm password"
                            value={confirmPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setConfirmPassword(e.currentTarget.value)
                            }
                            innerRef={register({
                                required: intl.formatMessage({
                                    id: 'Profile.CreateAccountPage.PasswordRequired',
                                }),
                                validate: (value) => validatePasswordMatch(value),
                            })}
                            errors={errors}
                            showPasswordToggle={true}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="d-flex">
                            <ValidationInput
                                testId="terms-and-privacy-checkbox"
                                name="terms-and-privacy-checkbox"
                                type="checkbox"
                                onChange={() => setTAndCCheckbox(true)}
                                value={tAndCCheckbox.toString()}
                                innerRef={register({
                                    required: intl.formatMessage({
                                        id: 'Profile.CreateAccountPage.ToContinueTermsAndConditions',
                                    }),
                                })}
                            />
                            <div>
                                <span>
                                    <FormattedMessage id="Profile.CreateAccountPage.TermsAndConditionsDescription" />
                                </span>
                                <a href="www.google.com">
                                    &nbsp;
                                    <FormattedMessage id="Profile.CreateAccountPage.TermsAndConditionsLink" />
                                </a>
                            </div>
                        </div>
                        {errorCount > 0 && errors['terms-and-privacy-checkbox'] && (
                            <div className="validation-input-feedback">
                                <ValidationMessage message={errors['terms-and-privacy-checkbox'].message} />
                            </div>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <div className="d-flex">
                            <p>
                                <FormattedMessage id="Profile.CreateAccountPage.PrivacyNoticeDescription" />
                                <a href="www.google.com">
                                    &nbsp;
                                    <FormattedMessage id="Profile.CreateAccountPage.PrivacyNoticeLink" />
                                </a>
                            </p>
                        </div>
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitDisabled}
                        variant="contained"
                        className="col submit"
                        data-testid="create-account-form-submit-button"
                    >
                        <FormattedMessage id="Common.CreateAccount" />
                    </Button>
                    <FormGroup>
                        <div className="d-flex align-items-center justify-content-center">
                            <FormattedMessage id="Login.LoginPage.HaveAnAccount" />
                            <button className="btn btn-link p-0 pl-1" onClick={() => navigateToLoginPage()}>
                                <FormattedMessage id="Common.LogIn" />
                            </button>
                        </div>
                    </FormGroup>
                </div>
            </Form>
        );
    };

    const renderError = (): JSX.Element | undefined => {
        if (createAccountError) {
            return (
                <Alert className="account-form__error">
                    <img src="/images/Alert-white.svg" alt="tick" />
                    {createAccountError}
                </Alert>
            );
        }
    };

    const progressBar = (): JSX.Element => {
        return (
            <ProgressPage
                pageTitle={intl.formatMessage({ id: 'Common.GetStartedMessage' })}
                progressBar={
                    <ProgressBar
                        createAccountStatus={'incomplete'}
                        organisationSetupStatus={'notStarted'}
                        accountDetailsStatus={'notStarted'}
                    />
                }
            />
        );
    };

    return (
        <Container className="account-page">
            <div className="flex-container-center">
                <div className="container">
                    {progressBar()}
                    {renderCreateAccountForm()}
                </div>
            </div>
        </Container>
    );
}

export default CreateAccountPage;
