import React, { Fragment } from 'react';
import { useNavigate } from 'react-router';

function PageNotFound(props) {
    const navigate = useNavigate();

    function handleRedirectToHomePage() {
        navigate('/');
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row flex-xl-nowrap">
                    <main className="col content">
                        <div className="error-page error-page--404 col offset-md-2 col-md-8 offset-xl-2 col-xl-8">
                            <img src="/images/Error-404.svg" alt="tick" />
                            <div className="error-page__status-code">
                                <span>ERROR 404</span>
                            </div>
                            <div className="error-page__title">The page you're looking for doesn't exist.</div>
                            <div className="error-page__description">
                                You may have mistyped the address or the page may have moved.
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btn btn-primary float-left" onClick={handleRedirectToHomePage}>
                                        Return to homepage
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </Fragment>
    );
}

export default PageNotFound;
