import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ConfirmedIcon } from '../../content/icons/Icon-Confirmed.svg';
import { ReactComponent as AlertIcon } from '../../content/icons/Icon-Alert.svg';

function Notification({ alertType, title, message1, message2 }) {
    function getAlertType() {
        switch (alertType) {
            case 'ConfirmedIcon':
                return <ConfirmedIcon />;
            case 'AlertIcon':
                return <AlertIcon />;
            default:
                return <AlertIcon />;
        }
    }

    return (
        <Fragment>
            <div className="header-icon header-alert">{getAlertType()}</div>
            <div className="account-form__title m-4">{title}</div>
            <div className="m-3 p-0 text-center">
                <p dangerouslySetInnerHTML={{ __html: message1 }}></p>
                <p dangerouslySetInnerHTML={{ __html: message2 }}></p>
            </div>
        </Fragment>
    );
}

Notification.propTypes = {
    alertType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message1: PropTypes.string.isRequired,
    message2: PropTypes.string,
};

export default Notification;
