import appNavigationTabs from '../../enums/appNavigationTabs';

function AppNavigationTabLabels() {
    return [
        {
            key: appNavigationTabs.getStarted,
            name: 'GET STARTED',
        },
        {
            key: appNavigationTabs.configure,
            name: 'Configure',
            isActive: true,
            subItems: [
                {
                    key: appNavigationTabs.basicInformation,
                    name: 'Basic Information',
                },
                {
                    key: appNavigationTabs.authentication,
                    name: 'Authentication',
                },
                {
                    key: appNavigationTabs.certification,
                    name: 'Certifications',
                },
                {
                    key: appNavigationTabs.webhookConfiguration,
                    name: 'Webhooks Configuration',
                },
            ],
        },
        {
            key: appNavigationTabs.testDiary,
            name: 'TEST DIARY',
        },
        {
            key: appNavigationTabs.submitApp,
            name: 'SUBMIT APP',
        },
        {
            key: appNavigationTabs.logs,
            name: 'LOGS',
            isActive: true,
            subItems: [
                {
                    key: appNavigationTabs.webhookLogs,
                    name: 'Webhook logs',
                },
                {
                    key: appNavigationTabs.apiLogs,
                    name: 'API logs',
                },
            ],
        },
        {
            key: appNavigationTabs.installations,
            name: 'INSTALLATIONS',
        },
    ];
}

export default AppNavigationTabLabels;
