import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DataTable from '../common/DataTable';
import ButtonBar from '../common/ButtonBar';
import { FormGroup, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import DateTimePicker from '../../components/common/DateTimePicker';

const responseStatusFilterType = {
    all: null,
    success: 'success',
    failure: 'failure',
};

const LogTable = ({
    columns,
    data,
    isLoading,
    nextPageUrl,
    handleLoadMoreClick,
    filter,
    setFilter,
    tableName,
    expandRow,
}) => {
    const intl = useIntl();
    const [minDateTimeTo, setMinDateTimeTo] = useState();

    filter = filter || {};

    const setResponseStatus = (value) => {
        setFilter({
            ...filter,
            responseStatus: value,
        });
    };

    const handleDateTimeOnChange = (fieldName, value) => {
        setFilter({
            ...filter,
            [fieldName]: value ? value.toISOString() : null,
        });
    };

    const Filters = useMemo(() => {
        const environmentTypes = [
            {
                index: 'all',
                value: responseStatusFilterType.all,
                isActive: filter.responseStatus === responseStatusFilterType.all,
                label: intl.formatMessage({ id: 'AppManagement.LogTable.All' }),
            },
            {
                index: 'success',
                value: responseStatusFilterType.success,
                isActive: filter.responseStatus === responseStatusFilterType.success,
                label: intl.formatMessage({ id: 'AppManagement.LogTable.Success' }),
            },
            {
                index: 'failure',
                value: responseStatusFilterType.failure,
                isActive: filter.responseStatus === responseStatusFilterType.failure,
                label: intl.formatMessage({ id: 'AppManagement.LogTable.Failure' }),
            },
        ];

        return (
            <div className="row">
                <div className="log-list__filters col-md-5">
                    <FormGroup>
                        <Label>
                            <h6>
                                <FormattedMessage id="AppManagement.LogTable.FilterBy" />
                            </h6>
                        </Label>
                        <ButtonBar items={environmentTypes} setValue={setResponseStatus} />
                    </FormGroup>
                </div>
                <div className="log-list__filters col-md-7">
                    <FormGroup className="float-right">
                        <DateTimePicker
                            ampm={false}
                            className="pr-2"
                            size="small"
                            label="Start date"
                            format="YYYY-MM-DD HH:mm:ss"
                            onAccept={(value) => {
                                setMinDateTimeTo(value);
                                handleDateTimeOnChange('dateTimeFrom', value);
                            }}
                        />
                        <DateTimePicker
                            ampm={false}
                            minDateTime={minDateTimeTo}
                            skipDisabled={true}
                            name="date-to"
                            size="small"
                            label="End date"
                            format="YYYY-MM-DD HH:mm:ss"
                            onAccept={(value) => {
                                handleDateTimeOnChange('dateTimeTo', value);
                            }}
                        />
                    </FormGroup>
                </div>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
        <Fragment>
            {Filters}
            <DataTable
                tableName={tableName}
                columns={columns}
                data={data}
                isLoading={isLoading}
                nextPageUrl={nextPageUrl}
                handleLoadMoreClick={handleLoadMoreClick}
                expandRow={expandRow}
            />
        </Fragment>
    );
};

LogTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    handleLoadMoreClick: PropTypes.func,
    filter: PropTypes.object,
    setFilter: PropTypes.func,
    tableName: PropTypes.string,
    renderPagination: PropTypes.func,
    application: PropTypes.object,
};

export default LogTable;
