import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import TextFieldWIthCopyToClipboard from './TextFieldWIthCopyToClipboard';

const RedirectUrlCard = (props: RedirectUrlCardProps) => {
    const intl = useIntl();

    return (
        <div className="oauth-card">
            <h5 className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.RedirectUrl" />
            </h5>
            <p className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.RedirectUrlDescription" />
            </p>
            <p className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.RedirectUrlSubDescription" />
            </p>
            <TextFieldWIthCopyToClipboard
                id="redirectUrl"
                register={props.register}
                errors={props.errors}
                placeholder={intl.formatMessage({
                    id: 'AppManagement.AppDetails.RedirectUrlPlaceholder',
                })}
                getValue={props.getValue}
            />
        </div>
    );
};

export interface RedirectUrlCardProps {
    getValue: () => string;
    errors?: FieldError;
    register?: UseFormRegisterReturn;
}

export default RedirectUrlCard;
