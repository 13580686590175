/* eslint-disable */
const truncateText = (text, textLength) => {
    return text.length > textLength ? text.substring(0, textLength) + '...' : text;
};

const formatPascalText = (text) => {
    if (isNaN(text)) {
        //also works for acronymns
        text = text.replace(/([a-z])([A-Z])/g, '$1 $2');
        text = text.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
        return text;
    } else {
        return text;
    }
};

export default {
    truncateText,
    formatPascalText,
};
