import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, FormGroup, Label } from 'reactstrap';
import ValidationInput from '../common/ValidationInput';
import ProfileMenu from './ProfileMenuData';
import Sidebar from '../layout/Sidebar';
import InfoBarManager from '../common/InfoBarManager';
import { InfoBarProps } from '../common/InfoBar';
import OrganisationService from '../../services/OrganisationService';
import useValidation from '../../hooks/useValidation';
import { useOrganisation } from '../../contexts/OrganisationContext';
import infoBarType from '../../enums/infoBarType';
import ErrorMessage from '../../model/services/ErrorMessage';

const OrganisationSettingsPage = () => {
    const intl = useIntl();
    const { defaultOrganisation } = useOrganisation();
    const [infoBars, setInfoBars] = useState<InfoBarProps[]>([]);
    const profilesMenu = ProfileMenu();
    const [organisationName, setOrganisationName] = useState('');

    const organisationForm = useValidation();

    useEffect(() => {
        setOrganisationName(defaultOrganisation!.name);
    }, [defaultOrganisation]);

    // Error bars

    const addErrorBar = (message: string) => {
        setInfoBars((i) => [...i, { type: infoBarType.error, message: message }]);
    };

    const addSuccessBar = (message: string) => {
        setInfoBars((i) => [...i, { type: infoBarType.success, message: message }]);
    };

    // Submission functions

    const submitUpdateOrganisationName = () => {
        OrganisationService.updateOrganisationName(organisationName, defaultOrganisation!.id)
            .then((response) => {
                addSuccessBar(intl.formatMessage({ id: 'Profile.OrganisationSettingsPage.NameUpdateSuccess' }));
            })
            .catch((error: ErrorMessage) => {
                addErrorBar(
                    intl.formatMessage(
                        { id: 'Profile.OrganisationSettingsPage.NameUpdateUnsuccessful' },
                        { error: error.errorMessage }
                    )
                );
            });
    };

    // Render forms

    const renderUpdateOrganisationName = () => {
        return (
            <div>
                <Form onSubmit={organisationForm.submit(submitUpdateOrganisationName)}>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="organisationName">
                                <FormattedMessage id="Profile.OrganisationSettingsPage.OrgNameLabel" />
                            </Label>
                        </div>
                        <ValidationInput
                            testId="organisationName"
                            type="text"
                            name="organisationName"
                            value={organisationName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setOrganisationName(e.currentTarget.value)
                            }
                            errors={organisationForm.errors}
                        />
                    </FormGroup>
                    <FormGroup>
                        <input
                            type="submit"
                            value={intl.formatMessage({ id: 'Profile.OrganisationSettingsPage.OrgNameUpdateBtnLabel' })}
                            className="login-button btn btn-primary"
                            data-testid="update-organisation-name-form-submit-button"
                        />
                    </FormGroup>
                </Form>
            </div>
        );
    };

    return (
        <Fragment>
            <Fragment>
                <div className="container-fluid">
                    <InfoBarManager infoBarArray={infoBars} />
                    <div className="row flex-xl-nowrap">
                        <div className="sidebar-column col-md-3 col-xl-2 p-0 d-none d-md-block">
                            <Sidebar menuItems={profilesMenu} />
                        </div>
                        <main className="landing-content">
                            <div className="general-settings">
                                <h1>
                                    <FormattedMessage id="Profile.OrganisationSettingsPage.PageTitle" />
                                </h1>
                                <br></br>
                                <div>{renderUpdateOrganisationName()}</div>
                                <br></br>
                            </div>
                        </main>
                    </div>
                </div>
            </Fragment>
        </Fragment>
    );
};

export default OrganisationSettingsPage;
