import * as React from 'react';
import { useEffect, useState } from 'react';
import SearchInput from '../../../components/common/SearchInput';
/*import SelectList from '../../../components/common/SelectList';*/
import Paginator from '../../../components/common/Paginator';
import { Typography } from '@mui/material';
/*import { getPageSizes } from '../../../helpers/pagingHelper';*/
import TestDiaryTable from '../../../components/common/TestDiaryTable';
import TestDiaryService from '../../../services/admin/TestDiaryService';
import { useIntl, FormattedMessage } from 'react-intl';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import TestProvider from '../../../model/TestDiaries/TestProvider';
import { ReactComponent as SearchIcon } from '../../../content/icons/Search.svg';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import LinkTestDiaryModal from './LinkTestDiaryModal';
import { useNotification } from '../../../contexts/NotificationProvider';

function TestDiaryListPage() {
    const [pagedResult, setPagedResult] = useState<PagedResult>();
    const [isLoading, setIsLoading] = useState(true);
    const [searchFilter, setSearchFilter] = useState<string>();
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 50;
    const intl = useIntl();
    const [selectedTestDiary, setSelectedTestDiary] = useState<TestProvider>();
    const [isShowUnlinkModal, setIsShowUnlinkModal] = useState(false);
    const [isShowLinkModal, setIsShowLinkModal] = useState<boolean>(false);
    const [newTestProviderCount, setNewTestProviderCount] = useState(0);
    const { displayNotification } = useNotification();

    useEffect(() => {
        TestDiaryService.getTestDiaries({
            pageSize,
            pageNumber,
            searchFilter,
        })
            .then((response) => {
                if (!('errorMessage' in response)) {
                    let result = response;
                    setPagedResult({
                        data: result.data,
                        totalPages: result.totalPages,
                        pageNumber: result.pageNumber,
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [pageSize, searchFilter, pageNumber, newTestProviderCount]);

    //const handlepageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //    setPageSize(Number(e.target.value));
    //    setPageNumber(1);
    //};

    const handleSearchFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilter(e.target.value);
    };

    const handlePageChange = (e: number) => {
        setPageNumber(e);
    };
    const openUnlinkTestProviderModal = (testProvider: TestProvider) => {
        setSelectedTestDiary(testProvider);
        setIsShowUnlinkModal(true);
    };

    const showLinkTestProviderModal = () => {
        setIsShowLinkModal(true);
    };

    const renderEmptyResult = () => {
        return (
            <div className="col col-12 align-items-center text-center">
                <div className="text-center message-container">
                    <SearchIcon />
                    <h3 className="mt-3">
                        <FormattedMessage id="Admin.TestDiaries.NoDiary" />
                    </h3>
                    <p className="mt-2">
                        <FormattedMessage id="Admin.TestDiaries.LinkDiaryText" />
                    </p>
                </div>
            </div>
        );
    };

    const handleUnlinkTestDiary = () => {
        TestDiaryService.unlinkTestDiary(selectedTestDiary!.id)
            .then((response) => {
                if (response.status === 200) {
                    setPagedResult({
                        data: pagedResult!.data.filter(
                            (testDiary: TestProvider) => testDiary.id !== selectedTestDiary?.id
                        ),
                        totalPages: pagedResult!.totalPages,
                        pageNumber: pagedResult!.pageNumber,
                    });
                    setIsShowUnlinkModal(false);
                    displayNotification({
                        message: intl.formatMessage(
                            { id: 'Admin.TestDiaries.UnlinkToastMessage' },
                            { testDiary: selectedTestDiary?.name }
                        ),
                    });
                } else {
                    alert('error!');
                }
            })
            .finally(() => {
                // setIsLoading(false);
            });
    };

    const formattedUnlinkMessage = () => {
        return (
            <FormattedMessage
                id="Admin.TestDiaries.UnlinkDiaryMessage"
                values={{
                    b: (chunk) => <strong> {chunk}</strong>,
                    testDiary: `${selectedTestDiary?.name} (ID: ${selectedTestDiary?.remoteProviderId})`,
                    organisationName: selectedTestDiary?.organisationName,
                }}
            />
        );
    };

    const createDiaryModal = () => {
        if (isShowUnlinkModal) {
            return (
                <ConfirmationModal
                    header={intl.formatMessage({ id: 'Admin.TestDiaries.ConfirmUnlinkText' })}
                    isOpen={isShowUnlinkModal}
                    onClose={() => {
                        setIsShowUnlinkModal(false);
                    }}
                    onAccept={handleUnlinkTestDiary}
                    children={formattedUnlinkMessage()}
                    isHideCancel={false}
                    firstButtonText={intl.formatMessage({
                        id: 'Admin.TestDiaries.ConfirmUnlink',
                    })}
                />
            );
        }
    };

    const handleOnCloseLinkModal = () => {
        setIsShowLinkModal(false);
    };

    const handleOnSuccessLinkModalEvent = () => {
        setNewTestProviderCount(newTestProviderCount + 1);
        setIsShowLinkModal(false);
    };

    return (
        <div className="container-fluid">
            <div className="row flex-xl-nowrap">
                <main className="col col-12 content test-diaries-page">
                    <div className="header-section">
                        <div className="row">
                            <div className="titles">
                                <Typography variant="h4">
                                    <FormattedMessage id="Admin.TestDiaries.PageHeader" />
                                </Typography>
                                <Typography variant="body1" className="td-page__description mb-3">
                                    <FormattedMessage id="Admin.TestDiaries.DiaryAvailable" />
                                </Typography>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-6">
                                <SearchInput
                                    placeholder={intl.formatMessage({ id: 'Admin.TestDiaries.SearchPlaceholder' })}
                                    onChange={handleSearchFilterChange}
                                />
                            </div>
                            <div className="col-6">
                                <div className="float-right">
                                    {/*<SelectList*/}
                                    {/*    label={intl.formatMessage({ id: 'Admin.TestDiaries.PageSize' })}*/}
                                    {/*    options={getPageSizes()}*/}
                                    {/*    onChange={handlepageSizeChange}*/}
                                    {/*/>*/}
                                    <Button variant="contained" color="primary" onClick={showLinkTestProviderModal}>
                                        <AddIcon />
                                        <FormattedMessage id="Admin.TestDiaries.LinkDiaryButtonText" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col col-12 test-diaries-table">
                                <div className="table">
                                    {!isLoading && pagedResult?.data.length > 0 ? (
                                        <TestDiaryTable
                                            testDiaries={pagedResult?.data || []}
                                            openUnlinkTestProviderModal={openUnlinkTestProviderModal}
                                        />
                                    ) : (
                                        renderEmptyResult()
                                    )}
                                </div>
                                {(pagedResult?.totalPages ? pagedResult.totalPages : 0) > 1 && (
                                    <Paginator
                                        pages={pagedResult?.totalPages ? pagedResult.totalPages : 0}
                                        handlePaginate={handlePageChange}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {createDiaryModal()}
                    {isShowLinkModal && (
                        <LinkTestDiaryModal
                            isShowModal={isShowLinkModal}
                            onClose={handleOnCloseLinkModal}
                            onSuccess={handleOnSuccessLinkModalEvent}
                        />
                    )}
                </main>
            </div>
        </div>
    );
}

interface PagedResult {
    data: any;
    totalPages: number;
    pageNumber: number;
}

export default TestDiaryListPage;
