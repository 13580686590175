import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../layout/Sidebar';
import ProfileMenu from './ProfileMenuData';
import { Form, FormGroup, Label } from 'reactstrap';
import useValidation from '../../hooks/useValidation';
import { ValidationInput } from '../../components/';
import { passwordValidCheck, isMatching } from '../../helpers/passwordValidator';
import AccountService from '../../services/AccountService';
import infoBarType from '../../enums/infoBarType';
import InfoBarManager from '../common/InfoBarManager';
import { useAuthentication } from '../../contexts/AuthenticationContext';

function ProfileLandingPage() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [editableUserEmail, setEditableUserEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // eslint-disable-line
    const [infoBars, setInfoBars] = useState([]);

    const { authInfo } = useAuthentication();

    const profileMenu = ProfileMenu();

    const contactDetailsForm = useValidation();
    const passwordUpdateForm = useValidation();

    const validationRules = () => {
        passwordValidCheck(newPassword).then((result) => {
            if (result.isValid !== true) {
                return 'Password must contain 10 characters, an uppercase letter and a special character.';
            }
        });
    };

    const validateIsMatching = () => {
        const passwordMatches = isMatching(newPassword, confirmPassword);
        if (!passwordMatches) {
            return 'Passwords must match.';
        }
    };

    useEffect(() => {
        setUserEmail(authInfo?.username);
    }, [authInfo?.username]);

    useEffect(() => {
        setFirstName(authInfo?.firstName);
    }, [authInfo?.firstName]);

    useEffect(() => {
        setLastName(authInfo?.lastName);
    }, [authInfo?.lastName]);

    function addErrorBar(message) {
        setInfoBars((i) => [...i, { type: infoBarType.error, message: message }]);
    }

    function addSuccessBar(message) {
        setInfoBars((i) => [...i, { type: infoBarType.success, message: message }]);
    }

    function submitUpdateContactDetails() {
        AccountService.updateContactDetails(firstName, lastName, editableUserEmail, userEmail)
            .then((response) => {
                if (response.status !== 200) {
                    setErrorMessage(response.data);
                    addErrorBar('Contact details not updated, please try again');
                } else {
                    addSuccessBar('Contact details updated successfully');
                }
            })
            .catch((err) => {
                setErrorMessage(err);
            });
    }

    function submitUpdatePassword() {
        AccountService.updatePassword(currentPassword, newPassword, userEmail)
            .then((response) => {
                if (response.status !== 200) {
                    addErrorBar('Password not updated, please try again');
                } else {
                    addSuccessBar('Password updated successfully');
                }
            })
            .catch((err) => {
                setErrorMessage(err);
            });
    }

    const renderUpdateContactDetails = () => {
        return (
            <Form onSubmit={contactDetailsForm.submit(submitUpdateContactDetails)}>
                <div className="d-flex">
                    <FormGroup className="user-first-name">
                        <div className="d-flex">
                            <Label for="firstName">First name</Label>
                        </div>
                        <ValidationInput
                            testId="firstName"
                            type="text"
                            name="firstName"
                            value={firstName}
                            onChange={(e) => setFirstName(e)}
                            innerRef={contactDetailsForm.register({
                                required: 'First name is required',
                            })}
                            errors={contactDetailsForm.errors}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="lastName">Last name</Label>
                        </div>
                        <ValidationInput
                            testId="lastName"
                            type="text"
                            name="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e)}
                            innerRef={contactDetailsForm.register({
                                required: 'Last name is required',
                            })}
                            errors={contactDetailsForm.errors}
                        />
                    </FormGroup>
                </div>
                <FormGroup>
                    <div className="d-flex">
                        <Label for="email">Email address</Label>
                    </div>
                    <ValidationInput
                        testId="email"
                        type="email"
                        name="email"
                        value={editableUserEmail}
                        onChange={(e) => setEditableUserEmail(e)}
                        innerRef={contactDetailsForm.register({
                            required: 'Email is required',
                        })}
                        errors={contactDetailsForm.errors}
                    />
                </FormGroup>
                <FormGroup>
                    <input
                        type="submit"
                        value={'Update contact details'}
                        className="login-button btn btn-primary"
                        data-testid="create-account-form-submit-button"
                    />
                </FormGroup>
            </Form>
        );
    };

    const renderUpdatePassword = () => {
        return (
            <Form onSubmit={passwordUpdateForm.submit(submitUpdatePassword)}>
                <FormGroup>
                    <div className="d-flex">
                        <Label for="currentPassword">Current password</Label>
                    </div>
                    <ValidationInput
                        testId="currentPassword"
                        type="password"
                        value=""
                        name="currentPassword"
                        onChange={(e) => setCurrentPassword(e)}
                        innerRef={passwordUpdateForm.register({
                            required: 'Current password is required',
                        })}
                        errors={passwordUpdateForm.errors}
                    />
                </FormGroup>
                <div className="d-flex">
                    <FormGroup className="user-new-password">
                        <div className="d-flex">
                            <Label for="newPassword">New password</Label>
                        </div>
                        <ValidationInput
                            testId="newPassword"
                            type="password"
                            value=""
                            name="newPassword"
                            onChange={(e) => setNewPassword(e)}
                            innerRef={passwordUpdateForm.register({
                                validate: () => validationRules(),
                                required: 'New password is required',
                            })}
                            errors={passwordUpdateForm.errors}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="confirmPassword">Confirm new password</Label>
                        </div>
                        <ValidationInput
                            testId="confirmPassword"
                            type="password"
                            value=""
                            name="confirmPassword"
                            onChange={(e) => setConfirmPassword(e)}
                            innerRef={passwordUpdateForm.register({
                                validate: () => validateIsMatching(),
                                required: 'Confirm password is required',
                            })}
                            errors={passwordUpdateForm.errors}
                        />
                    </FormGroup>
                </div>
                <br></br>
                <p>
                    Can't remember your current password?
                    <Link className="button-link ml-auto" to="/ForgotPassword">
                        {' Reset your password via email'}
                    </Link>
                </p>
                <input
                    type="submit"
                    value={'Update password'}
                    className="login-button btn btn-primary"
                    data-testid="login-form-submit-button"
                />
            </Form>
        );
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <InfoBarManager infoBarArray={infoBars} />
                <div className="row flex-xl-nowrap">
                    <div className="sidebar-column col-md-3 col-xl-2 p-0 d-none d-md-block">
                        <Sidebar menuItems={profileMenu} />
                    </div>
                    <div className="offset-md-3 col-md-9 offset-xl-2 col-xl-10 content">
                        <div className="settings">
                            <h3 className="font-weight-normal">Contact details</h3>
                            <br></br>
                            {renderUpdateContactDetails()}
                            <br></br>
                            <h3 className="font-weight-normal">Password</h3>
                            <br></br>
                            {renderUpdatePassword()}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ProfileLandingPage;
