import React, { Fragment, useEffect, useState } from 'react';
import { Label, Table } from 'reactstrap';
import RadioButton from '../common/RadioButton';
const WebhooksSettingsList = ({ title, items, onSettingChanged, disabled }) => {
    items = items || [];
    let groupname = title.replace(' ', '').toLowerCase();
    const [isAllEnabled, setIsAllEnabled] = useState(false);
    const [isAllDisabled, setIsAllDisabled] = useState(false);

    useEffect(() => {
        setIsAllEnabled(items.every((item) => item.isSubscribed));
        setIsAllDisabled(items.every((item) => !item.isSubscribed));
    }, [items]);

    const toggleAll = (isSubscribed) => {
        items.forEach((item) => onSettingChanged(item, isSubscribed));
    };

    return (
        <Fragment>
            <Table>
                <thead>
                    <tr>
                        <th className="col-md-8">{title.toUpperCase()}</th>
                        <th>
                            <Label check>
                                <RadioButton
                                    id={`${groupname}-toggle-all-enable`}
                                    name={`${groupname}-toggle-all`}
                                    label="Enable All"
                                    value="true"
                                    checked={isAllEnabled}
                                    onChange={() => {
                                        toggleAll(true);
                                    }}
                                    disabled={disabled}
                                />
                            </Label>
                        </th>
                        <th>
                            <Label check>
                                <RadioButton
                                    id={`${groupname}-toggle-all-disable`}
                                    name={`${groupname}-toggle-all`}
                                    label="Disable All"
                                    value="false"
                                    checked={isAllDisabled}
                                    onChange={() => {
                                        toggleAll(false);
                                    }}
                                    disabled={disabled}
                                />
                            </Label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>
                                    <RadioButton
                                        id={`${groupname}-toggle-${index}-true`}
                                        name={`${groupname}-toggle-${index}`}
                                        label="Enable"
                                        value="true"
                                        checked={item.isSubscribed}
                                        onChange={() => {
                                            onSettingChanged(item, true);
                                        }}
                                        disabled={disabled}
                                    />
                                </td>
                                <td>
                                    <RadioButton
                                        id={`${groupname}-toggle-${index}-false`}
                                        name={`${groupname}-toggle-${index}`}
                                        label="Disable"
                                        value="false"
                                        checked={!item.isSubscribed}
                                        onChange={() => {
                                            onSettingChanged(item, false);
                                        }}
                                        disabled={disabled}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {isAllDisabled && (
                <>
                    <hr />
                    <p>
                        You aren't subscribed to any {title}. This means that you won't receive any webhooks
                        notifications. If this is a mistake, please subscribe to one of the {title} displayed above this
                        message. If no {title} are displayed, contact ResDiary support.
                    </p>
                </>
            )}
        </Fragment>
    );
};
export default WebhooksSettingsList;
