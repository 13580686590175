import * as React from 'react';
import { useState } from 'react';
import SidebarMenuItem from '../../model/layout/SidebarMenuItem';
import SidebarTitle from '../common/sidebar/SidebarTitle';

const Sidebar = (props: SidebarProps) => {
    const [openNodeKey, setOpenNodeKey] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const handleToggle = (isOpen: boolean, key: number) => {
        setIsActive(isOpen);
        setOpenNodeKey(key);
    };

    // Render titles
    return (
        <nav className={'dp-sidebar'}>
            {props.menuItems.map((item, index) => {
                return (
                    <SidebarTitle
                        item={item}
                        sideBarTitleKey={index}
                        key={index}
                        handleToggle={handleToggle}
                        isOpen={openNodeKey === index ? isActive : !isActive}
                    />
                );
            })}
        </nav>
    );
};

export interface SidebarProps {
    menuItems: SidebarMenuItem[];
}

export default Sidebar;
