/* eslint-disable */
import { adminApi } from '../../helpers/ApiHelper';

const updateDocumentManagementSettings = async (userId) => {
    const response = await adminApi.put(`Document/UpdateInHouseDocumentation/${userId}`);
    if (response.status === 200) {
        return response.data;
    } else {
        const errorMessage = response.data;
        return { errorMessage: errorMessage };
    }
};

export default {
    updateDocumentManagementSettings,
};
