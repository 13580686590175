import * as React from 'react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableContainer, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import App from '../../../model/appManagement/App';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../../common/LoadingSpinner';
import AppSubmissionStatusTag from '../AppSubmissionStatusTag';
import AppSubmissionStatusDropdown from '../AppSubmissionStatusDropdown';
import appSubmissionStatus from '../../../enums/appSubmissionStatus';

export interface AppsTableProps {
    apps?: App[];
    isLoading?: boolean;
    updateAppStatus: Function;
}

const AppsTable = (props: AppsTableProps) => {
    const navigate = useNavigate();

    return (
        <div>
            {props.isLoading ? (
                <LoadingSpinner />
            ) : (
                <Fragment>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ backgroundColor: '#e5e9f0' }}>
                                <TableRow>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.AppNameCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.IdCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.OrganisationsCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.ApiCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.StatusCol" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.apps &&
                                    props.apps.map((app, index) => (
                                        <TableRow key={index}>
                                            <TableCell className="btn btn-link" onClick={() => navigate(`${app.id}`)}>
                                                {app.title}
                                            </TableCell>
                                            <TableCell onClick={() => navigate(`${app.id}`)}>{app.id}</TableCell>
                                            <TableCell onClick={() => navigate(`${app.id}`)}>
                                                {app.organisationName}
                                            </TableCell>
                                            <TableCell onClick={() => navigate(`${app.id}`)}>{app.apiType}</TableCell>
                                            <TableCell>
                                                {app.submissionStatus === appSubmissionStatus.live ? (
                                                    <AppSubmissionStatusTag submissionStatus={app.submissionStatus} />
                                                ) : (
                                                    <AppSubmissionStatusDropdown
                                                        submissionStatus={app.submissionStatus}
                                                        appId={app.id}
                                                        updateAppStatus={props.updateAppStatus}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Fragment>
            )}
        </div>
    );
};
export default AppsTable;
