import * as React from 'react';
import { useMemo } from 'react';
import List from '@mui/material/List';
import NestedListItem from './NestedListItem';
import '../../styles/sidebarContent.scss';
import { NavLink } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';

const getTabLabels = (menuItems: SideBarItem[], parentIndex: number = 0) => (
    <List className="app-sidebar" disablePadding>
        {menuItems.map((menuItem, index) => {
            if (menuItem.subItems && menuItem.subItems.length > 0) {
                return (
                    <div key={`${parentIndex}-${index}`} className="nav-menu-group">
                        <NestedListItem
                            sx={{
                                ml: parentIndex * 3,
                            }}
                            hideExpandIcon={menuItem.hideExpandIcon}
                            selected={isMenuHeaderExpanded(menuItem)}
                            className="nav-menu-header"
                            component={menuItem.component}
                            subListItems={getTabLabels(menuItem.subItems, parentIndex + 1)}
                        />
                    </div>
                );
            } else {
                return (
                    <NavLink
                        key={`${parentIndex}-${index}`}
                        to={menuItem.path!}
                        className={({ isActive }) => {
                            return isActive ? 'nav-menu-item active' : 'nav-menu-item';
                        }}
                    >
                        <ListItemButton
                            sx={{
                                display: 'flow',
                                pl: parentIndex === 0 ? 2 : parentIndex * (menuItem.hideExpandIcon ? 3 : 4),
                            }}
                        >
                            {menuItem.component}
                        </ListItemButton>
                    </NavLink>
                );
            }
        })}
    </List>
);

const isMenuHeaderExpanded = (menuItem: SideBarItem): boolean => {
    if (menuItem.subItems) {
        return menuItem.subItems?.some((x: SideBarItem) => x.isSelected || isMenuHeaderExpanded(x));
    }
    return false;
};

const SideBarContentPage: React.FC<React.PropsWithChildren<SideBarContentPageProps>> = ({ children, tabLabels }) => {
    const menuItems = useMemo(() => {
        return getTabLabels(tabLabels);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabLabels]);

    return (
        <div className="overflow-hidden">
            <div className="flex-container-inline row">
                <div className="col-md-2">{menuItems}</div>
                <div className={`col-xl p-2`}>{children}</div>
            </div>
        </div>
    );
};

export interface SideBarContentPageProps {
    tabLabels: SideBarItem[];
}

export interface SideBarItem {
    hideExpandIcon?: boolean;
    path?: string | null;
    subItems?: SideBarItem[];
    component?: JSX.Element | string;
    isSelected?: boolean;
}

export default SideBarContentPage;
