import React from 'react';
import PropTypes from 'prop-types';
import ButtonBarItem from './ButtonBarItem';

const ButtonBar = (props) => {
    return (
        <div className="button-group">
            <ul>
                {props.items.map((item) => {
                    return (
                        <React.Fragment key={item.index}>
                            <ButtonBarItem
                                value={item.value}
                                setValue={props.setValue}
                                isActive={item.isActive}
                                label={item.label}
                            />
                        </React.Fragment>
                    );
                })}
            </ul>
        </div>
    );
};

ButtonBar.propTypes = {
    items: PropTypes.array,
    setValue: PropTypes.func,
};

export default ButtonBar;
