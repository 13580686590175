import * as React from 'react';
import { Fragment, useState } from 'react';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import NavigateNext from '@mui/icons-material/NavigateNext';
import ExpandMore from '@mui/icons-material/ExpandMore';

const NestedListItem = ({ hideExpandIcon, component, subListItems, selected, ...rest }: NestedListItemProps) => {
    const [isOpen, setIsOpen] = useState(selected);

    const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsOpen(!isOpen);
    };

    return (
        <Fragment>
            <ListItemButton selected={selected} {...rest} onClick={(event) => handleOnClick(event)}>
                {!hideExpandIcon &&
                    (isOpen ? <ExpandMore className="toggle-icon" /> : <NavigateNext className="toggle-icon" />)}
                {component}
            </ListItemButton>
            <Collapse in={isOpen}>{subListItems}</Collapse>
        </Fragment>
    );
};

export interface NestedListItemProps extends Omit<ListItemButtonProps, 'component'> {
    hideExpandIcon?: boolean;
    component?: JSX.Element | string;
    subListItems?: JSX.Element | undefined;
}

export default NestedListItem;
