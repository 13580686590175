import React, { useState, useEffect } from 'react';
import ProfileCircle from '../common/ProfileCircle';
import Table from '../common/DataTable';
import PropTypes from 'prop-types';
import Toggle from '../common/Toggle';

function AssignRoles({ rData, isLoading, updateRoles }) {
    const [teamData, setTeamData] = useState(rData);

    useEffect(() => {
        setTeamData(rData);
    }, [rData, setTeamData]);

    const updateRole = (e, x) => {
        if (x.userId === 'ALL') {
            if (e === true) {
                let all = teamData.filter((item) => item.userId === x.userId);
                all[0].isInRole = true;
                setTeamData(all);
            } else {
                setTeamData(rData.map((item) => (item.userId === x.userId ? { ...item, isInRole: e } : item)));
            }
        } else {
            setTeamData(teamData.map((item) => (item.userId === x.userId ? { ...item, isInRole: e } : item)));
        }
        let updated = rData.map((item) => (item.userId === x.userId ? { ...item, isInRole: e } : item));
        updateRoles(updated);
    };

    const columns = [
        {
            name: 'TEAM MEMBERS',
            selector: (x) => x.userId,
            displayFormat: (x) => {
                if (x.userId === 'ALL') {
                    return (
                        <div className="mt-2">
                            <b> {x.firstName} </b>
                        </div>
                    );
                } else {
                    return (
                        <div className="user-control d-flex mr-5">
                            <ProfileCircle fullName={`${x.firstName} ${x.lastName}`} />
                            <div className="ml-2 mt-1 text-left">
                                <div className="font-weight-bold text-capitalize">{`${x.firstName} ${x.lastName}`}</div>
                                <div className="">{x.email}</div>
                            </div>
                        </div>
                    );
                }
            },
        },
        {
            name: '',
            displayFormat: (x) => (
                <div className="float-right mr-5">
                    <Toggle
                        label=""
                        checked={x.isInRole}
                        handleChange={(e) => updateRole(e, x)}
                        checkedLabel="Yes"
                        uncheckedLabel="No"
                        isloading={isLoading}
                    />
                </div>
            ),
        },
    ];

    return (
        <Table
            tableName={'Team Members Table'}
            columns={columns}
            data={teamData}
            isLoading={isLoading}
            className="assignRolesTable"
        />
    );
}

AssignRoles.propTypes = {
    rData: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    updateRoles: PropTypes.func.isRequired,
};
export default AssignRoles;
