import React, { Fragment } from 'react';
import TextWithIcon from './TextWithIcon';
import SuccessIconWrapper from './SuccessIconWrapper';
import PropTypes from 'prop-types';
import useScreenSize from '../../hooks/useScreenSize';

function ProgressBar(props) {
    const { isTabletView } = useScreenSize();

    function getIcon(status) {
        return <SuccessIconWrapper status={status} />;
    }

    function getHeaderText() {
        return 'Get started with ResDiary Hub';
    }

    return (
        <Fragment>
            <div className="d-flex rd-progress-bar">
                <div>
                    <TextWithIcon
                        text={isTabletView ? '' : 'Account creation'}
                        icon={getIcon(props.createAccountStatus)}
                    />
                </div>
                <div className="horizontal-splitter"></div>
                <div>
                    <TextWithIcon
                        text={isTabletView ? '' : 'Organisation setup'}
                        icon={getIcon(props.organisationSetupStatus)}
                    />
                </div>
                <div className="horizontal-splitter"></div>
                <div>
                    <TextWithIcon
                        text={isTabletView ? '' : 'Account details'}
                        icon={getIcon(props.accountDetailsStatus)}
                    />
                </div>
            </div>
            {isTabletView && <h5 className="m-3 font-weight-normal">{getHeaderText()}</h5>}
        </Fragment>
    );
}

ProgressBar.propTypes = {
    createAccountStatus: PropTypes.string,
    organisationSetupStatus: PropTypes.string,
    accountDetailsStatus: PropTypes.string,
};

export default ProgressBar;
