import $ from 'jquery';
/**
 * Some tables don't need header but `Markdig` still renders it which
 * will result in empty grey bars on top of the table content.
 * This plugin will help to hide them.
 */
export default function hideEmptyTableHeaders() {
    $('thead tr').each(function () {
        const empty =
            $(this)
                .children()
                .map(function () {
                    const content = $(this).text();
                    return content && content.trim().length > 0;
                })
                .get()
                .filter((v) => v).length === 0;
        if (empty) {
            $(this).parent().hide();
        }
    });
}
