import * as React from 'react';

const RadioButton = (props: RadioButtonProps) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    };

    return (
        <div className={`radio-button ${props.className}`}>
            <input
                id={props.id}
                type="radio"
                name={props.name ?? 'radio-group'}
                value={props.value}
                onChange={onChange}
                checked={props.checked ?? props.defaultChecked}
                defaultChecked={props.defaultChecked}
                disabled={props.disabled}
            />
            <label htmlFor={props.id} className="radio-label">
                {props.label}
                {props.description && <div className="radio-description">{props.description}</div>}
            </label>
        </div>
    );
};

export interface RadioButtonProps {
    id?: string;
    name?: string;
    label?: string;
    description?: string;
    value?: string;
    onChange(value: string): void;
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
    className?: string;
}

export default RadioButton;
