import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

interface IConditionalRouteProps {
    condition: boolean | undefined;
    defaultUrl?: string;
    replace?: boolean;
    state?: any;
}

function ConditionalRoute({ condition, defaultUrl = '', replace, state }: IConditionalRouteProps) {
    const navigate = useNavigate();

    if (!condition) {
        navigate(defaultUrl, {
            state: state,
            replace: replace,
        });
    }

    return <Outlet />;
}

export default ConditionalRoute;
