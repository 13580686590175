import $ from 'jquery';

/**
 * To resize `response-example`s to make them fit to the height of the api-body.
 */
export default function resizeResponseExamples() {
    // We need a delay here because the resize logic use `.outerHeight`
    // which doesn't return correct value before browser finishes the rendering.
    // Reference: https://stackoverflow.com/questions/10268892/outerheighttrue-gives-wrong-value
    setTimeout(resize, 200);
}

const resize = () => {
    $('.api-doc__right-column pre.response-example').each(function () {
        const apiDoc = $(this).closest('.api-doc');
        const apiDocBody = apiDoc.find('.api-doc__body');
        const rightColumn = apiDoc.find('.api-doc__right-column');
        const delta = rightColumn.outerHeight() - apiDocBody.outerHeight();
        if (delta <= 0) {
            return;
        }
        $(this).css({
            maxHeight: $(this).outerHeight() - delta,
        });
    });
};
