import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem, { paginationItemClasses } from '@mui/material/PaginationItem';
import { styled } from '@mui/material/styles';

const PaginationItemStyled = styled(PaginationItem)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    [`&.${paginationItemClasses.selected}`]: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        ':hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

interface PaginatorProps {
    handlePaginate(pageNumber: number): void;
    pages: number;
    currentPage?: number;
}

const Paginator = (props: PaginatorProps) => {
    return (
        <Pagination
            count={props.pages}
            onChange={(_, page) => {
                props.handlePaginate(page);
            }}
            renderItem={(item) => <PaginationItemStyled {...item} />}
            defaultPage={props.currentPage ? props.currentPage : 1}
        />
    );
};

export default Paginator;
