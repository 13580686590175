import $ from 'jquery';

export default function addLinkToHeadingElements() {
    const selector = ['h2', 'h3', 'h4', 'h5', '.need-hash-link'].map((tag) => `${tag}[id]`).join(', ');
    $(selector).each(function () {
        const id = $(this).attr('id');
        // Need to include the location pathname to work with react-router
        const hashLinkHtml = `
            <a class='hash-link' href='${window.location.pathname + `#${id}`}'>#</a>
        `;
        $(this).append(hashLinkHtml);
        // Move the `id` from heading elements to `span`s and adjust position
        // of these spans to -N px from the original position (in .css) so that
        // when user clicks on anchor links, the heading elements won't stay
        // below the fixed-header.
        // Reference: https://stackoverflow.com/questions/10732690/offsetting-an-html-anchor-to-adjust-for-fixed-header
        const anchorIdHtml = `
            <span class='anchor-id' id='${id}'></span>
    `;
        $(this).prepend(anchorIdHtml);
        $(this).removeAttr('id');
    });
}
