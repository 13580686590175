export default function useTranslations() {
    function addResouceMessages(allMessages, resourceFiles, addingDefaultEnResource) {
        resourceFiles.keys().forEach((key) => {
            const fileNameWithExtension = key.replace('./', '');
            const resourceContent = require(`../translations/${fileNameWithExtension}`);
            const fileName = fileNameWithExtension.replace('.json', '');
            const splitFileName = fileName.split('.');
            if (addingDefaultEnResource && splitFileName.length === 1) {
                allMessages['en'] = { ...allMessages['en'], ...resourceContent };
            } else if (!addingDefaultEnResource && splitFileName.length > 1) {
                const culture = splitFileName[1].replace('_', '-');
                allMessages[culture] = { ...allMessages['en'], ...allMessages[culture], ...resourceContent };
            }
        });
    }

    const resourceFiles = require.context('../translations', true, /.json$/);
    const allMessages = {};
    addResouceMessages(allMessages, resourceFiles, true);
    addResouceMessages(allMessages, resourceFiles, false);

    const language = navigator.language.split(/[-_]/)[0]; // language without region code
    const messages = language in allMessages ? allMessages[language] : allMessages['en'];
    return { language, messages };
}
