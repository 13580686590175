import React, { Fragment, useEffect, useState, useCallback } from 'react';
import config from '../../config/app';
import { Alert, Container, Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { useNavigate, Navigate } from 'react-router-dom';
import ValidationInput from '../common/ValidationInput';
import useValidation from '../../hooks/useValidation';
import { ReactComponent as ResIcon } from '../../content/images/RD_hub_logo.svg';
import AccountService from '../../services/AccountService';
import AdminAccountService from '../../services/admin/AccountService';
import useQueryParam from '../../hooks/useQueryParam';
import LoadingSpinner from '../common/LoadingSpinner';
import { passwordValidCheck, isMatching } from '../../helpers/passwordValidator';
import Notification from '../common/Notification';
import userOnboardingStage from './../../enums/userOnboardingStage';
import { useOrganisation } from '../../contexts/OrganisationContext';
import userStatus from '../../enums/userStatus';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import useTrackPage from '../../hooks/useTrackPage';

function JoinTeamPage() {
    const { setDefaultOrganisation } = useOrganisation();
    const token = useQueryParam('token');
    const organisationId = useQueryParam('organisationId');
    const userId = useQueryParam('userId');
    const [userData, setUserData] = useState();
    const [error, setError] = useState('');
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isInvalidToken, setIsInvalidToken] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [invitedBy, setInvitedBy] = useState('');
    const [isAlreadyOnboarded, setIsAlreadyOnboarded] = useState(false);
    const formValidation = useValidation();
    const navigate = useNavigate();

    useTrackPage('Team Mate Join Page');

    useEffect(() => {
        if (token && userId) {
            if (window.location.pathname === config.joinAdminTeamPath) {
                AdminAccountService.getInvitationToken(token, userId)
                    .then((adminResponse) => {
                        if (!('errorMessage' in adminResponse)) {
                            if (adminResponse.isTokenValid) {
                                setUserData({
                                    firstName: adminResponse.firstName,
                                    lastName: adminResponse.lastName,
                                    token: adminResponse.token,
                                    userId: adminResponse.userId,
                                });
                                AdminAccountService.joinAdminTeam(adminResponse).then((result) => {
                                    if ('errorMessage' in result) {
                                        setError(result.errorMessage);
                                    } else {
                                        <Fragment>
                                            {result} has invited you to access ResDiary Hub. <br /> Please enter your
                                            full name and create a password to continue.
                                        </Fragment>;
                                    }
                                });
                            } else {
                                setInvitedBy(adminResponse.invitedBy);
                                setIsInvalidToken(true);
                            }
                        } else {
                            setError(adminResponse.errorMessage);
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                AccountService.getInvitationToken(token, userId, organisationId)
                    .then((response) => {
                        if (response.isTokenValid) {
                            setUserData({
                                firstName: response.firstName,
                                lastName: response.lastName,
                                token: response.token,
                                organisationId: response.organisationId,
                                userId: response.userId,
                            });
                            if (response.onboardingStage !== userOnboardingStage.Complete) {
                                setWelcomeMessage(
                                    <Fragment>
                                        {response.invitedBy} has invited you to access ResDiary Hub. <br /> Please enter
                                        your full name and create a password to continue.
                                    </Fragment>
                                );
                            } else {
                                // Onboarded users are being invited to a new team.
                                setWelcomeMessage(
                                    <Fragment>
                                        Thanks for joining the team! You'll be redirected shortly, or click below to
                                        continue...
                                    </Fragment>
                                );
                                setIsAlreadyOnboarded(true);
                                AccountService.joinAdditionalTeam(response).then((result) => {
                                    if (result.errorMessage) {
                                        setError(result.errorMessage);
                                    } else {
                                        setTimeout(() => {
                                            setDefaultOrganisation(result);
                                            navigate('/');
                                        }, 3000);
                                    }
                                });
                            }
                        } else {
                            setInvitedBy(response.invitedBy);
                            setIsInvalidToken(true);
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setError(error);
                    });
            }
        }
    }, [
        formValidation.formState.errors.password,
        setIsInvalidToken,
        setError,
        token,
        userId,
        organisationId,
        navigate,
        setDefaultOrganisation,
    ]);

    const setInput = useCallback(
        (value, fieldName) => {
            let tempUserData = userData;
            tempUserData[fieldName] = value;
            setUserData(tempUserData);
        },
        [userData]
    );

    if (!token || !userId) {
        return <Navigate to={config.loginPath} />;
    }

    const submitCreateAccount = () => {
        if (window.location.pathname === config.joinAdminTeamPath) {
            AdminAccountService.registerAdminMember(userData).then((response) => {
                if (!('errorMessage' in response) && response.status === userStatus.Active) {
                    setIsEmailVerified(true);
                } else if ('errorMessage' in response) {
                    setError(response.errorMessage);
                } else {
                    setError('There was a problem confirming your registration');
                }
            });
        } else {
            AccountService.registerTeamMember(userData).then((response) => {
                if (response != null) {
                    const memberProperties = {
                        teammateFirstName: userData.firstName,
                        teammateLastName: userData.lastName,
                    };
                    AnalyticsHelper.trackClickWithProperties('Web teammateJoin Confirm', memberProperties);
                    setIsEmailVerified(true);
                } else if (response.errorMessage !== null) {
                    setError(response.errorMessage);
                }
            });
        }
    };

    const validatePasswordRequirements = (data) =>
        passwordValidCheck(data) || 'Password must contain 10 characters, an uppercase letter and a special character.';

    const validateIsPasswordMatching = (data) => isMatching(userData.password, data) || 'Passwords must match.';

    const renderInvalidTokenNotification = () => {
        if (isInvalidToken) {
            return (
                <div className="account-form">
                    <Notification
                        alertType="AlertIcon"
                        title="Email verification expired"
                        message1="Your verification link has expired."
                        message2={`Please request for a new email verification link from ${invitedBy}.`}
                    />
                </div>
            );
        }
    };

    const renderEmailVerified = () => {
        if (isEmailVerified) {
            return (
                <div className="account-form">
                    <Notification
                        alertType="ConfirmedIcon"
                        title="Account created"
                        message1="You have successfully created your account."
                        message2="Please use the link below to sign in."
                    />
                    <button
                        className="button-link"
                        onClick={() => {
                            navigate(config.loginPath);
                        }}
                    >
                        Sign in
                    </button>
                </div>
            );
        }
    };

    const renderWelcomeMessageOnly = () => {
        if (!isInvalidToken) {
            return (
                <div className="account-form">
                    <div className="account-form__title">Welcome!</div>
                    <div className="m-3 p-0">{welcomeMessage}</div>
                    <a href={config.loginPath}>Continue</a>
                </div>
            );
        }
    };

    const renderJoinTeamForm = () => {
        if (!isInvalidToken && !isEmailVerified) {
            return (
                <Form className="account-form" onSubmit={formValidation.submit(submitCreateAccount)}>
                    <div className="account-form__title">Join your team</div>
                    <div className="m-3 p-0">{welcomeMessage}</div>
                    {renderError()}
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="d-flex">
                                    <Label for="firstName">First name</Label>
                                </div>
                                <ValidationInput
                                    testId="firstName"
                                    type="text"
                                    name="firstName"
                                    placeholder="First name"
                                    value={userData.firstName}
                                    onChange={(e) => {
                                        setInput(e.currentTarget.value, 'firstName');
                                    }}
                                    innerRef={formValidation.register({
                                        required: 'First name is required',
                                    })}
                                    errors={formValidation.errors}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <div className="d-flex">
                                    <Label for="lastName">Last name</Label>
                                </div>
                                <ValidationInput
                                    testId="lastName"
                                    type="text"
                                    name="lastName"
                                    placeholder="Last name"
                                    value={userData.lastName}
                                    onChange={(e) => {
                                        setInput(e.currentTarget.value, 'lastName');
                                    }}
                                    innerRef={formValidation.register({
                                        required: 'Last name is required',
                                    })}
                                    errors={formValidation.errors}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="d-flex">
                                    <Label for="password">Password</Label>
                                </div>
                                <ValidationInput
                                    testId="password"
                                    type="password"
                                    name="password"
                                    placeholder=""
                                    value={userData.password}
                                    onChange={(e) => {
                                        setInput(e.currentTarget.value, 'password');
                                    }}
                                    innerRef={formValidation.register({
                                        validate: validatePasswordRequirements,
                                        required: 'Password is required',
                                    })}
                                    errors={formValidation.errors}
                                    showPasswordToggle={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="d-flex">
                                    <Label for="confirm-password">Confirm Password</Label>
                                </div>
                                <ValidationInput
                                    testId="confirmPassword"
                                    type="password"
                                    name="confirmPassword"
                                    placeholder=""
                                    value={userData.confirmPassword}
                                    onChange={(e) => {
                                        setInput(e.currentTarget.value, 'confirmPassword');
                                    }}
                                    innerRef={formValidation.register({
                                        validate: (value) => validateIsPasswordMatching(value),
                                        required: 'Confirm Password is required',
                                    })}
                                    errors={formValidation.errors}
                                    showPasswordToggle={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <input
                        disabled={formValidation.submitDisabled}
                        type="submit"
                        value={'Confirm'}
                        className="btn btn-primary col"
                        data-testid="confirm-submit-button"
                    />
                </Form>
            );
        }
    };

    const renderError = () => {
        if (error) {
            return (
                <Alert className="account-form__error">
                    <img src="/images/Alert-white.svg" alt="" />
                    {error}
                </Alert>
            );
        }
    };

    return (
        <Container className="account-page">
            <div className="flex-container-center">
                {isLoading ? (
                    <div className="lex-container-center m-10">
                        <LoadingSpinner loadingText="Loading..." />
                    </div>
                ) : (
                    <div>
                        <div className="account-page__body__logo">
                            <ResIcon className="brand-svg" />
                        </div>
                        <div>{renderEmailVerified()}</div>
                        <div>{renderInvalidTokenNotification()}</div>
                        <div>{!isAlreadyOnboarded ? renderJoinTeamForm() : renderWelcomeMessageOnly()}</div>
                    </div>
                )}
            </div>
        </Container>
    );
}

export default JoinTeamPage;
