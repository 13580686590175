import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import addToggleLinks from '../../docsApp/addToggleLinks';
import addLinkToHeadingElements from '../../docsApp/addLinkToHeadingElements';
import hideEmptyTableHeaders from '../../docsApp/hideEmptyTableHeaders';
import addLanguageToCodeBlocks from '../../docsApp/addLanguageToCodeBlocks';
import evaluateParametersInCodeBlocks from '../../docsApp/evaluateParametersInCodeBlocks';
import removeEmptyPsInCode from '../../docsApp/removeEmptyPsInCode';
import copyCode from '../../docsApp/copyCode';
import mobileNav from '../../docsApp/mobileNav';
import buildSubmenu from '../../docsApp/buildSubmenu';
import errorPage from '../../docsApp/errorPage';
import resizeResponseExamples from '../../docsApp/resizeResponseExamples';
window.jQuery = window.$ = $; // to share with bootstrap

const initDocs = () => {
    $(() => {
        addToggleLinks();
        addLinkToHeadingElements();
        hideEmptyTableHeaders();
        addLanguageToCodeBlocks();
        evaluateParametersInCodeBlocks();
        removeEmptyPsInCode();
        copyCode();
        buildSubmenu();
        errorPage();
        resizeResponseExamples();
    });
};

const initMobileNav = () => {
    $(() => {
        mobileNav();
    });
};

const initSubMenu = () => {
    $(() => {
        buildSubmenu();
    });
};

export { initDocs, initMobileNav, initSubMenu };
