import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import Sidebar from '../layout/Sidebar';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Label } from 'reactstrap';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import useValidation from '../../hooks/useValidation';
import AccountService from '../../services/AccountService';
import InfoBarManager from '../common/InfoBarManager';
import infoBarType from '../../enums/infoBarType';
import { ValidationInput } from '../../components/';
import { passwordValidCheck, isMatching } from '../../helpers/passwordValidator';
import ProfileMenu from './ProfileMenuData';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoBarProps } from '../common/InfoBar';
import { validateEmail } from '../../helpers/emailValidator';

function GeneralSettingsPage(): JSX.Element {
    const profilesMenu = ProfileMenu();
    const { authInfo } = useAuthentication();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [confirmNewEmail, setConfirmNewEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // eslint-disable-line
    const [infoBars, setInfoBars] = useState<InfoBarProps[]>([]);

    const contactDetailsForm = useValidation();
    const emailUpdateForm = useValidation();
    const passwordUpdateForm = useValidation();
    const intl = useIntl();

    useEffect(() => {
        setUserEmail(authInfo?.username !== undefined ? authInfo.username : '');
    }, [authInfo]);

    useEffect(() => {
        setFirstName(authInfo?.firstName !== undefined ? authInfo.firstName : '');
    }, [authInfo]);

    useEffect(() => {
        setLastName(authInfo?.lastName !== undefined ? authInfo.lastName : '');
    }, [authInfo]);
    function addErrorBar(message: string): void {
        setInfoBars((i) => [...i, { type: infoBarType.error, message: message }]);
    }

    function addSuccessBar(message: string): void {
        setInfoBars((i) => [...i, { type: infoBarType.success, message: message }]);
    }

    const passwordValidationRules = (): string | undefined => {
        let isValid = passwordValidCheck(newPassword);

        if (!isValid) {
            return intl.formatMessage({ id: 'Profile.GeneralSettingsPage.PasswordValidationRule' });
        }
    };

    const validatePasswordIsMatching = (): string | undefined => {
        let passwordMatches = isMatching(newPassword, confirmPassword);
        if (!passwordMatches) {
            return intl.formatMessage({ id: 'Profile.GeneralSettingsPage.PasswordValidationMatching' });
        }
    };

    const emailValidationRules = (): string | undefined => {
        if (!validateEmail(newEmail)) {
            return intl.formatMessage({ id: 'Profile.GeneralSettingsPage.EmailValidation' });
        }
    };

    const validateEmailIsMatching = (): string | undefined => {
        let emailMatches = isMatching(newEmail, confirmNewEmail);
        if (!emailMatches) {
            return intl.formatMessage({ id: 'Profile.GeneralSettingsPage.EmailValidationMatching' });
        }
    };

    function submitUpdateContactDetails(): void {
        AccountService.updateContactDetails(firstName, lastName)
            .then((response) => {
                if (response !== true) {
                    setErrorMessage(response.errorMessage);
                    addErrorBar(intl.formatMessage({ id: 'Profile.GeneralSettingsPage.ContactDetailsNotUpdated' }));
                } else {
                    addSuccessBar(intl.formatMessage({ id: 'Profile.GeneralSettingsPage.ContactDetailsUpdated' }));
                }
            })
            .catch((err) => {
                setErrorMessage(err);
            });
    }

    function submitUpdatePassword(): void {
        AccountService.updatePassword(currentPassword, newPassword, userEmail)
            .then((response) => {
                if (response !== true) {
                    addErrorBar(intl.formatMessage({ id: 'Profile.GeneralSettingsPage.PasswordNotUpdated' }));
                } else {
                    addSuccessBar(intl.formatMessage({ id: 'Profile.GeneralSettingsPage.PasswordUpdated' }));
                }
            })
            .catch((err) => {
                setErrorMessage(err);
            });
    }

    const submitUpdateEmail = (): void => {
        AccountService.updateEmail(newEmail)
            .then((response) => {
                if (response !== true && 'errorMessage' in response) {
                    addErrorBar(intl.formatMessage({ id: 'Profile.GeneralSettingsPage.EmailNotUpdated' }));
                    console.log(response.errorMessage);
                } else {
                    addSuccessBar(intl.formatMessage({ id: 'Profile.GeneralSettingsPage.EmailUpdated' }));
                }
            })
            .catch((err) => {
                setErrorMessage(err);
            });
    };

    const renderUpdateContactDetails = (): JSX.Element => {
        return (
            <div>
                <Form onSubmit={contactDetailsForm.submit(submitUpdateContactDetails)}>
                    <div className="d-flex">
                        <FormGroup className="user-first-name">
                            <div className="d-flex">
                                <Label for="firstName">
                                    <FormattedMessage id="Profile.GeneralSettingsPage.FirstName" />
                                </Label>
                            </div>
                            <ValidationInput
                                testId="firstName"
                                type="text"
                                name="firstName"
                                value={firstName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFirstName(e.currentTarget.value)
                                }
                                innerRef={contactDetailsForm.register({
                                    required: intl.formatMessage({
                                        id: 'Profile.GeneralSettingsPage.FirstNameRequired',
                                    }),
                                })}
                                errors={contactDetailsForm.errors}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div className="d-flex">
                                <Label for="lastName">
                                    <FormattedMessage id="Profile.GeneralSettingsPage.LastName" />
                                </Label>
                            </div>
                            <ValidationInput
                                testId="lastName"
                                type="text"
                                name="lastName"
                                value={lastName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setLastName(e.currentTarget.value)
                                }
                                innerRef={contactDetailsForm.register({
                                    required: intl.formatMessage({
                                        id: 'Profile.GeneralSettingsPage.LastNameRequired',
                                    }),
                                })}
                                errors={contactDetailsForm.errors}
                            />
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <input
                            type="submit"
                            value={intl.formatMessage({ id: 'Profile.GeneralSettingsPage.UpdateContactDetails' })}
                            className="login-button btn btn-primary"
                        />
                    </FormGroup>
                </Form>
            </div>
        );
    };

    const renderUpdateEmail = (): JSX.Element => {
        return (
            <div>
                <Form onSubmit={emailUpdateForm.submit(submitUpdateEmail)}>
                    <div className="d-flex">
                        <FormGroup className="user-new-email">
                            <div className="d-flex">
                                <Label for="newEmail">
                                    <FormattedMessage id="Profile.GeneralSettingsPage.NewEmail" />
                                </Label>
                            </div>
                            <ValidationInput
                                testId="email"
                                type="email"
                                value=""
                                name="newEmail"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setNewEmail(e.currentTarget.value);
                                }}
                                innerRef={emailUpdateForm.register({
                                    validate: () => emailValidationRules(),
                                    required: intl.formatMessage({ id: 'Profile.GeneralSettingsPage.NewEmailWarning' }),
                                })}
                                errors={emailUpdateForm.errors}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div className="d-flex">
                                <Label for="confirmEmail">
                                    <FormattedMessage id="Profile.GeneralSettingsPage.ConfirmNewEmail" />
                                </Label>
                            </div>
                            <ValidationInput
                                type="email"
                                value=""
                                name="confirmEmail"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setConfirmNewEmail(e.currentTarget.value);
                                }}
                                innerRef={emailUpdateForm.register({
                                    validate: () => validateEmailIsMatching(),
                                    required: intl.formatMessage({
                                        id: 'Profile.GeneralSettingsPage.ConfirmNewEmailWarning',
                                    }),
                                })}
                                errors={emailUpdateForm.errors}
                            />
                        </FormGroup>
                    </div>
                    <input
                        type="submit"
                        value={intl.formatMessage({ id: 'Profile.GeneralSettingsPage.UpdateEmail' })}
                        className="login-button btn btn-primary"
                        data-testid="login-form-submit-button"
                    />
                </Form>
            </div>
        );
    };

    const renderUpdatePassword = (): JSX.Element => {
        return (
            <div>
                <Form onSubmit={passwordUpdateForm.submit(submitUpdatePassword)}>
                    <FormGroup>
                        <div className="d-flex">
                            <Label for="currentPassword">
                                <FormattedMessage id="Profile.GeneralSettingsPage.CurrentPassword" />
                            </Label>
                        </div>
                        <ValidationInput
                            testId="currentPassword"
                            type="password"
                            value=""
                            name="currentPassword"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setCurrentPassword(e.currentTarget.value)
                            }
                            innerRef={passwordUpdateForm.register({
                                required: intl.formatMessage({
                                    id: 'Profile.GeneralSettingsPage.CurrentPasswordRequired',
                                }),
                            })}
                            errors={passwordUpdateForm.errors}
                        />
                    </FormGroup>
                    <div className="d-flex">
                        <FormGroup className="user-new-password">
                            <div className="d-flex">
                                <Label for="newPassword">
                                    <FormattedMessage id="Profile.GeneralSettingsPage.NewPassword" />
                                </Label>
                            </div>
                            <ValidationInput
                                testId="newPassword"
                                type="password"
                                value=""
                                name="newPassword"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setNewPassword(e.currentTarget.value)
                                }
                                innerRef={passwordUpdateForm.register({
                                    validate: () => passwordValidationRules(),
                                    required: intl.formatMessage({
                                        id: 'Profile.GeneralSettingsPage.NewPasswordRequired',
                                    }),
                                })}
                                errors={passwordUpdateForm.errors}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div className="d-flex">
                                <Label for="confirmPassword">
                                    <FormattedMessage id="Profile.GeneralSettingsPage.ConfirmNewPassword" />
                                </Label>
                            </div>
                            <ValidationInput
                                testId="confirmPassword"
                                type="password"
                                value=""
                                name="confirmPassword"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setConfirmPassword(e.currentTarget.value)
                                }
                                innerRef={passwordUpdateForm.register({
                                    validate: () => validatePasswordIsMatching(),
                                    required: intl.formatMessage({
                                        id: 'Profile.GeneralSettingsPage.ConfirmNewPasswordRequired',
                                    }),
                                })}
                                errors={passwordUpdateForm.errors}
                            />
                        </FormGroup>
                    </div>
                    <br></br>
                    <p>
                        <FormattedMessage id="Profile.GeneralSettingsPage.CanNotRememberPassword" />
                        <Link className="button-link ml-auto" to="/ForgotPassword">
                            &nbsp;
                            <FormattedMessage id="Profile.GeneralSettingsPage.ResetPasswordViaEmail" />
                        </Link>
                    </p>
                    <input
                        type="submit"
                        value={intl.formatMessage({ id: 'Profile.GeneralSettingsPage.UpdatePassword' })}
                        className="login-button btn btn-primary"
                        data-testid="login-form-submit-button"
                    />
                </Form>
            </div>
        );
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <InfoBarManager infoBarArray={infoBars} />
                <div className="row flex-xl-nowrap">
                    <div className="sidebar-column col-md-3 col-xl-2 p-0 d-none d-md-block">
                        <Sidebar menuItems={profilesMenu} />
                    </div>
                    <main className="landing-content">
                        <div className="general-settings">
                            <h1>
                                <FormattedMessage id="Profile.GeneralSettingsPage.GeneralSettings" />
                            </h1>
                            <br></br>
                            <h3 className="font-weight-normal">Contact details</h3>
                            <br></br>
                            <div>{renderUpdateContactDetails()}</div>
                            <br></br>
                            <h3 className="font-weight-normal">Email</h3>
                            <br></br>
                            <div>{renderUpdateEmail()}</div>
                            <br></br>
                            <h3 className="font-weight-normal">Password</h3>
                            <br></br>
                            <div>{renderUpdatePassword()}</div>
                            <br></br>
                            <br></br>
                        </div>
                    </main>
                </div>
            </div>
        </Fragment>
    );
}

export default GeneralSettingsPage;
