import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

function ConfirmationModal({
    header,
    onAccept,
    isOpen,
    onClose,
    children,
    isHideCancel,
    cancelButtonText,
    firstButtonText,
    secondButtonText,
    secondButtonFunc,
    isLoading,
    hasHeaderBorder,
}) {
    const cancelButton = () => {
        if (!isHideCancel) {
            return (
                <Button variant="outlined" disableElevation className="text-uppercase" onClick={onClose}>
                    {cancelButtonText || 'Cancel'}
                </Button>
            );
        }
    };

    const secondButton = () => {
        if (secondButtonText !== undefined && secondButtonFunc !== undefined) {
            return (
                <Button variant="outlined" disableElevation className="text-uppercase" onClick={secondButtonFunc}>
                    {secondButtonText}
                </Button>
            );
        }
    };

    return (
        <Modal className="confirmation-modal" centered isOpen={isOpen} toggle={onClose}>
            <ModalHeader className={hasHeaderBorder ? '' : 'border-bottom-0'} toggle={onClose}>
                {header && <span>{header}</span>}
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
                <Stack direction="row" spacing={2}>
                    {cancelButton()}
                    {secondButton()}
                    <Button
                        disabled={isLoading}
                        variant="contained"
                        disableElevation
                        className="text-uppercase"
                        onClick={onAccept}
                    >
                        {firstButtonText || 'Confirm'}
                        {isLoading && (
                            <CircularProgress
                                color="secondary"
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Button>
                </Stack>
            </ModalFooter>
        </Modal>
    );
}

ConfirmationModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hasHeaderBorder: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isHideCancel: PropTypes.bool,
    cancelButtonText: PropTypes.string,
    firstButtonText: PropTypes.string,
    secondButtonText: PropTypes.string,
    secondButtonFunc: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default ConfirmationModal;
