/* eslint-disable */
import { api } from '../helpers/ApiHelper';
import GeneralConfiguration from '../model/configuration/GeneralConfiguration';
import ErrorMessage from '../model/services/ErrorMessage';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getConfigurationSettings = async () => {
    return await api.get<GeneralConfiguration, string>(`Configuration`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

export default {
    getConfigurationSettings,
};
