import $ from 'jquery';

/**
 * To remove the empty <p></p> auto added before <pre><code></code></pre> by `Markdig`
 */
export default function removeEmptyPsInCode() {
    $('.code pre').each(function () {
        const sibling = $(this).prev();
        if (sibling.prop('tagName') === 'P' && !sibling.text().trim()) {
            sibling.remove();
        }
    });
}
