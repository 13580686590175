import * as React from 'react';
import MuiModal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const ModalContent = styled(Box)(({ theme }) => ({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
}));

function Modal(props: BasicModalProps) {
    return (
        <MuiModal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalContent>{props.children}</ModalContent>
        </MuiModal>
    );
}

export interface BasicModalProps {
    open: boolean;
    children: React.ReactNode;
    onClose: () => void;
}

export default Modal;
