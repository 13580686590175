import * as React from 'react';
import { useState } from 'react';
import { Alert, AlertColor, AlertTitle, SxProps } from '@mui/material';

const HubAlert = (props: HubAlertProps) => {
    const [isEnabled, setIsEnabled] = useState(true);

    const style: SxProps = {
        position: 'relative',
        marginTop: '1rem',
    };

    if (props.expireIn !== 0) {
        setTimeout(() => {
            setIsEnabled(false);
        }, props.expireIn ?? 4000);
    }

    const onClose = () => {
        setIsEnabled(false);
        if (props.onClose) props.onClose();
    };

    return (
        <Alert severity={props.type} onClose={() => onClose()} sx={style} hidden={!isEnabled}>
            {props.title && <AlertTitle>{props.title}</AlertTitle>}
            {props.message}
        </Alert>
    );
};

export interface HubAlertProps {
    type: AlertColor;
    message: string;
    title?: string;
    expireIn?: number;
    onExpire?(): void;
    onClose?(): void;
}

export default HubAlert;
