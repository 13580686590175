import $ from 'jquery';

/**
 * Extract h2 heading links and add them to submenu.
 * Notice that, this plugin must put after `addLinkToHeadingElements`
 */
export default function buildSubmenu() {
    const submenuContainer = $('ul.dp-sidebar__submenu').first();
    if (!submenuContainer.length) {
        return;
    }
    if (submenuContainer.children.length) {
        submenuContainer.empty();
    }
    const count = $('main h2 a.hash-link').each(function () {
        // Remove children so that .text() won't include text of its children
        const text = $(this).parent().clone().children().remove().end().text();
        if (!text || !text.trim().length) {
            return;
        }
        const link = $(this).attr('href');
        const html = `
      <li>
        <a href='${link}'>${text.trim()}</a>
      </li>
    `;
        submenuContainer.append(html);
    }).length;
    if (count > 0) {
        submenuContainer.show();
        highlightSubmenu();
        $(window).on('hashchange', function () {
            highlightSubmenu();
        });
    }
}

const highlightSubmenu = () => {
    const hash = window.location.hash;
    const activeSubmenuClass = 'dp-sidebar__submenu__item--active';
    $('.dp-sidebar__submenu a').each(function () {
        const link = $(this);
        if (this.hash === hash) {
            if (!link.hasClass(activeSubmenuClass)) {
                link.addClass(activeSubmenuClass);
            }
        } else {
            link.removeClass(activeSubmenuClass);
        }
    });
};
