import { useForm, SubmitHandler } from 'react-hook-form-6';

function useValidation() {
    const { register, handleSubmit, errors, getValues, setValue, control, setError, formState, reset } = useForm();

    const submitDisabled = Object.keys(errors).length > 0;
    const errorCount = Object.keys(errors).length;

    const submit = (onSubmit: SubmitHandler<HTMLInputElement>) => handleSubmit(onSubmit);

    return {
        register,
        errors,
        submitDisabled,
        submit,
        errorCount,
        setValue,
        control,
        getValues,
        setError,
        formState,
        reset,
    };
}

export default useValidation;
