import { useEffect, useState } from 'react';
import AnalyticsHelper from '../helpers/AnalyticsHelper';

function useTrackPage(pageName) {
    const [trackedPage, setTrackedPage] = useState(false);
    const providerType = AnalyticsHelper.getProviderType();
    useEffect(() => {
        if (!trackedPage) {
            setTrackedPage(true);
            window.analytics.page(pageName, providerType);
        }
    }, [pageName, trackedPage, providerType]);
}

export default useTrackPage;
